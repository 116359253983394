import {SV_API, handleSVApiError} from '../utils';
const path = 'admin';

export const getProductCondition = async (formData) => {
  try {
    const res = await SV_API.post(`${path}/product-v2/options/condition`, formData);

    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};

/* ดึงราคา */
export const getProductPrice = async (formData) => {
  try {

    const { isTurn, productCode, condition,  saleGrade } = formData

    /* Validate Field */
    const requiredFields = isTurn ? [productCode, condition] : [productCode, saleGrade];
    if (requiredFields.some(field => !field)) {
      throw new Error('INVALID_INPUT')
    }

    const res = await SV_API.post(`${path}/product-v2/price`, formData);

    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};

/* คำนวณค่างวด และเงินดาวน์ */
export const calculateInstallment = async (formData) => {
  try {

    const { price, down, period, /* discountPeriod, discountTurn1, couponDiscount */} = formData

    /* Validate Field */
    const requiredFields = [price, down, period]
    if (requiredFields.some(field => !field)) {
      throw new Error('INVALID_INPUT')
    }

    const res = await SV_API.post(`${path}/product-v2/installment/calculate`, formData);

    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};

