import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Select} from 'antd';
import {getProductData} from '../../../services/api/svAPI';

const SelectPeriods = (props) => {
  const {filter, placeholder, handleChange, productSaleByPeriod, multiple, ...otherProp} = props;
  const {type, serialNumber, productCode, condition, isTurn} = filter || {};
  const [dataSc, setDataSc] = useState([]);
  const [loading, setLoading] = useState(false);

  const inputOptions = useMemo(() => {
    return {type: type, serialNumber: serialNumber, productCode: productCode, condition: condition, isTurn: isTurn};
  }, [type, serialNumber, productCode, condition, isTurn]);

  useEffect(() => {
    if (!_.isEmpty(serialNumber) || !_.isEmpty(productCode)) fetchData();
  }, [inputOptions]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const {result} = await getProductData(inputOptions);

      const dt = _.sortBy(
        _.map(result?.productSale, (dt) => {
          return {label: dt?.period, value: dt?.period};
        }),
        ['value'],
      );

      setDataSc(dt);

      if (productSaleByPeriod) {
        productSaleByPeriod(
          _.keyBy(
            _.map(result?.productSale, (dt) => {
              return {...dt, productName: result?.productName};
            }),
            'period',
          ),
        );
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Select
      {...otherProp}
      style={{width: '100%'}}
      options={dataSc}
      placeholder={placeholder}
      loading={loading}
      showSearch
      onChange={(value) => (multiple ? handleChange(value) : handleChange(_.find(dataSc, (data) => data?.value === value)))}
      multiple={multiple}
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    />
  );
};

SelectPeriods.propTypes = {
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.object,
  productSaleByPeriod: PropTypes.func,
};

export default SelectPeriods;
