import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Select} from 'antd';
// import {getProductCondition} from '../../../services/api/svAPI';

const SelectProductCondition = (props) => {
  const {filter, placeholder, handleChange, dataResponse, multiple, ...otherProp} = props;
  const {type, serialNumber, productCode, period} = filter || {};
  const [dataSc, setDataSc] = useState([]);
  const [loading, setLoading] = useState(false);

  const inputOptions = useMemo(() => {
    return {type, serialNumber, productCode, period};
  }, [type, serialNumber, productCode, period]);

  useEffect(() => {
    fetchData();
  }, [inputOptions]);

  const fetchData = async () => {
    try {
      setLoading(true);
      // const {data: result} = await getProductCondition();

      const result = [
        {name: 'C0', code: 'C0'},
        {name: 'C1', code: 'C1'},
        {name: 'C2', code: 'C2'},
        {name: 'C3', code: 'C3'},
        {name: 'C4', code: 'C4'},
      ];

      setDataSc(
        _.map(result, (dt) => {
          return {
            label: dt?.name,
            value: dt?.code,
          };
        }),
      );
      if (dataResponse) {
        dataResponse(result);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Select
      {...otherProp}
      style={{width: '100%'}}
      options={dataSc}
      placeholder={placeholder}
      loading={loading}
      showSearch
      onChange={(value) => (multiple ? handleChange(value) : handleChange(_.find(dataSc, (data) => data?.value === value)))}
      multiple={multiple}
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    />
  );
};

SelectProductCondition.propTypes = {
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.object,
  dataResponse: PropTypes.func,
};

export default SelectProductCondition;
