import React from 'react';
import { Switch, Redirect, Route as Routes } from 'react-router-dom';
import { Path as subPath } from '../utils/subdomain';
import PrivateRoute from './PrivateRoute';
import DashboardScreen from '../screens/dashboard/Dashboard';
import TransactionScreen from '../screens/transactions';
import TransactionTradesysScreen from '../screens/transaction_tradesys';
import TransactionInfoScreen from '../screens/transactions/TransactionInfo';
import ProductScreen from '../screens/product';
import ProductInfoScreen from '../screens/product/ProductInfo';
import ProductSerialScreen from '../screens/product/ProductSerial';
import ImportScreen from '../screens/import';
import PaymentScreen from '../screens/payment';
import CustomerScreen from '../screens/customer2';
import CustomerInfoScreen from '../screens/customer/CustomerInfo';
import ContractScreen from '../screens/contract';
import ContractInfoScreen from '../screens/contract/ContractInfo';
import StatementCheckerScreen from '../screens/statement';
import NewCustomer from '../screens/new_customer';
import Notification from '../screens/notification';
import TrackPayment from '../screens/track_payment';
import TrackPaymentInfo from '../screens/track_payment/TrackPaymentInfo';
import ThaiQRLogScreen from '../screens/thaiqr';
import ManageUserScreen from '../screens/manageUser';
import CreateAccountScreen from '../screens/account/create_account';
import CreateEmailScreen from '../screens/account/create_email';
import ManageAccountScreen from '../screens/account/manage_account';
import ManageIcloudScreen from '../screens/icloud/manage_icloud';
import ManageTelephoneScreen from '../screens/telephone/manage_telephone';
import TransferScreen from '../screens/transfer';
import MonitorCustomerScreen from '../screens/monitor/monitor-customer';
import SchedulerDashboardScreen from '../screens/monitor/monitor-customer/schedulerDashboard';
import CreatePickScreen from '../screens/stock/createPickScreen';
import ProductsPickScreen from '../screens/stock/productsPickScreen';
import PrintPickFormScreen from '../screens/stock/printPickFormScreen';
import RecieveItemsScreen from '../screens/stock/recieveItemsScreen';
import PickReportScreen from '../screens/stock/report/pickReportScreen';
import CheckStockScreen from '../screens/stock/checkStockScreen';
import MonitorStockScreen from '../screens/stock/report/monitorStockReportScreen';
import AdjustStockScreen from '../screens/stock/adjustStock';
import AdjustStockReportScreen from '../screens/stock/report/adjustStockReportScreen';
import UnlockIcloudAccounting from '../screens/unlock_icloud';
import UnlockIcloudCRM from '../screens/unlock_icloud/unlockIcloudCRM';
import BlacklistCustomerScreen from './../screens/blacklist_customer/index';

import TrackingNoScreen from '../screens/trackingno';
import AddTrackingNoScreen from '../screens/trackingno/add_trackingno';
import TrackingTaskScreen from '../screens/tracking_task';
import DashboardProductsScreen from '../screens/manage_products/dashboardProductsScreen';
import ManagePackageScreen from '../screens/manage_products/managePackageScreen';
import CreatePickNonSerialScreen from '../screens/stock/stock_non_serial/createPickNonSerialScreen';
import RecieveItemsNonSerialScreen from '../screens/stock/stock_non_serial/recieveItemsNonSerialScreen';
import AdjustStockNonSerialScreen from '../screens/stock/stock_non_serial/adjustStockNonSerialScreen';
import PickNonSerialReportScreen from '../screens/stock/report/pickNonSerialReportScreen';
import AdjustStockNonSerialReportScreen from '../screens/stock/report/adjustStockNonserialReportScreen';
import MonitorStockNonSerialScreen from '../screens/stock/report/monitorStockNonSerialReportScreen';
import FollowTrackScreen from '../screens/track/report/followTrackReportScreen';
import FollowTrackDetailScreen from '../screens/track/report/followTrackDetailReportScreen';

import DIFARMaster from '../screens/interface-bc/DIFARMaster';
import DIFAPMaster from '../screens/interface-bc/DIFAPMaster';
import DIFItemMaster from '../screens/interface-bc/DIFItemMaster';
import DIFInvoice from '../screens/interface-bc/DIFInvoice';
import DIFInvoiceDetail from '../screens/interface-bc/DIFInvoiceDetail';
import DIFSerialTrans from '../screens/interface-bc/DIFSerialTrans';
import DIFReceipt from '../screens/interface-bc/DIFReceipt';
import DIFReceiptDetail from '../screens/interface-bc/DIFReceiptDetail';
import DIFRecMoney from '../screens/interface-bc/DIFRecMoney';
import DIFGPInvoice from '../screens/interface-bc/DIFGPInvoice';
import DIFGPInvoiceDetail from '../screens/interface-bc/DIFGPInvoiceDetail';

import BuyStockReport from '../screens/stock/report/buyStockReportScreen';
import ManageDEPScreen from '../screens/stock/manageDEPScreen';
import StockSerialReport from '../screens/stock/report/stockSerialReportScreen';
import StockBranchReport from '../screens/stock/report/stockBranchReportScreen';
import CountStockReport from '../screens/stock/report/countStockReportScreen';

import MiradoreConfig from '../screens/miradore';
import ReportTrackingFee from '../screens/report_tracking/reportTrackingFee';
import ReportTrackingDaily from '../screens/report_tracking/reportTrackingDaily';
import ReportTrackingAgent from '../screens/report_tracking/reportTrackingAgent';
import InterestRateRefinanceTableScreen from '../screens/interest_rate_refinace';
import ReportDownPaymentScreen from '../screens/down_payment/report_down_payment';
import ReportDownPaymentDailyScreen from '../screens/down_payment/report_down_payment_daily';
import IcloudTrackingStatusScreen from '../screens/icloud/tracking_status';
import IcloudTrackingStatusLogScreen from '../screens/icloud/tracking_status_log';
import StatementDownPaymentScreen from '../screens/down_payment/statement_down_payment';
import FinanceDownPaymentScreen from '../screens/down_payment/finance_down_payment';
import ApproveSecondHandScreen from '../screens/second_hand/page/approve_second_hand';
import BuySecondHandReportScreen from '../screens/second_hand/page';
import AddNewRecordSecondHand from '../screens/second_hand/page/addNewRecord';
import EditRecordSecondHand from '../screens/second_hand/page/editRecord';
import ManageSpecialProductScreen from '../screens/stock/manageSpecialProductScreen';
import EditSecondHandPriceScreen from '../screens/second_hand/page/edit-price';
import MovementReportScreen from '../screens/stock/report/movementReportScreen';

import AcknowledgementReceiptTrackingNoReport from '../screens/trackingno/reports/AcknowledgementReceiptTrackingNoReport'
import AddAcknowledgementTrackingNo from '../screens/trackingno/reports/pages/AddAcknowledgementTrackingNo'
import TerminatedContractReport from '../screens/trackingno/reports/TerminatedContractReport';
import MistakeTerminateContractPage from '../screens/contract/pages/MistakeTerminateContractPage';
import CheckLocationHistoryPage from '../screens/report_tracking/pages/CheckLocationHistoryPage';
import ReserveProductStockReportScreen from '../screens/stock/report/reserveProductStockReportScreen';
import DeviceReturnScreen from '../screens/device-return/index'
import TrackingTaskManageScreen from '../screens/tracking_task/TrackingTaskManageScreen';
import SignRecordSecondHand from '../screens/second_hand/page/signRecord';
import * as FGF from '../screens/friend-get-friend/screens'

/* APH */
import AphBuySecondHandReportScreen from '../screens/second_hand_aph/page';
import AphAddNewRecordSecondHand from '../screens/second_hand_aph/page/addNewRecord';
import AphEditRecordSecondHand from '../screens/second_hand_aph/page/editRecord';
import AphApproveSecondHandScreen from '../screens/second_hand_aph/page/approve_second_hand';
import AphSignRecordSecondHand from '../screens/second_hand_aph/page/signRecord';
import AphSignRecordSecondHandByCustomer from '../screens/second_hand_aph/page/signRecordByCustomer';

const ToV2 = () => {
  window.location.assign(window.location.pathname)
  return <></>
}

const Route = () => {
  return (
    <Switch>
      <PrivateRoute exact path={subPath.Customer} component={CustomerScreen} />
      <PrivateRoute exact path={subPath.Dashboard} component={DashboardScreen} />
      <PrivateRoute exact path={subPath.Transaction} component={TransactionScreen} />
      <PrivateRoute exact path={subPath.TransactionTradesys} component={TransactionTradesysScreen} />
      <PrivateRoute exact path={subPath.TransactionInfo} component={TransactionInfoScreen} />
      <PrivateRoute exact path={subPath.Product} component={ProductScreen} />
      <PrivateRoute exact path={subPath.ProductInfo} component={ProductInfoScreen} />
      <PrivateRoute exact path={subPath.ProductSerial} component={ProductSerialScreen} />
      <PrivateRoute exact path={subPath.Import} component={ImportScreen} />
      <PrivateRoute exact path={subPath.Payment} component={PaymentScreen} />

      <PrivateRoute exact path={subPath.CustomerInfo} component={CustomerInfoScreen} />
      <PrivateRoute exact path={subPath.Contract} component={ContractScreen} />
      <PrivateRoute exact path={subPath.ContractInfo} component={ContractInfoScreen} />
      <PrivateRoute exact path={subPath.StatementChecker} component={StatementCheckerScreen} />
      <PrivateRoute exact path={subPath.NewCustomer} component={NewCustomer} />
      <PrivateRoute exact path={subPath.Notification} component={Notification} />
      <PrivateRoute exact path={subPath.TrackPayment} component={TrackPayment} />
      <PrivateRoute exact path={subPath.TrackPaymentInfo} component={TrackPaymentInfo} />
      <PrivateRoute exact path={subPath.ThaiQRLog} component={ThaiQRLogScreen} />
      <PrivateRoute exact path={subPath.ManageUser} component={ManageUserScreen} />
      <PrivateRoute exact path={subPath.CreateAccount} component={CreateAccountScreen} />
      <PrivateRoute exact path={subPath.ManageAccount} component={ManageAccountScreen} />
      <PrivateRoute exact path={subPath.ManageIcloudAccount} component={ManageIcloudScreen} />
      <PrivateRoute exact path={subPath.ManageTelephone} component={ManageTelephoneScreen} />
      <PrivateRoute exact path={subPath.CreateEmail} component={CreateEmailScreen} />


      <PrivateRoute exact path={subPath.Transfer} component={TransferScreen} />
      <PrivateRoute exact path={subPath.MonitorCustomer} component={MonitorCustomerScreen} />
      <PrivateRoute exact path={subPath.SchedulerDashboard} component={SchedulerDashboardScreen} />

      <PrivateRoute exact path={subPath.CreatePick} component={CreatePickScreen} />
      <PrivateRoute exact path={subPath.ProductsPick} component={ProductsPickScreen} />
      <PrivateRoute exact path={subPath.PrintPick} component={PrintPickFormScreen} />
      <PrivateRoute exact path={subPath.ReciveItems} component={RecieveItemsScreen} />
      <PrivateRoute exact path={subPath.ReportPick} component={PickReportScreen} />
      <PrivateRoute exact path={subPath.CheckStock} component={CheckStockScreen} />
      <PrivateRoute exact path={subPath.MonitorStockReport} component={MonitorStockScreen} />
      <PrivateRoute exact path={subPath.AdjustStock} component={AdjustStockScreen} />
      <PrivateRoute exact path={subPath.AdjustStockReport} component={AdjustStockReportScreen} />

      <PrivateRoute exact path={subPath.UnlockIcloudAccounting} component={UnlockIcloudAccounting} />
      <PrivateRoute exact path={subPath.UnlockIcloudCRM} component={UnlockIcloudCRM} />

      <PrivateRoute exact path={subPath.TrackingNoInfo} component={TrackingNoScreen} />
      <PrivateRoute exact path={subPath.AddTrackingNo} component={AddTrackingNoScreen} />
      <PrivateRoute exact path={subPath.TrackingTaskInfo} component={TrackingTaskScreen} />
      <PrivateRoute exact path={subPath.BlacklistCustomer} component={BlacklistCustomerScreen} />
      <PrivateRoute exact path={subPath.DashboardProducts} component={DashboardProductsScreen} />
      <PrivateRoute exact path={subPath.ManagePackage} component={ManagePackageScreen} />
      <PrivateRoute exact path={subPath.CreatePickNonSerial} component={CreatePickNonSerialScreen} />
      <PrivateRoute exact path={subPath.RecieveItemsNonSerial} component={RecieveItemsNonSerialScreen} />
      <PrivateRoute exact path={subPath.AdjustStockNonSerial} component={AdjustStockNonSerialScreen} />

      <PrivateRoute exact path={subPath.PickNonSerialReport} component={PickNonSerialReportScreen} />
      <PrivateRoute exact path={subPath.AdjustStockNonSerialReport} component={AdjustStockNonSerialReportScreen} />
      <PrivateRoute exact path={subPath.MonitorStockNonserialReport} component={MonitorStockNonSerialScreen} />

      <PrivateRoute exact path={subPath.FollowTrack} component={FollowTrackScreen} />
      <PrivateRoute exact path={subPath.FollowTrackDetail} component={FollowTrackDetailScreen} />

      <PrivateRoute exact path={subPath.DIFARMaster} component={DIFARMaster} />
      <PrivateRoute exact path={subPath.DIFAPMaster} component={DIFAPMaster} />
      <PrivateRoute exact path={subPath.DIFItemMaster} component={DIFItemMaster} />
      <PrivateRoute exact path={subPath.DIFInvoice} component={DIFInvoice} />
      <PrivateRoute exact path={subPath.DIFInvoiceDetail} component={DIFInvoiceDetail} />
      <PrivateRoute exact path={subPath.DIFSerialTrans} component={DIFSerialTrans} />
      <PrivateRoute exact path={subPath.DIFReceipt} component={DIFReceipt} />
      <PrivateRoute exact path={subPath.DIFReceiptDetail} component={DIFReceiptDetail} />
      <PrivateRoute exact path={subPath.DIFRecMoney} component={DIFRecMoney} />
      <PrivateRoute exact path={subPath.DIFGPInvoice} component={DIFGPInvoice} />
      <PrivateRoute exact path={subPath.DIFGPInvoiceDetail} component={DIFGPInvoiceDetail} />

      <PrivateRoute exact path={subPath.BuyStockReport} component={BuyStockReport} />
      <PrivateRoute exact path={subPath.ManageDEPScreen} component={ManageDEPScreen} />
      <PrivateRoute exact path={subPath.StockSerialReport} component={StockSerialReport} />
      <PrivateRoute exact path={subPath.StockBranchReport} component={StockBranchReport} />
      <PrivateRoute exact path={subPath.CountStockReport} component={CountStockReport} />

      <PrivateRoute exact path={subPath.MiradoreConfig} component={MiradoreConfig} />
      <PrivateRoute exact path={subPath.ReportTrackingFee} component={ReportTrackingFee} />
      <PrivateRoute exact path={subPath.ReportTrackingDaily} component={ReportTrackingDaily} />
      <PrivateRoute exact path={subPath.ReportTrackingAgent} component={ReportTrackingAgent} />
      <PrivateRoute exact path={subPath.InterestRateRefinanceTableScreen} component={InterestRateRefinanceTableScreen} />
      <PrivateRoute exact path={subPath.ReportDownPayment} component={ReportDownPaymentScreen} />
      <PrivateRoute exact path={subPath.ReportDownPaymentDaily} component={ReportDownPaymentDailyScreen} />
      <PrivateRoute exact path={subPath.IcloudTrackingStatus} component={IcloudTrackingStatusScreen} />
      <PrivateRoute exact path={subPath.IcloudTrackingStatusLog} component={IcloudTrackingStatusLogScreen} />
      <PrivateRoute exact path={subPath.StatementDownPayment} component={StatementDownPaymentScreen} />
      <PrivateRoute exact path={subPath.FinanceDownPayment} component={FinanceDownPaymentScreen} />

      <PrivateRoute exact path={subPath.ApproveSecondHand} component={ApproveSecondHandScreen} />
      <PrivateRoute exact path={subPath.BuySecondHandReportScreen} component={BuySecondHandReportScreen} />
      <PrivateRoute exact path={subPath.AddNewRecordSecondHand} component={AddNewRecordSecondHand} />
      <PrivateRoute exact path={subPath.EditRecordSecondHand} component={EditRecordSecondHand} />
      <PrivateRoute exact path={subPath.EditSecondHandPriceScreen} component={EditSecondHandPriceScreen} />
      <PrivateRoute exact path={subPath.SignRecordSecondHand} component={SignRecordSecondHand} />

      <PrivateRoute exact path={subPath.ManageSpecialProductScreen} component={ManageSpecialProductScreen} />

      <PrivateRoute exact path={subPath.MovementReportScreen} component={MovementReportScreen} />

      <PrivateRoute exact path={subPath.TrackingNoReport} component={AcknowledgementReceiptTrackingNoReport} />
      <PrivateRoute exact path={subPath.AddAcknowledgementTrackingNo} component={AddAcknowledgementTrackingNo} />
      <PrivateRoute exact path={subPath.TerminatedContractReport} component={TerminatedContractReport} />
      <PrivateRoute exact path={subPath.MistakeTerminateContract} component={MistakeTerminateContractPage} />
      <PrivateRoute exact path={subPath.CheckLocationHistory} component={CheckLocationHistoryPage} />

      <PrivateRoute exact path={subPath.ReserveProductStockReportScreen} component={ReserveProductStockReportScreen} />

      <PrivateRoute exact path={subPath.DeviceReturn} component={DeviceReturnScreen} />
      <PrivateRoute exact path={subPath.TrackingTaskManage} component={TrackingTaskManageScreen} />

      <PrivateRoute exact path={subPath.FGFApproveCommission} component={FGF.FGFApproveCommissionListScreen} />

      <PrivateRoute exact path={subPath.AphBuySecondHandReportScreen} component={AphBuySecondHandReportScreen} />
      <PrivateRoute exact path={subPath.AphAddNewRecordSecondHand} component={AphAddNewRecordSecondHand} />
      <PrivateRoute exact path={subPath.AphEditRecordSecondHand} component={AphEditRecordSecondHand} />
      <PrivateRoute exact path={subPath.AphApproveSecondHandScreen} component={AphApproveSecondHandScreen} />
      <PrivateRoute exact path={subPath.AphSignRecordSecondHand} component={AphSignRecordSecondHand} />
      <Routes path={subPath.AphSignRecordSecondHandByCustomer}>
        <AphSignRecordSecondHandByCustomer />
      </Routes>

      <Routes path={'/v2'}>
        <ToV2 />
      </Routes>
    </Switch>
  );
};

export default Route;
