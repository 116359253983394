import moment from 'moment';

export const MonitorCustomerSearchForm = ({ defaultSearch, onClearCustomField, handleRequiredDate, onChangeSelectStatus, options, data }) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      placeholder: 'กรุณากรอกชื่อ-นามสกุล',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
    {
      key: '2',
      span: 6,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      placeholder: 'กรุณากรอกเบอร์โทรศัพท์',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
    {
      key: '3',
      span: 6,
      name: 'status',
      label: 'สถานะ',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionsConsiderStatus,
      allowClear: true,
      // defaultValue: defaultSearch.status,
      onSelect: (value) => {
        onChangeSelectStatus(value);
      },
      value: defaultSearch.status !== null ? defaultSearch.status : null,
      onClear: () => onClearCustomField(),
    },
    {
      key: '4',
      span: 6,
      name: 'type',
      label: 'ประเภท',
      placeholder: 'เลือกประเภท',
      type: 'SELECT',
      options: options.typeNew,
      allowClear: true,
      // value: '',
    },
    {
      key: '5',
      span: 6,
      name: 'dateType',
      label: 'ตัวเลือกค้นหาจากวันที่',
      placeholder: 'เลือกตัวเลือกค้นหา',
      type: 'SELECT',
      options: options.dateType,
      allowClear: true,
      onChangeCustom: (e) => handleRequiredDate(e, 'dateType'),
      rules: {
        required: data?.requiredDate,
      },
    },
    {
      key: '6',
      span: 6,
      name: 'startDate',
      label: 'เริ่มค้นหาจากวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
      onChange: (e) => handleRequiredDate(e, 'startDate'),
      rules: {
        required: data?.requiredDate,
      },
    },
    {
      key: '7',
      span: 6,
      name: 'endDate',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
      onChange: (e) => handleRequiredDate(e, 'endDate'),
      rules: {
        required: data?.requiredDate,
      },
    },
    {
      key: '8',
      span: 6,
      name: 'walk_in',
      label: 'Walk in',
      placeholder: 'เลือก Walk in',
      type: 'SELECT',
      options: options.optionWalkIn,
      allowClear: true,
    },
    {
      key: '9',
      span: 6,
      name: 'branch',
      label: 'สาขา',
      placeholder: 'กรุณาเลือกสาขา',
      type: 'SELECT',
      options: options.branchSpecificList,
      allowClear: true,
      value: '',
    },
    {
      key: '10',
      span: 6,
      name: 'productHand',
      label: 'ลักษณะสินค้า',
      placeholder: 'กรุณาเลือกลักษณะสินค้า',
      type: 'SELECT',
      options: options.optionProductHandFilter,
      allowClear: true,
      value: '',
    },
    {
      key: '11',
      span: 6,
      name: 'remark',
      label: 'หมายเหตุ',
      placeholder: 'กรุณากรอกหมายเหตุ',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
    {
      key: '12',
      span: 6,
      name: 'signOnlineStatus',
      label: 'สถานะเซ็นสัญญาซื้อขายออนไลน์',
      placeholder: 'กรุณาเลือก สถานะเซ็นสัญญาซื้อขายออนไลน์',
      type: 'SELECT',
      allowClear: true,
      options: options.optionSignOnline,
      value: '',
    },
  ];
};

export const FORM_APPOINTMENT = ({ options, defaultValue, fn }) => {
  const { optionBranch } = options;

  return [
    {
      key: '1',
      span: 8,
      name: 'fullName',
      label: 'ชื่อนามสกุล',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 8,
      name: 'phoneNumber',
      label: 'เบอร์โทรศัพท์',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 8,
      name: 'branch',
      label: 'สาขา',
      type: 'SELECT_MATCH_SELECT_WIDTH',
      placeholder: 'เลือกสาขา',
      options: optionBranch || [],
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      span: 7,
      name: 'startDate',
      label: 'เริ่มต้น :',
      type: 'DATE_PICKER',
      format: 'DD/MM/YYYY',
      disabledDate: (d) => !d || d.isSameOrBefore(moment().add(-1, 'days')),
      placeholder: 'เลือกวันเริ่มต้น',
      onChange: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '5',
      span: 5,
      name: 'startTime',
      label: 'เวลา :',
      type: 'TIME_PICKER',
      format: 'HH:mm',
      placeholder: 'เวลา',
      onChange: fn.onChange,
      defaultValue: defaultValue.startTime ? moment(defaultValue.startTime, 'HH:mm') : null,
      rules: {
        required: true,
      },
    },
    {
      key: '6',
      span: 7,
      name: 'endDate',
      label: 'สิ้นสุด :',
      type: 'DATE_PICKER',
      format: 'DD/MM/YYYY',
      disabledDate: (d) => !d || d.isSameOrBefore(moment().add(-1, 'days')),

      placeholder: 'เลือกวันสิ้นสุด',
      rules: {
        required: true,
      },
    },
    {
      key: '7',
      span: 5,
      name: 'endTime',
      label: 'เวลา :',
      type: 'TIME_PICKER',
      format: 'HH:mm',
      placeholder: 'เวลา',
      defaultValue: defaultValue.endTime ? moment(defaultValue.endTime, 'HH:mm') : null,
      rules: {
        required: true,
      },
    },
    {
      key: '8',
      span: 24,
      name: 'remark_appointment',
      label: 'หมายเหตุ(สำหรับลูกค้า + chatcone)',
      labelRed: '*แสดงบนใบนัดแคปให้ลูกค้า',
      type: 'INPUT_AREA',
      rows: 2,
      rules: {
        maxLength: 500,
      },
    },
    {
      key: '9',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ(สำหรับเจ้าหน้าที่)',
      type: 'INPUT_AREA',
      disabled: true,
      maxLength: 200,
      rows: 2,
    },
    {
      key: '10',
      span: 24,
      name: 'show_installment',
      label: 'แสดงยอดผ่อนต่อเดือน',
      type: 'RADIO',
      options: [
        { label: 'แสดง', value: 'SHOW' },
        { label: 'ไม่แสดง', value: 'NOT_SHOW' },
      ],
      defaultValue: defaultValue.show_installment || 'SHOW',
      onChangeRadio: fn.onChange,
      rules: {
        required: true,
      },
    },
  ];
};

export const EditRemarkConsider = () => {
  return [
    {
      key: '1',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      maxLength: 200,
      rows: 3,
      rules: {
        required: true,
      },
    },
  ];
};
