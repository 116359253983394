// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect, useContext, useRef } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Table, Modal, Card, Button, Spin, Col, Row } from 'antd';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { DollarOutlined } from '@ant-design/icons';
import { RefinanceSearchForm } from '../../constants/global/SearchForm'
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { RefinanceColumn } from '../../constants/global/columnTableForm'
import { dataRefinance } from './constant'
import { RecheckTransferModal } from '../../components/modal/Transfer/RecheckTransferModal'
import { GET, POST, GET_CONTRACT_TRANSFER_WAIT_APPROVE, GET_LOOKUP_REFINANCE } from '../../services/index';
import { convertBaht } from "../../functions/fn"
import { convertStrToFormat } from '../../functions/fn';

const Transfer = () => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [valueDefault, setValueDefault] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [summaryContractType, setSummaryContractType] = useState(null);
  const pageRef = useRef(1);
  const sizeRef = useRef(10);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [dataFilter, setDataFilter] = useState({});
  const [totalSize, setTotalSize] = useState(0);

  const option = {
    contractTypeOptions: [
      { value: 'TURN1', label: 'เทิร์น1' },
      { value: 'TURN2', label: 'เทิร์น2' },
      { value: 'SECONDHAND', label: 'ขายขาด' },
      { value: '', label: 'ทั้งหมด' }
    ]
  }

  useEffect(() => {
    getDataTransferWaitApprove()
  }, []);

  useEffect(() => {
    getDataTransferWaitApprove(dataFilter);
  }, [page, size]);


  const getDataTransferWaitApprove = async (data) => {

    try {
      setLoading(true);
      let res = await GET(GET_CONTRACT_TRANSFER_WAIT_APPROVE(data && data.contractNo || '', data && data.contractType || '', 'page=' + page + '&size=' + size));
      const { success, result } = res;
      setDataFilter(data);

      if (success) {
        const resultData = result.turnPreApprove.map((val, i) => {
          return {
            rowNo: i + 1,
            ...val,
            lease_amount: convertBaht(val.lease_amount) || '',
            productName: val.product?.name || '',
            customerName: val.user.general?.fullname || '',
            imageFrontIdCard: val.image?.url || ''
          }
        })

        const summaryContractType = result?.summaryContractType
        
        setSummaryContractType(summaryContractType);
        setDataSource(resultData) 
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onPageSizeChangeCustom = (pageData, sizeData) => {
    
    const sizeChange = sizeRef.current !== sizeData;
    if (sizeChange) {
      // setPageState(1)
      pageRef.current = 1;
      setPage(1);
    } else {
      // setPageState(pageData)
      pageRef.current = pageData;
      setPage(pageData);
    }
    sizeRef.current = sizeData;
    setSize(sizeData);
  };

  const closeModal = () => {
    setValueDefault({});
    setVisible(false);
  };
  const openModal = (record) => {

    setValueDefault(record);
    setVisible(true);
  };
  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'ทำรายการโอนเงิน',
              icon: <DollarOutlined />,
              spanSearch: 24,
              formSearch: RefinanceSearchForm({ option, defaultValue: { contractType: '' } }),
              onSearch: (data) => getDataTransferWaitApprove(data),
              onChangeTab: (data) => setDataFilter(data)
            }}

            tableLayout={{
              columns: RefinanceColumn({ openModal }),
              dataSource: dataSource,
              extraContent: summaryContractType && (
              <Col span={21}>
                <Row style={{ justifyContent: 'start' }}>
                  <Col>
                    <Card style={{ backgroundColor: '#ffc107', fontSize: '20px' }} className="card-summary">
                      <span>{`เทิร์น1 : `}</span>
                      <span>{ `${summaryContractType?.TURN1} สัญญา`}</span>
                    </Card>
                  </Col>

                  <Col>
                    <Card style={{ backgroundColor: '#2457C5', fontSize: '20px' }} className="card-summary">
                      <span>{`เทิร์น2 : `}</span>
                      <span>{ `${summaryContractType?.TURN2} สัญญา`}</span>
                    </Card>
                  </Col>

                  <Col>
                    <Card style={{ backgroundColor: '#FF698A', fontSize: '20px' }} className="card-summary">
                      <span>{`ขายขาด : `}</span>
                      <span>{ `${summaryContractType?.SECONDHAND} สัญญา`}</span>
                    </Card>
                  </Col>
                </Row>
              </Col>
              ),
              pagination: {
                showSizeChanger: true,
                current: page,
                pageSize: size,
                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                pageSizeOptions: ['10', '20', '30', '40', '100'],
                total: totalSize || 0,
                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: size,
                defaultCurrent: 1,
              }
            }}
          />
        </Spin>
      </Card>
      <RecheckTransferModal visible={visible} title={'ตรวจสอบข้อมูล'} onClose={closeModal} data={{ record: valueDefault, reApi: getDataTransferWaitApprove }} />
    </Wrapper>
  );
};

export default Transfer;
