import {useCallback, useEffect, useState} from 'react';
import {Input, Typography, Row, Col, Spin} from 'antd';
import _ from 'lodash';
import {ButtonTheme} from '../../../../components';
import {CloseOutlined, LoadingOutlined} from '@ant-design/icons';
import {useProductData} from '../hooks/useProductProductData';

const {Text} = Typography;
const loadingIcon = <LoadingOutlined spin />;

export const SerialNumberInput = (props) => {
  const {onChange, setErrorMessage, onDelete, onClear, type, product, form} = props;
  const [productList, setProductList] = useState(product || []);
  const [value, setValue] = useState('');
  const productsList = _.cloneDeep(productList);

  const [productSerialOneData, productSerialOneLoading, productSerialOneSearchFn] = useProductData();

  useEffect(() => {
    if (productSerialOneData) {
      const {productSale, productCode, saleGrade, productName} = productSerialOneData;

      const updatedList = _.cloneDeep(productList);

      if (productSale?.length > 0) {
        const result = {
          code: productCode,
          key: value,
          serialnumber: value,
          name: productName,
          saleGrade: saleGrade,
        };
        updatedList.push(result);
      } else {
        const noProduct = {code: '', name: 'ไม่พบข้อมูล', key: value, serialnumber: value, price: 0, price2: 0};
        updatedList.push(noProduct);
      }
      setProductList(updatedList);
      onChange({productList: updatedList, productSale});
      setValue('');
      productSerialOneSearchFn('', false);
    }
  }, [productSerialOneData]);

  useEffect(() => {
    if (onClear) {
      clearFormCallback();
    }
  }, [onClear]);

  const clearFormCallback = useCallback(() => {
    setProductList([]);
    setValue('');
    onChange([]);
  }, [onChange]);

  const tagStyle = {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#c7c7c7',
    bordeRadius: '14px',
    border: '1px solid #eeeeee',
    paddingInline: '7px',
    marginBlock: '10px',
    display: ' inline-block',
    minWidth: '100px',
    maxWidth: '265px',
    width: '265px',
    textAlign: 'left',
    background: '#f5f5f5',
  };

  const textstyle = {
    fontSize: '14px',
    lineHeight: '22px',
    paddingInline: '7px',
    marginBlock: '10px',
    display: ' inline-block',
    minWidth: '100px',
    maxWidth: '310px',
    width: '310px',
    textAlign: 'left',
  };

  const handleEnterProductList = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const barcode = event.target.value;
      if (!barcode) {
        return;
      }

      if (barcode.indexOf(' ') >= 0) {
        return setErrorMessage({
          form,
          error: true,
          name: 'serial_number',
          txtError: ['Serial number ไม่ควรมีช่องว่าง กรุณาเช็ค Serial number ใหม่อีกครั้ง'],
        });
      }

      let filterDulplicate = productsList.filter((el) => el.key === barcode);
      if (filterDulplicate.length > 0) {
        return setErrorMessage({form, error: true, name: 'serial_number', txtError: ['Serial number ซ้ำกัน กรุณาเช็ค Serial number ใหม่อีกครั้ง']});
      }

      productSerialOneSearchFn(barcode, true, type);
      return setErrorMessage({form, error: false, name: 'serial_number', txtError: []});
    }
  };

  const handleDeleteSerialNumber = (key) => {
    let updatedArr = productsList.filter((element) => element.key !== key);
    setProductList(updatedArr);
    onDelete(updatedArr);
  };

  return (
    <>
      <Input
        name="serial_number"
        suffix={productSerialOneLoading ? <Spin indicator={loadingIcon} /> : null}
        placeholder="พิมพ์แล้วกด enter เช่น G6TF21DT0D95"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleEnterProductList}
      />
      <div style={{marginTop: 10}}>
        {productList &&
          productList.map((el) => {
            return (
              <Row key={el.key}>
                <Col span={11}>
                  <Text style={tagStyle} color="gray">
                    {el.serialnumber}
                  </Text>
                </Col>
                <Col span={2}>
                  <ButtonTheme
                    size="small"
                    useFor="CUSTOM"
                    style={{color: '#000000', borderColor: '#c7c7c7', borderRadius: 50, marginTop: 10}}
                    bgcolor="white"
                    icon={<CloseOutlined size={10} />}
                    title=""
                    onClick={() => handleDeleteSerialNumber(el.key)}
                  />
                </Col>
                <Col span={9}>
                  <Text style={textstyle}>{el.name}</Text>
                </Col>
              </Row>
            );
          })}
      </div>
    </>
  );
};
