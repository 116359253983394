import {useCallback, useEffect, useState} from 'react';
import {Modal} from 'antd';
import {getProductData} from '../../../../services/api/svAPI';

export const useProductData = () => {
  const [dataProduct, setDataProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [enter, setEnter] = useState(false);
  const [type, setType] = useState(false);

  useEffect(async () => {
    if (value !== '' && enter) {
      const params = {
        type: type,
        serialNumber: [value.trim()],
      };
      setLoading(true);
      try {
        const res = await getProductData(params);
        const {result} = res;
        setDataProduct(result);
      } catch (err) {
        console.error(err.message);
        Modal.error({title: 'เกิดข้อผิดพลาด', content: err?.message});
      } finally {
        setLoading(false);
      }
    }
  }, [value, enter]);

  const handleSearch = useCallback((value, enter, type) => {
    setValue(value);
    setEnter(enter);
    setType(type);
  }, []);

  return [dataProduct, loading, handleSearch];
};
