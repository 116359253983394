const masterInsuranceStatus = [
  {label: 'มี', value: 'YES'},
  {label: 'ไม่มี', value: 'NO'},
];

const masterContractType = [
  {label: 'ผ่อนสินค้า', value: 'INSTALLMENT'},
  {label: 'เทิร์นเครื่องเก่ารับเครื่องเดิม', value: 'TURN1'},
  {label: 'เทิร์นเครื่องเก่ารับเครื่องใหม่', value: 'TURN2'},
  {label: 'ขายเครื่องให้ราคาสูง', value: 'SECONDHAND'},
];

export {masterInsuranceStatus, masterContractType};
