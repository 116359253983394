import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Select} from 'antd';
import {GET, GET_BANK_OPTION} from '../../../services';

const SelectBank = (props) => {
  const {placeholder, handleChange, dataResponse, multiple, ...otherProp} = props;
  const [dataSc, setDataSc] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await GET(GET_BANK_OPTION);
      const {message, data} = res;
      if (message === 'get bank options success') {
        const result = _.map(data?.bank?.bank, (el) => {
          return {
            label: el?.bankName,
            value: el?.bankCode,
            source: el,
          };
        });

        setDataSc(result);

        if (dataResponse) {
          dataResponse(result);
        }
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Select
      {...otherProp}
      style={{width: '100%'}}
      options={dataSc}
      placeholder={placeholder}
      loading={loading}
      showSearch
      onChange={(value) => (multiple ? handleChange(value) : handleChange(_.find(dataSc, (data) => data?.value === value)))}
      multiple={multiple}
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    />
  );
};

SelectBank.propTypes = {
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.object,
  dataResponse: PropTypes.func,
};

export default SelectBank;
