import React, {useEffect, useMemo, useState} from 'react';
import {Form, Select, Input, Divider, Row, Col, InputNumber, Modal, Space, DatePicker} from 'antd';
import _ from 'lodash';
import {CONTRACT_TYPE} from '../constants';
import Example from '../../../../assets/images/example_detail.jpg';
import {convertNumberToComma} from '../../../../functions/fn';
import {handleTrimValue} from '../../../../utils/antform';
import {disabledBeforeCurrentDate} from '../../../../utils/date';
import {ButtonTheme, Spinners, Upload} from '../../../../components';
import {SelectProductAll, SelectProductCondition} from '../../../../components/inputfromapi';
import {GCS_BUCKET_CONTRACT_IMAGE, GCS_PATH_CONTRACT_IMAGE} from '../../../../env';
import {getProductData} from '../../../../services/api/svAPI';
import {SelectInsuranceStatus} from '../../../../components/masterdata';

/*
coupon: [
  {
    coupon_id: 'PON-25-0006',
    coupon_code: 'REGISTERCOUPON',
    coupon_discount: 200,
  },
];
*/
const formName = 'FormTurn2Turn';
const {TURN2} = CONTRACT_TYPE;
const ININT_CAL = {
  contractType: TURN2, //ประเภทสัญญา
  condition: '', //เงื่อนไข
  price: 0, //ราคา
  productCode: '', //รหัสสินค้า
};

const FormTurn2Turn = ({defaultData, data, setActiveTab, turnState, setTurnState}) => {
  const {options, admin_branch, contractType} = data;

  /* Form */
  const [form] = Form.useForm();
  /* Product */
  const [productTurnData, setProductTurnData] = useState(turnState?.state?.productTurnData || {});
  /* Maximum */
  const [maxPrice, setMaxPrice] = useState(turnState?.state?.maxPrice || 0);
  /* Insurance */
  const [insuranceStatus, setInsuranceStatus] = useState(turnState?.formData?.insurance_status === 'YES' ? 'YES' : 'NO');
  /* Calculate */
  const [calculateData, setCalculateData] = useState(turnState?.state?.calculateData || {...ININT_CAL});
  /* File */
  const [fileMachineDetailImageList, setFileMachineDetailImageList] = useState(turnState?.state?.fileMachineDetailImageList || []);
  const [fileImeiImageList, setFileImeiImageList] = useState(turnState?.state?.fileImeiImageList || []);
  const [fileOtherImageList, setFileOtherImageList] = useState(turnState?.state?.fileOtherImageList || []);
  /* Modal */
  const [isModalVisible, setIsModalVisible] = useState(false);
  /* Loading API */
  const [machineDetailImageLoading, setMachineDetailImageLoading] = useState(false);
  const [imeiImageLoading, setImeiImageLoading] = useState(false);
  const [otherImageLoading, setOtherImageLoading] = useState(false);
  const [productDataLoading, setProductDataLoading] = useState(false);

  /* --------- useMemo calculateData ---------*/
  const calDtMemo = useMemo(() => {
    return calculateData;
  }, [calculateData]);

  /* --------- useEffect set default data ---------*/
  useEffect(() => {
    if (!_.isEmpty(defaultData)) {
      const {condition, serialNumber, productTurn} = defaultData;

      form.setFieldsValue({
        branch: admin_branch,
        condition: condition,
        product_code: productTurn?.code,
        serial_number: serialNumber,
        ...turnState?.formData,
      });

      setCalculateData((prev) => ({
        ...prev,
        condition: turnState?.formData?.condition || condition,
        productCode: turnState?.formData?.product_code || productTurn?.code,
      }));
    }
  }, [defaultData]);

  /* --------- useEffect SetMaxPrice ---------*/
  useEffect(() => {
    const maxPrice = productTurnData?.productTurn?.price || 0;
    setMaxPrice(maxPrice);
  }, [calDtMemo?.productCode, calDtMemo?.condition, productTurnData]);

  /* --------- useEffect Get Product ---------*/
  useEffect(() => {
    if (contractType && !_.isEmpty(calDtMemo?.productCode) && calDtMemo?.condition)
      getProductDataFn({
        type: contractType,
        productCode: calDtMemo?.productCode,
        condition: calDtMemo?.condition,
        isTurn: true,
      });
  }, [contractType, calDtMemo?.productCode, calDtMemo?.condition]);

  const getProductDataFn = async (params) => {
    try {
      if (!_.isEmpty(params)) {
        setProductDataLoading(true);

        const response = await getProductData(params);
        const {success, result} = response;

        if (success) {
          setProductTurnData(result);
        }
      }
    } catch (err) {
      Modal.error({
        title: 'ไม่สามารถดึงข้อมูลสินค้าได้',
        content: err?.message,
      });
    } finally {
      setProductDataLoading(false);
    }
  };

  /* --------- Fn ---------*/
  const handleProductChange = (value) => {
    form.setFields([{name: 'product_code', value: value, errors: []}]);
    setCalculateData((prev) => ({...prev, productCode: value}));
  };

  const handleConditionChange = (value) => {
    form.setFields([{name: 'condition', value: value, errors: []}]);
    setCalculateData((prev) => ({...prev, condition: value}));
  };

  const handlePriceChange = (value) => {
    const rawValue = value?.replace(/,/g, ''); // ลบ "," ออกจากตัวเลข
    const newValue = Number(rawValue) || 0;
    const finalValue = Math.min(newValue, maxPrice || Number.MAX_SAFE_INTEGER); // จำกัดค่ามากสุด
    form.setFields([{name: 'price', value: finalValue, errors: []}]);
    setCalculateData((prev) => ({...prev, price: finalValue}));
  };

  const handleChangeInsuranceStatus = (e) => {
    form.setFieldsValue({insurance_status: e});
    if (e === 'NO') {
      form.setFieldsValue({insurance_date: null});
    }
    setInsuranceStatus(e);
  };

  const handleClearForm = () => {
    form.resetFields();
    setProductTurnData({});
    setMaxPrice(0);
    setInsuranceStatus(null);
    setCalculateData({...ININT_CAL});
    setFileMachineDetailImageList([]);
    setFileImeiImageList([]);
    setFileOtherImageList([]);
    setTurnState({});
  };

  const onChangeTab = async (formData) => {
    setTurnState({
      formData: formData,
      state: {productTurnData, maxPrice, calculateData, fileMachineDetailImageList, fileImeiImageList, fileOtherImageList},
    });
    setActiveTab('PRODUCT_NEW');
  };

  return (
    <>
      {productDataLoading && <Spinners />}
      <Form
        form={form}
        name={formName}
        layout={'horizontal'}
        labelAlign="right"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
        style={{padding: '0px 20px 20px 20px'}}
        onFinish={onChangeTab}
        onSubmit={(e) => e.preventDefault()}
        autoComplete="off"
        scrollToFirstError>
        <Form.Item name={'product_code'} label="ค้นหาสินค้า" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <SelectProductAll placeholder={'เลือกสินค้า'} handleChange={(e) => handleProductChange(e?.value)} filter={{type: contractType}} />
        </Form.Item>

        <Form.Item
          name={'serial_number'}
          label="ซีเรียลเครื่อง"
          rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}
          extra={`ห้ามใส่ ' - ' และช่องว่าง เมื่อสร้างสัญญาใหม่โดยใช้เลขซีเรียลเครื่องเดิม`}>
          <Input onChange={(e) => handleTrimValue(form, 'serial_number', e?.target?.value)} />
        </Form.Item>

        <Divider />

        <Form.Item name={'branch'} label="สาขา" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]} initialValue={admin_branch}>
          <Select options={options?.choicesOptionBranch || []} />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'condition'} label="Condition" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectProductCondition placeholder={'เลือก Condition'} handleChange={(e) => handleConditionChange(e?.value)} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={'price'}
              label="ราคา"
              labelCol={{span: 8}}
              rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}
              extra={`สูงสุด ${maxPrice ? convertNumberToComma(maxPrice) : ''}`}>
              <InputNumber
                style={{width: '100%'}}
                min={0}
                max={maxPrice || Number.MAX_SAFE_INTEGER}
                formatter={(value, info) => {
                  if (!info.userTyping) {
                    return convertNumberToComma(value);
                  } else {
                    return value;
                  }
                }}
                onBlur={(e) => handlePriceChange(e?.target?.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Col span={12}>
          <Form.Item name={'imei'} label="IMEI" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
            <Input placeholder={'กรุณากรอกหมายเลขเครื่อง IMEI'} onChange={(e) => handleTrimValue(form, 'imei', e?.target?.value)} />
          </Form.Item>
        </Col>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'insurance_status'} label="ประกัน" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectInsuranceStatus placeholder={'กรุณาเลือกสถานะประกันของเครื่อง'} onChange={(e) => handleChangeInsuranceStatus(e)} />
            </Form.Item>
          </Col>
          {insuranceStatus === 'YES' && (
            <Col span={12}>
              <Form.Item name={'insurance_date'} label="วันที่หมดประกัน" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
                <DatePicker style={{width: '100%'}} placeholder={'กรุณากรอกวันที่หมดประกัน'} disabledDate={disabledBeforeCurrentDate} />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Form.Item
          name={'machineDetailImage'}
          label="รูปรายละเอียด"
          labelCol={{span: 4}}
          rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}
          extra={
            <Space size={[36, 16]} wrap>
              <span>{`เข้าไปที่ การตั้งค่า > เกี่ยวกับ`}</span>
              <span className="text-link" onClick={() => setIsModalVisible(true)}>
                ดูรูปตัวอย่าง
              </span>
            </Space>
          }>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileMachineDetailImageList}
            setLoading={setMachineDetailImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'machineDetailImage', value: files[0]?.url, errors: []}]);
              setFileMachineDetailImageList(files);
            }}
            handleRemove={() => {
              form.setFields([{name: 'machineDetailImage', value: null, errors: []}]);
              setFileMachineDetailImageList([]);
            }}
          />
        </Form.Item>

        <Form.Item name={'imeiImage'} label="รูป IMEI" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]} labelCol={{span: 4}}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileImeiImageList}
            setLoading={setImeiImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'imeiImage', value: files[0]?.url, errors: []}]);
              setFileImeiImageList(files);
            }}
            handleRemove={() => {
              form.setFields([{name: 'imeiImage', value: null, errors: []}]);
              setFileImeiImageList([]);
            }}
          />
        </Form.Item>

        <Form.Item name={'otherImage'} label="รูปอื่นๆ" labelCol={{span: 4}}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileOtherImageList}
            setLoading={setOtherImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'otherImage', value: files[0]?.url, errors: []}]);
              setFileOtherImageList(files);
            }}
            handleRemove={() => {
              form.setFields([{name: 'otherImage', value: null, errors: []}]);
              setFileOtherImageList([]);
            }}
          />
        </Form.Item>

        <Row justify={'center'}>
          <ButtonTheme useFor="CLEAR" style={{width: 150}} onClick={() => handleClearForm()} />
          <ButtonTheme
            useFor="CUSTOM"
            htmlType="submit"
            title={'ถัดไป'}
            style={{width: 150}}
            loading={machineDetailImageLoading || imeiImageLoading || otherImageLoading}
          />
        </Row>
      </Form>
      <Modal title="ตัวอย่างรูปรายละเอียดเครื่อง" open={isModalVisible} onCancel={() => setIsModalVisible(false)} width={380} footer={null}>
        <img src={Example} style={{width: '100%'}} alt="No" />
      </Modal>
    </>
  );
};

export default FormTurn2Turn;
