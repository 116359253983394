import { SV_API, handleSVApiError } from '../utils';
const path = 'admin';

export const getConsiderDetail = async (registerId) => {
  try {
    const res = await SV_API.get(`${path}/consider-v2/detail`, {
      params: { registerId },
    });

    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};

/* ดึงราคา */
export const getConsiderCalculatePayment = async (formData) => {
  try {

    const { type, isTurn, productCode, condition, saleGrade, period } = formData

    // /* Validate Field */
    // const requiredFields = isTurn ? [productCode, condition, period] : [productCode, saleGrade, period];
    const requiredFields = (type === 'SECONDHAND' && isTurn) ? [productCode, condition] : isTurn ? [productCode, condition, period] : [productCode, saleGrade, period];

    if (requiredFields.some(field => !field)) {
      throw new Error('INVALID_INPUT')
    }

    const res = await SV_API.post(`${path}/consider-v2/calculate/payment`, formData);

    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};

export const editConsider = async (registerId, formData) => {
  try {
    const res = await SV_API.put(`${path}/consider-v2/edit`,
      formData,
      { params: { registerId } }
    );

    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};