import { Button, Card, Space, Tooltip, Typography } from 'antd'
import { Icon } from '../../../resources/icon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useState } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { color } from '../../../resources/color';

export const FieldGenLink = (props) => {
  const { link, disabled, handleClickGenarate } = props.item;
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    if (!link || disabled) return; // ป้องกันการคัดลอกหากไม่มีลิงก์หรือปิดใช้งาน
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // แสดง "คัดลอกแล้ว!" ชั่วคราว 2 วินาที
  };

  return (
    <Card style={{ marginTop: '1%' }}>
      <div>
        <Space size={[16, 16]}>
          {/* ปุ่มสร้างลิงก์ */}
          <Button type='primary' disabled={disabled} onClick={handleClickGenarate}>
          Generate Link
          </Button>

          {/* ปุ่มคัดลอก */}
          <CopyToClipboard text={link || ''} onCopy={onCopy}>
            <Tooltip title={'คัดลอก'}>
              {copied ? (
                <Space size={[16, 16]}>
                  <CheckCircleOutlined
                    style={{
                      padding: 6,
                      color: color.success,
                      fontSize: '20px',
                    }}
                  />
                  <Typography.Text type='success'>คัดลอกแล้ว!</Typography.Text>
                </Space>
              ) : (
                <Space size={[16, 16]}>
                  <Button disabled={disabled || !link} icon={<Icon.Copy />} type='ghost'></Button>
                </Space>
              )}
            </Tooltip>
          </CopyToClipboard>
        </Space>
      </div>

      {/* แสดงลิงก์ที่สร้าง */}
      {link && (
        <div style={{ marginTop: '2%' }}>
          <Typography.Text
            style={{
              whiteSpace: "normal",
              wordBreak: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {link}
          </Typography.Text>
        </div>
      )}
    </Card>
  );
};
