import React, { useState, useEffect, act } from 'react';
import { Modal, Steps, Row, Col, Image, Spin, Tooltip, Button, DatePicker } from 'antd';
import { ClockCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../../constants/global/RenderForm.js';
import { ContainerButton } from '../../../../styles/styledComponentGlobal.js';
import { ButtonTheme } from '../../../../components/buttons/index.js';
import { checkIdCardPattern, getAgeMonth, getMonthMinusMonths, monthShortOnly, parseNumber } from '../../../../functions/fn.js';
import _, { get } from 'lodash';
import {APP_URL} from '../../../../env';
import {
  GET,
  POST,
  PUT,
  INSTALLMANT_CALCULATE,
  GET_ADDRESS,
  GET_BUSINESS,
  GET_JOB,
  // EDIT_CONSIDER,
  CONFIRM_CONSIDER,
  RE_RECIEVE,
  REPLACE_RECEIVE,
  INSERT_BLACKLIST_CUSTOMER,
  // CALCULATE_A_SCORE,
  INSTALLMENT_REFINANCE,
  GET_PRODUCT_COMBO,
  GET_REGISTER_SALARY,
} from '../../../../services/index';
import {
  FORM_CONSIDER,
  FORM_PRODUCT_INSTALLMENT,
  FORM_PRODUCT_TURN_STEP1,
  FORM_PERSONAL_INFO,
  FORM_OCCUPATION,
  FORM_PERSONAL_CONTACT,
  FORM_PRODUCT_TURN_STEP2,
} from './form/index.js';
import { auth } from '../../../../utils/firebase.js';
import { registerInstallmentSteps, registerSecondHandSteps, registerTurnSteps } from './constants/step.js';
import noImage from '../../../../assets/images/no_image.jpeg';
import { convertStrToFormat, filterDataAddress, duplicateData } from '../../../../functions/fn.js';
import { Icon } from '../../../../resources/icon.js';
import {
  statusApprove,
  // optionsPeriod,
  optionsCountPerson,
  optionsPrefix,
  optionsIncomeDoc,
  optionsOccupation,
  optionsPhoneSystem,
  optionsExperience,
  optionRelationship,
  optionsTypeLockInstallment,
  optionsTypeLockRefinance
} from '../hook/useConstant.js';
import { useAuthState, useCurrentState } from '../../../../utils/hook.js';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// import { checkBlacklistBeforeApprove } from '../../../../utils/auto-admin/api.js';
import { BlacklistCustomerModal } from '../modal/BlacklistCustomerModal.js';
import { useGetHistory, useUpdateTagChatconeByProcess, useSendChatconeByProcess } from '../../../../hook/chatcone';
import { useLocation } from 'react-router-dom';
import { DatatableLog, Spinners } from '../../../../components/index.js';
import { ApproveHistoryColumn } from '../columns/ApproveHistoryColumn.js';
import { Checkbox } from '../../../../components/ui/index.js';
import moment from 'moment';
import { getEstimateDeviceOptions, getRegisterAllProductColor, getRegisterAllProductModel, getRegisterAllProductStorage, getRegisterBranch, getRegisterProductCodeAndPeriods } from '../../../../apiRegister/index.js';
import { getProductCondition } from '../../../../services/api/svapi/product'
import { getConsiderCalculatePayment, editConsider } from '../../../../services/api/svapi/consider'
import { generateUrlSignOnline } from '../../../../services/api/adminservice/sign-online'

import { type } from './constants/option.js';

const { Step } = Steps;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

export const ApproveCustomerVividModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
    setError,
  } = useForm();

  const { user } = useCurrentState(auth);
  const userAdmin = useAuthState(auth);

  const [registerSteps, setRegisterSteps] = useState([]);
  const [current, setCurrent] = useState(0);
  const [optionsProducts, setOptionsProducts] = useState([]);
  const [optionsStorage, setOptionsStorage] = useState([]);
  const [optionsColor, setOptionsColor] = useState([]);
  const [checkPrefix, setCheckPrefix] = useState(true);
  const [optionDistrict, setOptionDistrict] = useState([]);
  const [optionAmphoe, setOptionAmphoe] = useState([]);
  const [optionProvince, setOptionProvince] = useState([]);
  const [productImage, setProductImage] = useState('');
  const [productNewImage, setProductNewImage] = useState('');
  const [fileListStudentCardImages, setfileListStudentCardImages] = useState([]);
  const [fileListStudentTranscriptImages, setfileListStudentTranscriptImages] = useState([]);
  const [fileListStatement, setfileListStatement] = useState([]);
  const [fileListGovernmentCardImage, setfileListGovernmentCardImage] = useState([]);
  const [proveWork, setProveWork] = useState([]); // รูปยืนยันหนักงานชั่วคราว
  const [imeiImageList, setImeiImageList] = useState([]);
  const [iphoneImageList, setIphoneImageList] = useState([]);
  const [proveOwnBusinessImageList, setProveOwnBusinessImageList] = useState([]);
  const [occupation, setOccupation] = useState('');
  const [jobDescriptionState, setJobDescription] = useState('');
  const [incomeDoc, setIncomeDoc] = useState('');
  const [typeInstallment, setTypeInstallment] = useState('');
  const [optionsAddress, setOptionsAddress] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState([]);
  const [optionsBusinessFullTime, setOptionsBusinessFullTime] = useState([]);
  const [optionsBusinessFullTime2, setOptionsBusinessFullTime2] = useState([]);
  const [optionsBusinessPartTime, setOptionsBusinessPartTime] = useState([]);
  const [optionsBusinessGovernment, setOptionsBusinessGovernment] = useState([]);
  const [optionsBusinessOwn, setOptionsBusinessOwn] = useState([]);
  const [optionsJobDescription, setOptionsJobDescription] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [registerId, setRegisterId] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [userCreateName, setUserCreateName] = useState('');
  const [statusConsider, setStatusConsider] = useState('');
  const [disabledField, setDisableField] = useState(false);
  // const [disabledBFApp, setDisabledBFApp] = useState(false);
  const [openUserBlacklist, setOpenUserBlacklist] = useState(false);
  const [statusAppDefault, setStatusAppDefault] = useState('');
  const [fbBlacklist, setFbBlacklist] = useState({});
  const [optionsDoc, setOptionsDoc] = useState(optionsIncomeDoc);
  const [knownUfriend, setKnownUfriend] = useState('');
  const [optionsBranchUser, setOptionsBranchUser] = useState([]);
  const [optionRefinancePeriod, setOptionRefinancePeriod] = useState([]);
  const { pathname } = useLocation();
  const [sendChatconeByProcessFn, sendChatconeByProcessLoading] = useSendChatconeByProcess()
  const [updateTagChatconeByProcessFn, updateTagChatconeByProcessLoading] = useUpdateTagChatconeByProcess()
  const [getChatconeHistoryFn, getChatconeHistoryData, getChatconeHistoryLoading] = useGetHistory()
  const [checkSendChatcone, setCheckSendChatcone] = useState(false);

  const [dataAscore, setDataAscore] = useState({});
  const [defaultRemark, setDefaultRemark] = useState(null);

  const [isApproveSpecial, setIsApproveSpecial] = useState(false);
  const [optionRefinanceNewPeriod, setOptionRefinanceNewPeriod] = useState([]);
  const [newDownPayment, setNewDownPayment] = useState(null);
  const [newMaximum, setNewMaximum] = useState(null);
  const [fgfData, setFgfData] = useState({});

  const [productGroup, setProductGroup] = useState('');
  const [productCombo, setProductCombo] = useState([]);
  const [productComboData, setProductComboData] = useState([]);
  const [optionAdditionalCheckbox, setOptionAdditionalCheckbox] = useState([]);
  const [optionAvgIncome, setOptionAvgIncome] = useState([]);
  const [optionStudentGrade, setOptionStudentGrade] = useState([]);
  const [downPayments, setDownPayment] = useState(null);
  const [business, setBusiness] = useState('');
  const [businessFullTime, setBusinessFullTime] = useState('');
  const [proveOwnBusinessImageFlag, setProveOwnBusinessImageFlag] = useState('');
  const [registeredBusinessDocImage, setRegisteredBusinessDocImage] = useState([]);
  const [unRegisteredbusinessDocImage, setUnregisteredBusinessDocImage] = useState([]);
  const [saleChannel, setSaleChannel] = useState('');
  const [productShopSaleImage, setProductShopSaleImage] = useState([]);
  const [walkin, setWalkin] = useState(false);
  const [acceptConditions, setAcceptConditions] = useState(null);
  const [riderAppicationImage, setRiderAppicationImage] = useState([]);
  const [comboProductForm, setComboProductForm] = useState([]);
  const [comboPrice, setComboPrice] = useState(0);
  const [visibleBusinessDocField, setVisibleBusinessDocField] = useState(true);
  const [updatedWalkinUser, setUpdatedWalkinUser] = useState('');
  const [defaultTypeLock, setDefaultTypeLock] = useState('');
  const [loadingUpload, setLoadingUpload] = useState(false);

  // State Related to Condition Device
  const [selectedIssues, setSelectedIssues] = useState({});
  const [activePanels, setActivePanels] = useState([])
  const [conditionDevice, setConditionDevice] = useState([]);
  const [defaultSelectedIssues, setDefaultSelectedIssues] = useState({});

  const [defaultCoupon, setDefaultCoupon] = useState([])
  const [couponData, setCouponData] = useState({})
  const [disabledDeleteCoupon, setDisabledDeleteCoupon] = useState([])

  const [urlSignOnline, setUrlSignOnline] = useState(null)
  const [signOnlineStatus, setSignOnlineStatus] = useState('')

  const [optionsProductsNewPrd, setOptionsProductsNewPrd] = useState([]);
  const [optionsStorageNewPrd, setOptionsStorageNewPrd] = useState([]);
  const [optionsColorNewPrd, setOptionsColorNewPrd] = useState([]);

  const [optionsPeriod, setOptionsPeriod] = useState([])
  const [productCode, setProductCode] = useState(null)
  const [productTurnCode, setProductTurnCode] = useState(null)
  const [saleGrade, setSaleGrade] = useState(null)

  const [optionsProductCondition, setOptionsProductCondition] = useState([]);

  const menu_user = JSON.parse(localStorage.getItem('menu_user'));
  const permissions = menu_user.length > 0 && menu_user.filter((el) => el.head === 'Ufriend')[0]?.menu;
  const perAction = permissions.length > 0 && permissions.filter((el) => el.path === '/monitor-customer')[0]?.actions || [];
  useEffect(async () => {
    if (JSON.stringify(data && data.record[0]) !== '{}' && data.record[0] && data?.company === 'VIVID') {
      const {
        userCreateId,
        type,
        model,
        storage,
        color,
        productImage,
        occupation,
        jobDescription,
        incomeDoc,
        imeiImageList,
        iphoneImageList,
        fileListStatement,
        studentImages,
        transcriptOrTermFees,
        registerId,
        userCreateName,
        status,
        statusApp,
        knownUfriendFrom,
        period,
        proveOwnBusinessImage,
        chatcone,
        remark,
        // aScore,
        maximum,
        newMaximum,
        newDownPayment,
        is_approve_special,
        friendGetFriend,
        dateOfBirth,
        installments,
        business,
        companyName,
        companyLocation,
        governmentOfficial,
        proveWork,
        riderAppication,
        proveOwnBusinessImageFlag,
        salesChannels,
        acceptCondition,
        walkin,
        productGroup,
        salesChannelsImage,
        combo,
        downPayment,
        prefix,
        productHand,
        defaultTypeLock,
        urlSignOnline,
        signOnlineStatus,
        model_newPrd,
        storage_newPrd,
        color_newPrd,
        productGroup_newPrd,
        productImage_newPrd,
        estimateDevice,
        // productCode,
        productTurn,
        product,
        discountPeriod,
        discountTurn1,
        changeAmount,
        coupon,
        couponDiscount
      } = data.record[0];
      const dataValue = await Object.keys(data.record[0]);
      // dataValue.forEach((val) => setValue(val, data.record[0][val]));
      _.map(dataValue, async (val) => {
        await setValue(val, data.record[0][val]);
      });

      console.log("AAA ", data.record[0])

      // if (type === 'INSTALLMENT') setProductTurnCode(product.code)
      // if (type === 'TURN1') setProductTurnCode(product.code)
      // if (type === 'TURN2') setProductTurnCode(productTurn.code)
      // if (type === 'SECONDHAND') setProductTurnCode(product.code)

      if (type === 'TURN2') {
        setProductTurnCode(productTurn.code)
      } else {
        setProductTurnCode(product.code)
      }

      if (type === 'SECONDHAND') {
        setValue(newMaximum, maximum)
      }

      setProductCode(product.code)
      setSaleGrade(productHand === 'NEW' ? 'L' : 'A')

      /* สภาพเครื่อง */
      const defaultIssues = estimateDevice
      await onFetchConditionDevice(defaultIssues)

      /* คูปอง */
      const couponList = coupon.map(item => item.coupon_label);

      setDefaultCoupon([discountPeriod?.code, discountTurn1?.code, ...couponList].filter(Boolean))

      setCouponData({ discountPeriod: discountPeriod, discountTurn1: discountTurn1, coupon: coupon })

      // setDisabledDeleteCoupon([discountPeriod?.code, discountTurn1?.code, ...couponList].filter(Boolean))

      /* เงินทอน */
      setValue('change_amount', changeAmount || 0)

      const currentRegisterStep = registerStep(user && user.role, type);
      setRegisterSteps(currentRegisterStep)

      if (type === 'INSTALLMENT') {
        const { firstName2, lastName2, phoneNumber2, relationship2, firstName3, lastName3, phoneNumber3, relationship3 } = data.record[0];
        const fields = [
          { key: 'firstName2', value: firstName2 },
          { key: 'lastName2', value: lastName2 },
          { key: 'phoneNumber2', value: phoneNumber2 },
          { key: 'relationship2', value: relationship2 },
          { key: 'firstName3', value: firstName3 },
          { key: 'lastName3', value: lastName3 },
          { key: 'phoneNumber3', value: phoneNumber3 },
          { key: 'relationship3', value: relationship3 },
        ];

        fields.forEach(({ key, value }) => {
          setValue(key, value || '');
        });
      }

      if (prefix) {
        let checkPrefix = optionsPrefix.filter((el) => el.value === prefix);
        if (checkPrefix.length === 0) {
          setValue('prefix', 'อื่นๆ');
          setValue('otherPrefix', prefix);
          setCheckPrefix(false);
        }
      }

      const proveOwnFlag = proveOwnBusinessImageFlag ? 'REGISTERED_BUSINESS' : 'UNREGISTERED_BUSINESS';
      setValue('walkin', walkin);
      setValue('proveOwnBusinessImageFlag', proveOwnFlag);

      handleVisibleBusinessDocField(proveOwnFlag, salesChannels);

      if (dateOfBirth) {
        const dob = moment(dateOfBirth).add(543, 'years').format('DD/MM/YYYY');
        setValue('dateOfBirth', dob);
        const age = getAgeMonth(dateOfBirth);
        setValue('age', `${age.years} ปี ${age.months} เดือน`);
      } else {
        setValue('dateOfBirth', '');
        setValue('age', '');
      }

      const job = handleJobDescription(jobDescription);
      const income = handleIncomeDoc(job, incomeDoc, occupation);

      setValue('jobDescription', job);
      setValue('incomeDoc', income);

      if (business) {
        const checkBusiness = getBusinessValue(business);

        if (checkBusiness === 'OTHER') {
          setBusinessFullTime('OTHER');
          setValue('businessFullTime', 'OTHER');
          setValue('businessFullTime2', business);
        } else {
          setValue('businessFullTime', business);
        }
        // change if connect API (if the variable change)
        setBusiness(business);
        setValue('businessGovernmentOfficer', business);
        setValue('businessPartTime', business);
        setValue('businessOwn', business);
      }

      if (companyName) {
        // change if connect API (if the variable change)
        setValue('companyNameFullTime', companyName);
        setValue('companyNameGovernmentOfficer', companyName);
        setValue('companyNamePartTime', companyName);
        setValue('companyNameOwn', companyName);
      }

      if (companyLocation) {
        // change if connect API (if the variable change)
        setValue('companyLocationFullTime', companyLocation);
        setValue('companyLocationGovernmentOfficer', companyLocation);
        setValue('companyLocationPartTime', companyLocation);
        setValue('companyLocationOwn', companyLocation);
      }

      if (defaultTypeLock) {
        setValue('typeLock', defaultTypeLock);
      }

      if (
        ['WAIT_APPOINTMENT_PROMISE', 'WAIT_PROMISE', 'SUCCESS_PROMISE', 'NO_PROMISE', 'CANCEL_PROMISE', 'FOLLOWUP', 'REJECTED'].includes(status) ||
        (userCreateId !== user.userId && user && user.role !== 'User') || (data?.action === 'VIEW')
      ) {
        setDisableField(true);
      }

      if (imeiImageList.length !== 0) {
        const imei = imeiImageList.map((el, i) => {
          return {
            uid: i + 1,
            status: 'done',
            ...el,
          };
        });
        setImeiImageList(imei);
      }

      if (iphoneImageList.length !== 0) {
        const iphone = iphoneImageList.map((el, i) => {
          return {
            uid: i + 1,
            status: 'done',
            ...el,
          };
        });
        setIphoneImageList(iphone);
      }

      if (fileListStatement.length !== 0) {
        const statement = fileListStatement.map((el, i) => {
          return {
            uid: i + 1,
            status: 'done',
            ...el,
          };
        });
        setfileListStatement(statement);
      }

      if (governmentOfficial && governmentOfficial.length !== 0) {
        const governmentCardImage = governmentOfficial.map((el, i) => {
          return {
            uid: i + 1,
            status: 'done',
            ...el,
          };
        });
        setfileListGovernmentCardImage(governmentCardImage);
      }

      if (proveWork && proveWork.length !== 0) {
        const proveBusinessPartTimeImageList = proveWork.map((el, i) => {
          return {
            uid: i + 1,
            status: 'done',
            ...el,
          };
        });
        setProveWork(proveBusinessPartTimeImageList);
      }

      if (riderAppication && riderAppication.length !== 0) {
        const proveRidermageList = riderAppication.map((el, i) => {
          return {
            uid: i + 1,
            status: 'done',
            ...el,
          };
        });
        setRiderAppicationImage(proveRidermageList);
      }

      if (proveOwnBusinessImage.length !== 0) {
        const image = proveOwnBusinessImage.map((el, i) => {
          return {
            uid: i + 1,
            status: 'done',
            ...el,
          };
        });

        if (type === 'INSTALLMENT') {
          if (job === 'เจ้าของกิจการ') {
            setUnregisteredBusinessDocImage(image);
          }
        } else {
          if (job === 'เจ้าของกิจการ' && proveOwnBusinessImageFlag === true) {
            setRegisteredBusinessDocImage(image);
          } else if (job === 'เจ้าของกิจการ' && proveOwnBusinessImageFlag === false) {
            setUnregisteredBusinessDocImage(image);
          }
          setProveOwnBusinessImageList(image);
        }
      }

      if (salesChannelsImage && salesChannelsImage.length !== 0) {
        const image = salesChannelsImage.map((el, i) => {
          return {
            uid: i + 1,
            status: 'done',
            ...el,
          };
        });
        if (proveOwnBusinessImageFlag === false && salesChannels === 'OFFLINE') {
          setProductShopSaleImage(image);
        }
        if (proveOwnBusinessImageFlag === true && salesChannels === 'OFFLINE') {
          setUnregisteredBusinessDocImage(image);
        }
      }

      if (studentImages.length !== 0) {
        const student = studentImages.map((el, i) => {
          return {
            uid: i + 1,
            status: 'done',
            ...el,
          };
        });
        setfileListStudentCardImages(student);
      }

      if (transcriptOrTermFees && transcriptOrTermFees.length !== 0) {
        const student = transcriptOrTermFees.map((el, i) => {
          return {
            uid: i + 1,
            status: 'done',
            ...el,
          };
        });
        setfileListStudentTranscriptImages(student);
      }

      if (occupation === 'บุคคลทั่วไป') {
        setOptionsDoc(optionsIncomeDoc.filter((im) => im.value !== 'NO'));
      }

      setStatusAppDefault(statusApp);
      setStatusConsider(status);
      setOccupation(occupation);
      setIncomeDoc(income);
      setRegisterId(registerId);
      setTypeInstallment(type);
      setProductImage(productImage);
      setProductNewImage(productImage_newPrd);
      setKnownUfriend(knownUfriendFrom);
      setJobDescription(job);
      setDefaultRemark(remark);
      // setDataAscore({ aLocalScale: aScore.aLocalScale });
      setIsApproveSpecial(is_approve_special);
      setNewDownPayment(newDownPayment);
      setNewMaximum(newMaximum);
      setFgfData(friendGetFriend); // FGF: หน้าพิจารณา เพิ่มช่องรหัสและผลสรุปว่าได้สิทธิไหม
      setUserCreateName(userCreateName);
      setProductGroup(productGroup);

      setDownPayment(downPayment);
      setSaleChannel(salesChannels);
      setProveOwnBusinessImageFlag(proveOwnBusinessImageFlag ? 'REGISTERED_BUSINESS' : 'UNREGISTERED_BUSINESS');
      setWalkin(walkin);
      setAcceptConditions(acceptCondition);
      setDefaultTypeLock(defaultTypeLock);

      //temp use
      const modelOption = await onFetchOptionsProducts(type, productGroup, productHand, true, type !== 'TURN2');
      onFetchOptionsStorage(type, model, productHand, true, type !== 'TURN2');
      onFetchOptionsColor(type, model, storage, productHand, true, type !== 'TURN2');
      onFetchOptionsBranchUser(type);

      if (type === 'INSTALLMENT') {
        onFetchProductCodeAndPeriods(type, model, storage, color, productHand)
      }

      /* Sign Online */
      if (type !== 'INSTALLMENT') {
        setSignOnlineStatus(signOnlineStatus)
        setUrlSignOnline(urlSignOnline)
      }

      if (type === 'INSTALLMENT') {
        setValue('newMaximum', maximum)
        setValue('newPeriod', period)
        setValue('newInstallments', installments)
        setValue('newDownPayment', downPayment)
      }

      if (type === 'TURN1') {
        setValue('productHand_newPrd', 'SECOND')
        setValue('productGroup_newPrd', productGroup)
        setValue('model_newPrd', model)
        setValue('storage_newPrd', storage)
        setValue('color_newPrd', color)
        setValue('newMaximum', maximum)
        setValue('newPeriod', period)
        setValue('newInstallments', installments)
        setValue('newDownPayment', downPayment)

        // onFetchProductCodeAndPeriods(type, model, storage, color, productHand)

        // onFetchProductCodeAndPeriods(type, model_newPrd, storage_newPrd, color_newPrd, productHand)

        onFetchOptionsProductCondition(product?.code)
      }

      if (type === 'TURN2') {
        setValue('productHand_newPrd', productHand)
        setValue('productGroup_newPrd', productGroup_newPrd)
        setValue('model_newPrd', model_newPrd)
        setValue('storage_newPrd', storage_newPrd)
        setValue('color_newPrd', color_newPrd)
        setValue('newMaximum', maximum)
        setValue('newPeriod', period)
        setValue('newInstallments', installments)
        setValue('newDownPayment', downPayment)

        onFetchOptionsProducts(type, productGroup_newPrd, productHand, true, true);
        onFetchOptionsStorage(type, model_newPrd, productHand, true, true);
        onFetchOptionsColor(type, model_newPrd, storage_newPrd, productHand, true, true);

        // onFetchProductCodeAndPeriods(type, model_newPrd, storage_newPrd, color_newPrd, productHand)

        onFetchOptionsProductCondition(productTurn?.code)
      }

      if (type === 'SECONDHAND') {
        onFetchOptionsProductCondition(product?.code)
      }

      if (productGroup === 'Tablet') {
        setValue('downPayment', downPayment);

        onFetchOptionsProductCombo(model, storage, productHand, type, modelOption, combo, true);
        // }
      }

      onFetchOptionsAddress();
      onFetchOptionsBusiness();
      onFetchOptionsJobDescription();
      onFetchOptionsSalary();
      onFetchOptionStudentGrade();
      // onFetchOptionDoc(type, occupation, downPayment, handleJobDescription(jobDescription));
      onFetchOptionDoc(type, occupation, downPayment, handleJobDescription(jobDescription));

      if (chatcone?.social_id) {
        getChatconeHistoryFn({ ref_id: registerId, type: 'RESULT_REGISTER' });
      }
      // setRegisterSteps(user && user.role === 'User' ? registerUserSteps : registerInstallmentSteps);
    }

    setCheckSendChatcone(false);
  }, [data.record[0], data.company]);

  useEffect(() => {
    /*  Set Default Period */
    if (data.record[0] && current === 1 && ['TURN1', 'TURN2'].includes(typeInstallment)) {

      const { type, model_newPrd, storage_newPrd, color_newPrd, productHand } = data.record[0]

      onFetchProductCodeAndPeriods(type, model_newPrd, storage_newPrd, color_newPrd, productHand)

      onFetchConsiderCalculatePayment({
        type: typeInstallment,
        isTurn: true,
        productCode: productTurnCode,
        condition: getValues('condition_approve'),
        period: getValues('period'),
        down: Number(getValues('newDownPayment')),
        coupon: couponData?.coupon
      })
    }

  }, [data.record[0], current]);

  const registerStep = (role, typeRegister) => {
    let resgisterStepType = [];
    switch (typeRegister) {
      case 'INSTALLMENT':
        resgisterStepType = registerInstallmentSteps;
        break;
      case 'TURN1':
      case 'TURN2':
        resgisterStepType = registerTurnSteps;
        break;
      case 'SECONDHAND':
        resgisterStepType = registerSecondHandSteps;
        break;
      default:
        break;
    }

    const containsTitle = resgisterStepType.some(item => item.title === 'พิจารณา');

    if (role === 'User' && containsTitle) {
      resgisterStepType.pop()
    }
    return resgisterStepType;
  }

  /* --------------------- FETCH API ----------------------- */
  const onFetchConditionDevice = async (defaultIssues) => {
    setLoading(true);
    try {
      const response = await getEstimateDeviceOptions();
      const { result, success } = response;

      if (success) {
        setConditionDevice([...result].sort((a, b) => a.position - b.position));
        setActivePanels(result.map((item) => item.key));

        const initialSelection = handleDefaultConditionDevice(defaultIssues, result)
        console.log('initialSelection :>> ', initialSelection);
        setDefaultSelectedIssues(initialSelection);
      }
    } catch (error) {
      Modal.error({
        title: error?.message || error?.error || 'ไม่สามารถดึงข้อมูล Option Estimate Device',
      });
    } finally {
      setLoading(false);
    }
  };

  const onFetchOptionsProducts = async (type, category, productHand, initDefault, turn) => {
    try {
      const pdHand = productHand || '';
      const payload = {
        type: type,
        category: category,
        productHand: pdHand
      }
      const res = await getRegisterAllProductModel(payload)
      // const res = await GET(GET_PRODUCT_MODEL(type, category, pdHand));
      const model = res.model
        .filter((el) => el.value !== null) // Filter out elements with null value
        .map((el) => ({
          value: el.value,
          label: el.label,
          image: el.image,
          productFeatureCode: el.productFeatureCode,
        }));


      if (type === 'TURN1' || type === 'TURN2') {
        if (initDefault && turn) {
          const initialModelValue = getValues('model_newPrd')
          const cloneModelArr = _.cloneDeep(model)
          const filterModel = cloneModelArr.filter((el) => el.label === initialModelValue)?.[0]?.value;

          setValue('model_newPrd', filterModel)
        }
        setOptionsProductsNewPrd(model);
      }

      if (initDefault && turn) {
        const initialModelValue = getValues('model')
        const cloneModelArr = _.cloneDeep(model)
        const filterModel = cloneModelArr?.filter((el) => el.label === initialModelValue)?.[0]?.value;
        setValue('model', filterModel)
      }

      if (current === 0) {
        setOptionsProducts(model);
      }

      return model;
    } catch (error) {
      console.error('Error fetching options onFetchProductCodeAndPeriods:', error);
      Modal.error({
        title: 'ไม่สามารถดึงข้อมูลได้',
        content: error || ''
      })
    }
  };

  const onFetchOptionsStorage = async (type, model, productHand, initDefault, turn) => {
    const pdHand = productHand || '';
    const payload = {
      type: type,
      model: model,
      productHand: pdHand
    }
    const res = await getRegisterAllProductStorage(payload)
    // let res = await GET(GET_PRODUCT_STORAGE(type, model, pdHand));
    const storage = res.storage.map((el) => {
      return {
        value: el.value,
        label: el.label,
      };
    });
    if (type === 'TURN1' || type === 'TURN2') {
      if (initDefault && turn) {
        const initialStorageValue = getValues('storage_newPrd')
        const cloneStorageArr = _.cloneDeep(storage)
        const filterStorage = cloneStorageArr.filter((el) => el.label === initialStorageValue)?.[0]?.value;
        setValue('storage_newPrd', filterStorage)
      }
      setOptionsStorageNewPrd(storage);
    }

    if (initDefault && turn) {
      const initialStorageValue = getValues('storage')
      const cloneStorageArr = _.cloneDeep(storage)
      const filterStorage = cloneStorageArr.filter((el) => el.label === initialStorageValue)?.[0]?.value;
      setValue('storage', filterStorage)
    }
    if (current === 0) {
      setOptionsStorage(storage);
    }
  };

  const onFetchOptionsColor = async (type, model, storage, productHand, initDefault, turn) => {
    try {
      const pdHand = productHand || '';
      const payload = {
        type: type,
        model: model,
        storage: storage,
        productHand: pdHand
      }
      const res = await getRegisterAllProductColor(payload)
      // let res = await GET(GET_PRODUCT_COLOR(type, model, storage, pdHand));
      const color = res.color.map((el) => {
        return {
          value: el.value,
          label: el.label,
          image: el.image,
        };
      });

      if (type === 'TURN1' || type === 'TURN2') {
        if (initDefault && turn) {
          const initialColorValue = getValues('color_newPrd')
          const cloneColorArr = _.cloneDeep(color)
          const filterColor = cloneColorArr.filter((el) => el.label === initialColorValue)?.[0]?.value;
          setValue('color_newPrd', filterColor)
        }
        setOptionsColorNewPrd(color);
      }

      if (type === 'TURN1') {
        if (!initDefault && !turn) {
          setOptionsColorNewPrd(color);
        }
      }

      if (initDefault && turn) {
        const initialColorValue = getValues('color')
        const cloneColorArr = _.cloneDeep(color)
        const filterColor = cloneColorArr.filter((el) => el.label === initialColorValue)?.[0]?.value;
        setValue('color', filterColor)
      }
      if (current === 0) {
        setOptionsColor(color);
      }
    } catch (error) {
      console.error('Error fetching options onFetchOptionsColor:', error);
      Modal.error({
        title: 'ไม่สามารถดึงข้อมูลได้',
        content: error?.message || ''
      })
    }
  };

  const onFetchOptionsProductCombo = async (name, storage, productHand, type, modelOp, combo, set_value) => {
    try {
      const productHandConvert = handleProductHand(productHand, type);
      const pdFeatureCode = handleFilterProductOption(name, modelOp);
      const res = await POST(GET_PRODUCT_COMBO, {
        name: name,
        storage: storage,
        type: productHandConvert,
        productFeatureCode: pdFeatureCode[0]?.productFeatureCode,
      });
      const { success, result } = res;
      if (success) {
        if (!_.isEmpty(result)) {
          const { checkbox, ...sliceCombo } = result;
          await handleProductCombo(sliceCombo, combo, set_value);
        }
      }
    } catch (error) {
      console.error('Error fetching options:', error);
      const { message } = error;
      Modal.error({
        title: message || 'ไม่สามารถดึงข้อมูล Option Product Combo',
      });
    }
  };


  const onFetchOptionsAddress = async () => {
    const province = getValues('province');
    const zipcode = getValues('zipcode');
    const district = getValues('district');
    const res = await GET(GET_ADDRESS);
    setOptionsAddress(res.address);

    const filterAddress = res.address
      .filter((val) => val.zipcode === zipcode)
      .map((prop) => {
        return prop;
      });

    const arrProvince = filterDataAddress(filterAddress, zipcode, 'province');
    setOptionProvince(duplicateData(arrProvince));
    const arrDistrict = filterDataAddress(filterAddress, district, 'district');
    setOptionDistrict(duplicateData(arrDistrict));
    const arrAmphoe = filterDataAddress(filterAddress, province, 'amphoe');
    setOptionAmphoe(duplicateData(arrAmphoe));
  };

  const onFetchOptionsBusiness = async () => {
    try {
      const res = await GET(GET_BUSINESS);

      if (res.success) {
        const groupedOptions = res?.business.reduce((groups, option) => {
          const { jobOccupation, jobBusinessParrent, jobBusiness } = option;

          // Find or create the group by jobOccupation
          let group = groups.find((g) => g.label === jobOccupation);
          if (!group) {
            group = { label: jobOccupation, options: [] };
            groups.push(group);
          }

          // Find or create the subgroup by jobBusinessParrent
          let subGroup = group.options.find((sg) => sg.label === jobBusinessParrent);
          if (!subGroup) {
            subGroup = { label: jobBusinessParrent, options: [] };
            group.options.push(subGroup);
          }

          // Add the option to the subgroup
          subGroup.options.push({ label: jobBusiness, value: jobBusiness });

          return groups;
        }, []);

        // Split options array into two arrays
        const firstArray = groupedOptions[0]?.options.slice(0, 2);
        const secondArray = groupedOptions[0]?.options.slice(2);

        // Add obj to the end of the firstArray
        const obj = {
          label: 'อื่นๆ',
          options: [{ label: 'อื่นๆ', value: 'OTHER' }],
        };
        firstArray.push(obj);

        setOptionsBusinessFullTime(firstArray || []);
        setOptionsBusinessFullTime2(secondArray || []);
        setOptionsBusinessPartTime(groupedOptions[1]?.options || []);
        setOptionsBusinessGovernment(groupedOptions[2]?.options || []);
        setOptionsBusinessOwn(groupedOptions[3]?.options || []);
      }
    } catch (error) {
      console.error('Error fetching options Business:', error);
    }
  };

  const onFetchOptionsJobDescription = async () => {
    const res = await GET(GET_JOB);
    const optionJob = res.job.map((job) => {
      const updatedLabel = handleJobDescription(job.label);
      return {
        ...job,
        label: updatedLabel,
        value: updatedLabel,
      };
    });
    setOptionsJobDescription(optionJob);
  };

  const onFetchOptionsBranchUser = async (value) => {
    const obj = {
      type: value,
    };
    // const res = await POST(GET_REGISTER_BRANCH_NEW, obj);
    const res = await getRegisterBranch(obj);
    const { success, result } = res;
    if (success) {
      const option = result.map((el) => {
        return {
          value: el.value,
          label: el.label,
        };
      });
      setOptionsBranchUser(option);
    }
  };

  // const onFetchOptionsPeriods = async (type) => {
  //   let productHand = current === 0 ? getValues('productHand') : getValues('productHand_newPrd');
  //   const obj = {
  //     type:
  //       type === 'INSTALLMENT' && productHand === 'NEW'
  //         ? 'INSTALLMENT'
  //         : type === 'INSTALLMENT' && productHand === 'SECOND'
  //           ? 'INSTALLMENTS_SPECIAL'
  //           : 'REFINANCE',
  //     product: {
  //       model: getValues('model'),
  //       storage: getValues('storage'),
  //       color: getValues('color'),
  //     },
  //     period: getValues('period'),
  //     combo: [],
  //   };
  //   const res = await POST(INSTALLMANT_CALCULATE, obj);
  //   const optionPeriod = res.installmentRange.map((el) => {
  //     return {
  //       label: el.period,
  //       value: el.period,
  //     };
  //   });
  //   setOptionRefinancePeriod(optionPeriod);
  // };

  // const getOptionsNewPeriods = async (inputAmount) => {
  //   const amount = inputAmount;

  //   if (amount) {
  //     try {
  //       const res = await GET(INSTALLMENT_REFINANCE(amount));
  //       const optionNewPeriod = res.data.installmentRange.map((el) => ({
  //         label: el.period,
  //         value: el.period,
  //         installments: el.installments,
  //       }));

  //       setOptionRefinanceNewPeriod(optionNewPeriod);
  //     } catch (error) {
  //       console.error('Error fetching options New Period:', error);
  //     }
  //   }
  // };

  // const onFetchOptionsNewPeriods = _.debounce((inputAmount) => {
  //   setOptionRefinanceNewPeriod([]);
  //   getOptionsNewPeriods(inputAmount);
  // }, 1000);

  const onFetchOptionsSalary = async () => {
    try {
      const res = await GET(GET_REGISTER_SALARY);
      const { success, result } = res;
      if (success) {
        setOptionAvgIncome(result || []);
      }
    } catch (error) {
      console.error('Error fetching options:', error);
      const { message } = error;
      Modal.error({
        title: message || 'ไม่สามารถดึงข้อมูล Option Salary',
      });
    }
  };

  const onFetchOptionStudentGrade = async () => {
    try {
      const option = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
      ];

      setOptionStudentGrade(option);
    } catch (error) {
      console.error('Error fetching options:', error);
      const { message } = error;
      Modal.error({
        title: message || 'ไม่สามารถดึงข้อมูล Option Student Grade',
      });
    }
  };

  const onFetchOptionDoc = async (type, occupation, installments, job) => {
    try {
      const date = new Date()
      const pastDate = getMonthMinusMonths(date, 3);
      const toDate = getMonthMinusMonths(date, 1);
      const prev3Month = monthShortOnly(pastDate.getMonth())
      const month = monthShortOnly(toDate.getMonth())
      const label3Month = `Statement ${prev3Month} - ${month}`;

      let option = [
        { value: '3MONTH', label: label3Month },
        { value: 'CURRENT', label: 'สลิปเงินเดือนล่าสุด' },
        { value: 'NO', label: 'ไม่มี' },
      ];

      if (
        (type === 'INSTALLMENT' && occupation === 'นักเรียน/นักศึกษา' && installments > 15000) ||
        job === 'พนักงานราชการ' ||
        job === 'พนักงานเงินเดือน'
      ) {
        option.splice(2);
      }

      setOptionsDoc(option);
    } catch (error) {
      console.error('Error fetching options:', error);
      const { message } = error;
      Modal.error({
        title: message || 'ไม่สามารถดึงข้อมูล Option Doc',
      });
    }
  };

  const onFetchProductCodeAndPeriods = async (type, model, storage, colorPrd, productHand) => {
    try {
      let prdCode = ''
      const pdHand = productHand || '';
      const payload = {
        type: type,
        model: model,
        storage: storage,
        color: colorPrd,
        productHand: pdHand
      }
      const res = await getRegisterProductCodeAndPeriods(payload)
      if (res?.success) {
        if (current === 0) {
          prdCode = res?.product_code
          setProductTurnCode(res?.product_code)
        } else {
          prdCode = res?.product_code
          setProductCode(res?.product_code)
        }
        setOptionsPeriod(res?.period)
      } else {
        setProductTurnCode(null)
        setProductCode(null)
        Modal.error({
          title: 'ไม่สามารถดึงข้อมูลได้'
        })
      }
      return prdCode;
    } catch (error) {
      console.error('Error fetching options onFetchProductCodeAndPeriods:', error);
      Modal.error({
        title: 'ไม่สามารถดึงข้อมูลได้',
        content: error?.message || ''
      })
    }
  };

  const onFetchOptionsProductCondition = async (productTurnCode) => {
    try {
      if (productTurnCode) {
        const obj = { productCode: productTurnCode };
        const res = await getProductCondition(obj);
        const { success, result } = res;
        if (success) {
          const option = result.map((el) => {
            return {
              value: el.purchase_condition,
              label: el.purchase_condition,
              data: el
            };
          });

          setOptionsProductCondition(option);
        }
      }
    } catch (error) {
      Modal.error({
        title: 'ไม่สามารถดึง condition ได้',
        content: error?.message || ''
      })
    }

  };

  const onFetchConsiderCalculatePayment = async (data) => {
    try {
      if (productTurnCode) {
        // const { isTurn, productCode, condition, saleGrade, period } = data
        console.log('data :>> ', data);
        const res = await getConsiderCalculatePayment(data);
        const { success, result } = res;
        if (success) {
          console.log("onFetchtConsiderCalculateInstallment => ", result)

          const couponList = result.coupon.map(item => item.coupon_label);

          setDefaultCoupon([result?.productSale?.discountPeriod?.code, result?.productSale?.discountTurn1?.code, ...couponList])

          setCouponData({ discountPeriod: result?.productSale?.discountPeriod, discountTurn1: result?.productSale?.discountTurn1, coupon: result?.coupon })

          // setDisabledDeleteCoupon([result?.productSale?.discountPeriod?.code, result?.productSale?.discountTurn1?.code])

          if (typeInstallment === 'TURN1') {
            setSaleGrade(data?.isTurn ? result?.productTurn?.sale_grade : saleGrade)
          } else if (typeInstallment === 'TURN2') {
            setSaleGrade(getValues('productHand_newPrd') === 'NEW' ? 'L' : 'A')
          }

          setValue('newPeriod', result?.summary?.period)
          setValue('newMaximum', result?.summary?.price)
          setValue('newDownPayment', result?.summary?.down)
          setValue('newInstallments', result?.summary?.installments)

          if (typeInstallment === 'TURN2' || typeInstallment === 'SECONDHAND') {
            setValue('change_amount', result?.changeAmount)
          } else if (typeInstallment === 'TURN1' && data?.isTurn) {
            setValue('change_amount', result?.changeAmount)
          }

        }
      }
    } catch (error) {
      console.log(error)
      Modal.error({
        title: 'ไม่สามารถคำนวณเงินดาวน์ และยอดผ่อนได้',
        content: error?.message || ''
      })
    }
  };

  // const onFetchProductCalculateInstallment = async (data) => {
  //   try {
  //     // const { isTurn, productCode, condition, saleGrade, period } = data

  //     const res = await calculateInstallment(data);
  //     const { success, result } = res;
  //     if (success) {
  //       console.log("onFetchtConsiderCalculateInstallment => ", result)

  //       setValue('newMaximum', convertStrToFormat(result?.price, 'money'))
  //       setValue('newDownPayment', convertStrToFormat(result?.down, 'money'))
  //       setValue('newInstallments', convertStrToFormat(result?.installments, 'money'))
  //       setValue('change_amount', convertStrToFormat(result?.price - result?.down, 'money'))
  //     }
  //   } catch (error) {
  //     Modal.error({
  //       title: 'ไม่สามารถคำนวณเงินดาวน์ และยอดผ่อนได้',
  //       content: error?.message || ''
  //     })
  //   }
  // };

  /* --------------------- HANDLE ----------------------- */

  const handleDefaultConditionDevice = (data, conditionDevice) => {
    const initialSelection = conditionDevice.reduce((acc, { key, value }) => {
      const selectedCodes = data[key] || [];
      let selectedValues = [];

      value.forEach(({ sub_key }) => {
        sub_key.forEach((item) => {
          if (selectedCodes.includes(item.code)) {
            selectedValues.push({ code: item.code, name: item.name });
          }
        });
      });

      acc[key] = selectedValues;
      return acc;
    }, {});

    return initialSelection;
  };


  const handleJobDescription = (job, ascore) => {
    switch (job) {
      case 'พนักงานประจำ (พนักงานรายเดือน)':
      case 'พนักงานเงินเดือน':
        return 'พนักงานเงินเดือน';
      case 'ข้าราชการ (ทหาร/ตำรวจ)':
      case 'ข้าราชการ/พนักงานราชการ':
      case 'พนักงานราชการ':
        return 'พนักงานราชการ';
      case 'พนักงานชั่วคราว เช่น รายวัน รับเป็นจ็อบ':
      case 'พนักงานชั่วคราว/รับจ็อบ/Freelance':
      case 'พนักงานชั่วคราว/พนักงานอิสระ':
        return 'พนักงานชั่วคราว/พนักงานอิสระ';
      default:
        return job;
    }
  };

  const handleIncomeDoc = (job, income, occupation) => {
    switch (job) {
      case 'พนักงานชั่วคราว เช่น รายวัน รับเป็นจ็อบ':
      case 'พนักงานชั่วคราว/รับจ็อบ/Freelance':
      case 'พนักงานชั่วคราว/พนักงานอิสระ':
      case 'เจ้าของกิจการ':
        return '3MONTH';
      default:
        return income;
    }
  };

  const handleProductHand = (productHand, type) => {
    if (type === 'INSTALLMENT') {
      switch (productHand) {
        case 'NEW':
          return 'installmentNew';
        case 'SECOND':
          return 'installmentSecond';
        default:
          return productHand;
      }
    } else {
      return 'refinance';
    }
  };

  const handleFilterProductOption = (value, option) => {
    const filtered = option.filter((el) => el.value === value);
    return filtered;
  };

  const getBusinessValue = (inputValue) => {
    const options = optionsBusinessFullTime.flatMap((optionGroup) => optionGroup.options);
    const foundOption = options.find((option) => option.value === inputValue);
    return foundOption ? foundOption.value : 'OTHER';
  };

  const getPropertyNames = (value, obj) => {
    return Object.keys(obj).filter((key) => obj[key].some((item) => item.code === value));
  };

  const onChangeCombo = async (value, ref) => {
    const res = getPropertyNames(value, comboProductForm);
    const item = await getDownAmount(value, comboProductForm, ref);
    setProductCombo(res);
    const downPayment = getValues('downPayment');
    // setValue('downPayment', Number(downPayment) + Number(item.downAmount) - item.itemRemoveDown);
    setValue('downPayment', Number(downPayment) - item.itemRemoveDown);
    // setComboPrice(Number(item.downAmount));
  };

  const getDownAmount = async (value, obj, ref) => {
    if (!obj || typeof obj !== 'object') {
      throw new TypeError('Expected an object as the second argument');
    }
    let downAmount = 0;
    let itemRemoveDown = 0;
    let val;
    await Object.keys(obj).some(async (key) => {
      const item = obj[key].find((item) => item.code === value);
      if (item) {
        if (productComboData.length > 0) {
          productComboData
            .filter((val) => val.value !== value && val.key === key)
            .map((items) => {
              if (value === items.code) {
                downAmount = 0;
                return true;
              } else {
                itemRemoveDown = obj[key].find((item) => item.code !== value)?.down_amount || 0;
                setProductComboData([...productComboData.filter((val) => val.value !== value && val.key !== key), { key: key, code: value }]);
                downAmount = item.down_amount;
                return true;
              }
            });
        } else {
          setProductComboData([...productComboData.filter((val) => val.value !== value && val.key !== key), { key: key, code: value }]);
          downAmount = item.down_amount;
          return true;
        }
      }
      return false;
    });
    setValue(ref, value);
    val = {
      downAmount: downAmount,
      itemRemoveDown: itemRemoveDown,
    };
    calculate();
    return val;
  };
  const handleVisibleBusinessDocField = (proveOwn, saleCh) => {
    if (proveOwn && saleCh) {
      setVisibleBusinessDocField(false);
    } else {
      setVisibleBusinessDocField(true);
    }
  };

  const onChange = async (value, ref) => {

    switch (ref) {
      case 'walkin':
        setValue(ref, value);
        setUpdatedWalkinUser(userAdmin?.user?.displayName)
        break;
      case 'productHand':
      case 'productHand_newPrd':
        setValue(ref, value);
        if (ref === 'productHand') {
          setValue('model', '');
          setValue('storage', '');
          setValue('color', '');
          setValue('combo', '');
          setValue('productGroup', '');
        }
        if (ref === 'productHand_newPrd') {
          setValue('model_newPrd', '');
          setValue('storage_newPrd', '');
          setValue('color_newPrd', '');
          setValue('productGroup', '');
        }

        // setValue('downPayment', '');
        // setValue('installments', '');
        // setValue('maximum', '');

        setValue('newPeriod', null);
        setValue('newInstallment', null);
        setValue('newDownPayment', null);
        setValue('newMaximum', null); //refinance
        setNewMaximum(null);
        setNewDownPayment(null);
        setProductGroup('');
        setProductComboData([]);
        Object.keys(comboProductForm).forEach((item) => {
          const combo = getValues(`combo_${item}`);
          if (combo !== null) {
            setValue(`combo_${item}`, null);
          }
        });
        break;
      case 'productGroup':
      case 'productGroup_newPrd':
        setValue(ref, value);
        if (ref === 'productGroup') {
          setValue('model', '');
          setValue('storage', '');
          setValue('color', '');
          setValue('combo', '');
          if (typeInstallment === 'TURN1') {
            setValue('productGroup_newPrd', value)
          }
        }
        if (ref === 'productGroup_newPrd') {
          setValue('model_newPrd', '');
          setValue('storage_newPrd', '');
          setValue('color_newPrd', '');
        }
        if (value === 'Tablet') {
          setValue('additionalProduct', []);
          setOptionAdditionalCheckbox([]);
          setProductCombo([]);
        }

        // setValue('downPayment', '');
        // setValue('installments', '');
        // setValue('maximum', '');

        setValue('newPeriod', null);
        setValue('newInstallment', null);
        setValue('newDownPayment', null);
        setValue('newMaximum', null); //refinance
        setNewMaximum(null);
        setNewDownPayment(null);
        setProductGroup(value);
        const prdHand = current === 0 ? getValues('productHand') : getValues('productHand_newPrd');
        onFetchOptionsProducts(typeInstallment, value, prdHand, false, false);
        setProductComboData([]);
        Object.keys(comboProductForm).forEach((item) => {
          const combo = getValues(`combo_${item}`);
          if (combo !== null) {
            setValue(`combo_${item}`, null);
          }
        });
        break;
      case 'additionalProduct':
        setValue(ref, value);
        setProductCombo(value);
        Object.keys(comboProductForm).forEach((item) => {
          const down = comboProductForm[item].find((data) => data.code === getValues(`combo_${item}`)) || 0;
          const downPayment = getValues('downPayment');
          const combo = getValues(`combo_${item}`);
          if (combo !== null) {
            setValue('downPayment', Number(downPayment) - Number(down.down_amount));
            setValue(`combo_${item}`, null);
            setProductComboData(productComboData.filter((val) => val.key !== item));
          }
        });
        if (typeInstallment === 'INSTALLMENT') {
          calculate();
        }
        break;
      case 'prefix':
        if (value === 'อื่นๆ') {
          setCheckPrefix(false);
        } else {
          setCheckPrefix(true);
        }
        break;
      case 'occupation':
        setValue(ref, value);
        setOccupation(value);
        if (value === 'บุคคลทั่วไป') {
          onFetchOptionDoc(typeInstallment, value, downPayments, jobDescriptionState);
          setJobDescription(handleJobDescription(data.record[0].jobDescription));
          // onFetchCalculateAScore();
        } else if (value === 'นักเรียน/นักศึกษา') {
          onFetchOptionDoc(typeInstallment, value, downPayments);
          setJobDescription(handleJobDescription(getValues('jobDescription')) || '');
          // setDataAscore({});
          clearData();
          // setValue('jobDescription', '')
          // setJobDescription('')
          setfileListGovernmentCardImage([]);
          setRegisteredBusinessDocImage([]);
          setUnregisteredBusinessDocImage([]);
          setProductShopSaleImage([]);
          setRiderAppicationImage([]);
          setProveWork([]);
          setValue('incomeDoc', data.record[0]?.incomeDoc);
          setIncomeDoc(data.record[0]?.incomeDoc);
        }
        break;
      case 'incomeDoc':
        setValue(ref, value);
        const income = handleIncomeDoc(jobDescriptionState, value, occupation);
        setIncomeDoc(income);
        clearErrors('filePassword');
        // onFetchCalculateAScore();
        break;
      case 'proveOwnBusinessImageFlag':
        setValue(ref, value);
        setProveOwnBusinessImageFlag(value);
        handleVisibleBusinessDocField(getValues('proveOwnBusinessImageFlag'), getValues('salesChannels'));
        break;
      case 'salesChannels':
        setValue(ref, value);
        setSaleChannel(value);
        handleVisibleBusinessDocField(getValues('proveOwnBusinessImageFlag'), getValues('salesChannels'));
        break;
      case 'urlOnlineSale':
        setValue(ref, value);
        break;
      case 'zipcode':
        if (value.length === 5) {
          const filterAddress = optionsAddress
            .filter((val) => val.zipcode === value)
            .map((prop) => {
              return prop;
            });
          setDefaultAddress(filterAddress);
          const arrProvince = filterDataAddress(filterAddress, value, 'province');
          setOptionProvince(duplicateData(arrProvince));
          // onFetchCalculateAScore();
        }
        setOptionAmphoe([]);
        setOptionDistrict([]);
        setValue('province', '');
        setValue('district', '');
        setValue('subdistrict', '');
        break;
      case 'province':
        const arrAmphoe = filterDataAddress(defaultAddress, value, 'amphoe');
        setOptionAmphoe(duplicateData(arrAmphoe));
        break;
      case 'district':
        if (value === undefined) {
          setOptionDistrict([]);
        } else {
          const arrDistrict = filterDataAddress(defaultAddress, value, 'district');
          setOptionDistrict(duplicateData(arrDistrict));
        }

        setValue('subdistrict', '');
        break;
      case 'jobDescription':
        setValue(ref, handleJobDescription(value));
        setJobDescription(handleJobDescription(value));
        if (occupation === 'บุคคลทั่วไป') {
          const incomeJob = handleIncomeDoc(value, incomeDoc, occupation);
          setIncomeDoc(incomeJob);
        }
        onFetchOptionDoc(typeInstallment, occupation, downPayments, value);
        clearData();
        // onFetchCalculateAScore();
        break;
      case 'model':
      case 'model_newPrd': {
        let filterModel;
        setValue(ref, value);
        if (typeInstallment === 'TURN1') {
          setOptionRefinancePeriod([]);
        }
        const currentPrdHand = current === 0 ? getValues('productHand') : getValues('productHand_newPrd');
        if (ref === 'model') {
          setValue('storage', '');
          setValue('color', '');
          filterModel = optionsProducts.filter((el) => el.value === value)?.[0].label;
          const urlImage = optionsProducts.filter((el) => el.value === value)?.[0].image;
          setProductImage(urlImage);
          if (typeInstallment === 'TURN1') {
            setValue('model_newPrd', value)
            setValue('storage_newPrd', '');
            setValue('color_newPrd', '');
            // let filterModelNew = optionsProductsNewPrd.filter((el) => el.value === value)?.[0]?.label;
            const urlImage = optionsProductsNewPrd.filter((el) => el.value === value)?.[0]?.image;
            setProductNewImage(urlImage)
            // onFetchOptionsStorage(typeInstallment, filterModelNew, currentPrdHand, false, false);
            onFetchOptionsStorage(typeInstallment, filterModel, currentPrdHand, false, false);
          }
        }
        if (ref === 'model_newPrd') {
          setValue('storage_newPrd', '');
          setValue('color_newPrd', '');
          filterModel = optionsProductsNewPrd.filter((el) => el.value === value)?.[0]?.label;
          const urlImage = optionsProductsNewPrd.filter((el) => el.value === value)?.[0]?.image;
          setProductImage(urlImage);
          onFetchOptionsStorage(typeInstallment, filterModel, currentPrdHand, false, false);
        }
        // setValue('period', '');
        // setValue('downPayment', '');
        // setValue('installments', '');
        // setValue('maximum', '');
        setValue('newPeriod', null);
        setValue('newInstallments', null);
        setValue('newDownPayment', null);
        setValue('newMaximum', null); //refinance

        if (typeInstallment === 'TURN2') {
          setValue('change_amount', null);
        }

        if (productGroup === 'Tablet') {
          setValue('additionalProduct', []);
          setOptionAdditionalCheckbox([]);
        }

        setNewMaximum(null);
        setNewDownPayment(null);
        setProductComboData([]);
        Object.keys(comboProductForm).forEach((item) => {
          const combo = getValues(`combo_${item}`);
          if (combo !== null) {
            setValue(`combo_${item}`, null);
          }
        });
        break;
      }
      case 'storage':
      case 'storage_newPrd':
        setValue(ref, value);

        const currentPrdHand2 = current === 0 ? getValues('productHand') : getValues('productHand_newPrd');
        const currentPrdModel2 = current === 0 ? getValues('model') : getValues('model_newPrd');
        const currentPrdStorage2 = current === 0 ? getValues('storage') : getValues('storage_newPrd');
        let filterModel2;
        let filterStorage2;
        if (ref === 'storage') {
          setValue('color', '');
          filterModel2 = optionsProducts.filter((el) => el.value === currentPrdModel2)?.[0].label;
          filterStorage2 = optionsStorage.filter((el) => el.value === currentPrdStorage2)?.[0].label;
          if (typeInstallment === 'TURN1') {
            setValue('storage_newPrd', value)
            setValue('color_newPrd', '');
            // let filterModelNew2 = optionsProductsNewPrd.filter((el) => el.value === currentPrdModel2)?.[0].label;
            // let filterStorageNew2 = optionsStorageNewPrd.filter((el) => el.value === currentPrdStorage2)?.[0].label;
            onFetchOptionsColor(typeInstallment, filterModel2, filterStorage2, currentPrdHand2, false, false);
          }
        }
        if (ref === 'storage_newPrd') {
          setValue('color_newPrd', '');
          filterModel2 = optionsProductsNewPrd.filter((el) => el.value === currentPrdModel2)?.[0].label;
          filterStorage2 = optionsStorageNewPrd.filter((el) => el.value === currentPrdStorage2)?.[0].label;
          onFetchOptionsColor(typeInstallment, filterModel2, filterStorage2, currentPrdHand2, false, false);
        }
        // setValue('period', '');
        // setValue('downPayment', '');
        // setValue('installments', '');
        // setValue('maximum', '');
        if (getValues('period') && typeInstallment !== 'SECONDHAND') {
          // calculate();
        }


        if (productGroup === 'Tablet') {
          Object.values(productCombo).map((item) => {
            setValue(`combo_${item}`, null);
          });
          setProductCombo([]);
          setOptionAdditionalCheckbox([]);
          onFetchOptionsProductCombo(filterModel2, filterStorage2, currentPrdHand2, getValues('type'), optionsProducts);
          setValue('additionalProduct', []);
        }
        setValue('newPeriod', null);
        setValue('newInstallments', null);
        setValue('newDownPayment', null);
        setValue('newMaximum', null);
        if (typeInstallment === 'TURN2') {
          setValue('change_amount', null);
        }
        setNewMaximum(null);
        setNewDownPayment(null);
        // onFetchCalculateAScore();
        setProductComboData([]);
        Object.keys(comboProductForm).forEach((item) => {
          const combo = getValues(`combo_${item}`);
          if (combo !== null) {
            setValue(`combo_${item}`, null);
          }
        });
        break;
      case 'color':
      case 'color_newPrd': {
        const isNewProduct = current !== 0;
        // const currentPrdHand = getValues(isNewProduct ? 'productHand_newPrd' : 'productHand');
        const currentPrdModel = getValues(isNewProduct ? 'model_newPrd' : 'model');
        const currentPrdStorage = getValues(isNewProduct ? 'storage_newPrd' : 'storage');
        const currentPrdColor = getValues(isNewProduct ? 'color_newPrd' : 'color');

        const optionsModel = isNewProduct ? optionsProductsNewPrd : optionsProducts;
        const optionsStorageSet = isNewProduct ? optionsStorageNewPrd : optionsStorage;
        const optionsColorSet = isNewProduct ? optionsColorNewPrd : optionsColor;

        const filterModel = optionsModel.find((el) => el.value === currentPrdModel)?.label;
        const filterStorage = optionsStorageSet.find((el) => el.value === currentPrdStorage)?.label;
        const filterColor = optionsColorSet.find((el) => el.value === currentPrdColor)?.label;
        const imageUrl = optionsColorSet.find((el) => el.value === value)?.image;

        setValue('newPeriod', null);
        setValue('newInstallments', null);
        setValue('newDownPayment', null);
        setValue('newMaximum', null); //refinance

        if (typeInstallment === 'TURN2') {
          setValue('change_amount', null);
        }

        setProductImage(imageUrl);
        setProductComboData([]);

        if (ref === 'color' && typeInstallment === 'TURN1') {
          setValue('color_newPrd', value);
          setProductNewImage(imageUrl);
        }
        let prdCode = ref === 'color' ? productTurnCode : productCode;
        if ((typeInstallment === 'INSTALLMENT' && ref === 'color') || (typeInstallment === 'TURN1' && ref === 'color') || (typeInstallment === 'TURN2' && ref === 'color_newPrd') || (typeInstallment === 'SECONDHAND' && ref === 'color')) {
          const prdHand = typeInstallment === 'INSTALLMENT' ? getValues('productHand') : getValues('productHand_newPrd');

          prdCode = await onFetchProductCodeAndPeriods(typeInstallment, filterModel, filterStorage, filterColor, prdHand);
        }

        /* ดึง Condition ใหม่ */
        if (typeInstallment === 'TURN1' || typeInstallment === 'TURN2' || typeInstallment === 'SECONDHAND') {
          onFetchOptionsProductCondition(prdCode); //ไปดึง condition ใหม่หลังเปลี่ยนสินค้า step1

          const currentCondition = getValues('condition_approve');
          const condition = _.find(optionsProductCondition, ['value', currentCondition]);

          setValue('newMaximum', condition?.data?.price);
        }
        break;
      }
      case 'period':
        setValue(ref, value);
        onFetchConsiderCalculatePayment({
          type: typeInstallment,
          isTurn: false,
          productCode: productTurnCode,
          saleGrade: getValues('productHand') === 'NEW' ? 'L' : 'A',
          period: value,
          down: Number(getValues('newDownPayment')),
          coupon: couponData?.coupon
        })
        break;
      case 'newPeriod':
        setValue(ref, value);
        //   // console.log("onChange condition_approve =>", value)
        //   onFetchConsiderCalculatePayment({
        //     isTurn: true,
        //     productCode: productCode,
        //     condition: getValues('condition_approve'),
        //     period: value,
        //     down: getValues('newDownPayment')
        //   })
        // }
        onFetchConsiderCalculatePayment({
          type: typeInstallment,
          isTurn: typeInstallment === 'SECONDHAND' ? true : false,
          productCode: (typeInstallment === 'INSTALLMENT' || typeInstallment === 'SECONDHAND') ? productTurnCode : productCode,
          saleGrade: saleGrade,
          // saleGrade: getValues('productHand_newPrd') === 'NEW' ? 'L' : 'A',
          period: value,
          down: Number(getValues('newDownPayment')),
          coupon: couponData?.coupon
        })
        // }

        // if (typeInstallment !== 'SECONDHAND') {
        // calculate();
        // }
        // onFetchCalculateAScore();
        break;
      case 'interestedBranch':
        setValue(ref, value);
        break;
      case 'idCard':
        if (value) {
          setValue(ref, value);
          if (validateIdCardFn(value) !== '') {
            let error = validateIdCardFn(value);
            return setError('idCard', { type: 'custom', message: error });
          }
        } else {
          setValue(ref, '');
          return setError('idCard', { type: 'custom', message: 'กรุณาระบุเลขบัตรประชาชน' });
        }
        break;
      case 'dateOfBirth':
        if (value) {
          if (value !== '' || !_.isEmpty(value)) {
            setValue(ref, value);
            if (validateDate(value) !== '') {
              let error = validateDate(value);
              return setError('dateOfBirth', { type: 'custom', message: error });
            }
          }
        } else {
          setValue(ref, null);
          setValue('age', '');
          // return setError('dateOfBirth', { type: 'custom', message: 'กรุณาระบุ วัน/เดือน/ปีเกิด' });
        }
        // onFetchCalculateAScore();
        break;
      case 'businessFullTime':
        setValue(ref, value);
        setBusinessFullTime(value);
        setBusiness(value);
        // onFetchCalculateAScore();
        setValue('business', value);
        break;
      case 'businessGovernmentOfficer':
      case 'businessFullTime2':
      case 'businessPartTime':
      case 'businessOwn':
        setValue(ref, value);
        setValue('business', value);
        setBusiness(value);
        // onFetchCalculateAScore();
        break;
      case 'newInstallment':
      case 'phoneSystem':
      case 'experience':
      case 'salary':
      case 'salary_avg':
        setValue(ref, value);
        // onFetchCalculateAScore();
        break;
      case 'newDownPayment':
        setValue(ref, value);
        setNewDownPayment(value);

        onFetchConsiderCalculatePayment({
          type: typeInstallment,
          isTurn: false,
          productCode: (typeInstallment === 'INSTALLMENT') ? productTurnCode : productCode,
          saleGrade: getValues('productHand') === 'NEW' ? 'L' : 'A',
          period: getValues('newPeriod'),
          down: Number(value),
          coupon: couponData?.coupon
        })
        // }

        break;
      case 'is_approve_special':
        setValue(ref, value);
        setIsApproveSpecial(value);

        if (!value) {
          setValue('remark_approve_special', null);
        }
        break;
      case 'statusApp':
        setStatusAppDefault(value);

        if (value === 'REJECTED') {
          setValue('remark_reject', null);
        }
        break;
      case 'condition_approve':
        if (typeInstallment !== 'INSTALLMENT') {
          const condition = _.find(optionsProductCondition, ['value', value]);
          setValue('newMaximum', condition?.data?.price);

          console.log("onChange condition_approve =>", value, productTurnCode)

          await onFetchConsiderCalculatePayment({
            type: typeInstallment,
            isTurn: true,
            productCode: productTurnCode,
            condition: value,
            period: getValues('period'),
            down: Number(getValues('newDownPayment')),
            coupon: couponData?.coupon
          })
        }
        break;
      // case 'newMaximum':
      //   setValue(ref, value);
      //   setValue('newPeriod', null);
      //   setValue('newInstallment', null);
      //   if (typeInstallment === 'INSTALLMENT') {
      //     onFetchOptionsNewPeriods(value);
      //     setNewMaximum(value);
      //   }

      //   break;
      // case 'newPeriod':
      //   if (value) {
      //     if (typeInstallment === 'TURN1') {
      //       const newInstallment = _.find(optionRefinanceNewPeriod, ['value', value]);
      //       setValue('newInstallment', convertStrToFormat(newInstallment.installments, 'money'));
      //     }
      //     // onFetchCalculateAScore();
      //   }
      //   break;
      default:
        break;
    }

    clearErrors(ref);
    setValue(ref, value);
  };

  const onChangeUploadImage = (value, ref) => {
    const objFile = _.isEmpty(value)
      ? []
      : _.map(value, (val) => {
        return {
          ...val,
          fileName: val?.response?.fileName ?? val?.fileName,
          fileNameOrigin: val?.response?.fileNameOrigin ?? val?.fileNameOrigin,
          mimeType: val?.mimeType ?? '',
          url: val?.response?.url ?? val.url,
        };
      });

    switch (ref) {
      case 'studentCardImages':
        setfileListStudentCardImages(objFile);
        break;
      case 'studentTranscriptImages':
        setfileListStudentTranscriptImages(objFile);
        break;
      case 'statementImage':
        setfileListStatement(objFile);
        break;
      case 'governmentOfficial':
        setfileListGovernmentCardImage(objFile);
        break;
      case 'proveWork':
        setProveWork(objFile);
        break;
      case 'riderAppication':
        setRiderAppicationImage(objFile);
        break;
      case 'imeiImages':
        setImeiImageList(objFile);
        break;
      case 'iphoneImages':
        setIphoneImageList(objFile);
        break;
      case 'registeredBusinessDocImage':
        setRegisteredBusinessDocImage(objFile);
        break;
      case 'unRegisteredbusinessDocImage':
        if (typeInstallment === 'INSTALLMENT') {
          setUnregisteredBusinessDocImage(objFile);
          setProveOwnBusinessImageList(objFile);
        } else {
          setUnregisteredBusinessDocImage(objFile);
        }
        break;
      case 'productShopSaleImage':
        setProductShopSaleImage(objFile);
        break;
      default:
        break;
    }

    clearErrors(ref);
    setValue(ref, value);
  };

  const calculate = async () => {
    let productHand = current === 0 ? getValues('productHand') : getValues('productHand_newPrd');
    let model = current === 0 ? getValues('model') : getValues('model_newPrd');
    let storage = current === 0 ? getValues('storage') : getValues('storage_newPrd');
    let color = current === 0 ? getValues('color') : getValues('color_newPrd');
    let combo;

    if (productCombo.length !== 0) {
      let comboItems = [];

      // Loop through keys of the object
      for (const key in getValues()) {
        // Check if the key contains 'combo_'
        if (key.startsWith('combo_')) {
          // Extract the type (e.g., 'Pencil', 'Film') from the key
          const type = key.substring('combo_'.length);
          // Push an object with type and corresponding code to comboItems array
          comboItems.push({
            type: type,
            code: getValues(key),
          });
        }
      }
      comboItems = comboItems.filter((item) => getValues('additionalProduct').includes(item.type));

      const resultCombo = comboItems.reduce((acc, item) => {
        const { type, code } = item;
        const matchingItem = comboProductForm[type]?.find((opItem) => opItem.code === code);
        if (matchingItem) {
          acc.push({
            name: matchingItem.name,
            code: matchingItem.code,
            down_amount: matchingItem.down_amount,
            type: type,
          });
        }
        return acc;
      }, []);
      combo = resultCombo;
    }
    const obj = {
      // type:
      //   typeInstallment === 'INSTALLMENT' && productHand === 'NEW'
      //     ? 'INSTALLMENT'
      //     : typeInstallment === 'INSTALLMENT' && productHand === 'SECOND'
      //       ? 'INSTALLMENTS_SPECIAL'
      //       : 'REFINANCE',
      type: getValues('type') || typeInstallment,
      product: {
        model: model,
        storage: storage,
        color: color,
      },
      // productCode: "G01101080142",
      // productHand: productHand,
      // condition: condition,
      // period: typeInstallment === 'INSTALLMENT' ? +getValues('period') : 0,
      period: getValues('period'),
      combo: combo || [],
    };

    if (model && storage) {
      const res = await POST(INSTALLMANT_CALCULATE, obj);
      // setValue('downPayment', convertStrToFormat(res.downPayment, 'money'));
      // setValue("installments", convertStrToFormat(res.installments, "money"))
      if (typeInstallment === 'TURN1') {
        setValue('downPayment', res.downPayment);

        const optionPeriod = res.installmentRange.map((el) => {
          return {
            label: el.period,
            value: el.period,
          };
        });
        setOptionRefinancePeriod(optionPeriod);
        setValue('maximum', res.maximum);
        // setValue("period", res.period)
        if (getValues('period')) {
          let installmentFilter = res.installmentRange.filter((el) => el.period === getValues('period'));
          setValue('installments', installmentFilter[0].installments);
        }
      } else {
        if (productGroup === 'Tablet' && productCombo.length !== 0) {
          // add combo price to down_amount of iPad product
          setValue('downPayment', res.downPayment);
          // setValue('downPayment', res.downPayment + comboPrice);
        } else {
          setValue('downPayment', res.downPayment);
        }
        setValue('installments', res.installments);
      }
    }
  };

  // const calculateAScore = async () => {
  //   const input = getValues();
  //   const down = input.downPayment;

  //   let productHand = current === 0 ? getValues('productHand') : getValues('productHand_newPrd');

  //   let combo;

  //   if (productCombo.length !== 0) {
  //     let comboItems = [];

  //     // Loop through keys of the object
  //     for (const key in getValues()) {
  //       // Check if the key contains 'combo_'
  //       if (key.startsWith('combo_')) {
  //         // Extract the type (e.g., 'Pencil', 'Film') from the key
  //         const type = key.substring('combo_'.length);
  //         // Push an object with type and corresponding code to comboItems array
  //         comboItems.push({
  //           type: type,
  //           code: getValues(key),
  //         });
  //       }
  //     }
  //     comboItems = comboItems.filter((item) => getValues('additionalProduct').includes(item.type));

  //     const resultCombo = comboItems.reduce((acc, item) => {
  //       const { type, code } = item;
  //       const matchingItem = comboProductForm[type]?.find((opItem) => opItem.code === code);
  //       if (matchingItem) {
  //         acc.push({
  //           name: matchingItem.name,
  //           code: matchingItem.code,
  //           down_amount: matchingItem.down_amount,
  //           type: type,
  //         });
  //       }
  //       return acc;
  //     }, []);
  //     combo = resultCombo;
  //   }

  //   const obj = {
  //     registerId: registerId,
  //     type: typeInstallment,
  //     period: input.period,
  //     product: {
  //       model: input.model,
  //       storage: input.storage,
  //       color: input.color,
  //     },
  //     profile: {
  //       type: input.type,
  //       zipcode: input.zipcode,
  //       phoneSystem: input.phoneSystem,
  //       dateOfBirth: (input.dateOfBirth && moment.utc(input.dateOfBirth, 'DD/MM/YYYY').subtract(543, 'years').toISOString()) || null,
  //     },
  //     job: {
  //       type: input.occupation,
  //       occupation: handleJobDescription(input.jobDescription),
  //       business: input.business,
  //       experience: input.experience,
  //       salary: parseNumber(input.salary),
  //       newSalary: parseNumber(input.salary_avg),
  //     },
  //     statement: {
  //       type: input.incomeDoc,
  //     },
  //     summary: {
  //       period: input.period,
  //       downPayment: typeof down === 'string' ? parseInt(down.replace(',', '')) : parseNumber(input.downPayment),
  //       installments: parseNumber(input.installments),
  //       maximum: parseNumber(input.maximum),
  //       newPeriod: input.newPeriod,
  //       newDownPayment: parseNumber(input.newDownPayment),
  //       newInstallment: parseNumber(input.newInstallment),
  //       newMaximum: parseNumber(input.newMaximum),
  //     },
  //     combo: combo || [],
  //     productHand: productHand || ''
  //   };

  //   if (input.model && input.storage) {
  //     // const res = await POST(CALCULATE_A_SCORE(registerId), obj);
  //     // const resAscore = res.result;
  //     // setDataAscore({
  //     //   aLocalScale: resAscore.aScore.aLocalScale,
  //     //   aScore: resAscore,
  //     // });

  //     // if (typeInstallment === 'TURN1') {
  //     //   setValue('newInstallment', convertStrToFormat(resAscore.summary.newInstallment, 'money'));
  //     // } else if (typeInstallment === 'INSTALLMENT') {
  //     //   setValue('newInstallment', convertStrToFormat(resAscore.summary.newInstallment, 'money'));
  //     // }

  //     // generateRemark(resAscore);

  //     generateRemark(obj);
  //   }
  // };

  /* ต้องปรับ */
  const generateRemark = async (obj) => {
    const { type, product, summary, job, combo } = obj;

    const salary = job?.newSalary || job?.salary;
    const maximum = summary?.newMaximum || summary?.maximum;
    const downPayment = summary?.newDownPayment || summary?.downPayment;
    const installments = summary?.newInstallment || summary?.installments;
    const period = summary?.newPeriod || summary?.period;

    let comboText = '';
    if (combo && combo.length !== 0) {
      if (combo.length > 1) {
        comboText += '+ Combo ';
        comboText += combo.map((item) => item.name).join(' + ');
      } else if (combo.length === 1) {
        comboText += `+ Combo ${combo[0]?.name}`;
      }
    }

    let textRemark = '';
    textRemark += `${salary}/${product.model} ${product.storage} ${product.color} `;
    if (comboText !== '') {
      textRemark += `${comboText} `;
    }
    textRemark += type === 'INSTALLMENT' ? `ดาวน์ ${downPayment} ` : `วงเงินสูงสุด ${maximum} `;
    textRemark += `ผ่อน ${installments}*${period}ด.`;

    setValue('remark', textRemark);
  };

  // /* Calculate A Score */
  // useEffect(() => {
  //   if(inputCalculateAscore && occupation === 'บุคคลทั่วไป'){
  //     const timeoutId = setTimeout(() => {
  //       calculateAScore()
  //       setInputCalculateAscore(false)
  //     } , 1000);
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [inputCalculateAscore]);

  // const onFetchCalculateAScore = _.debounce(() => {
  //   if (occupation === 'บุคคลทั่วไป') {
  //     calculateAScore();
  //   }
  // }, 1000);

  const onCloseMain = () => {
    setDisableField(false);
    setFbBlacklist({});
    setfileListStudentCardImages([]);
    setfileListStudentTranscriptImages([]);
    setfileListStatement([]);
    setfileListGovernmentCardImage([]);
    setProveWork([]);
    setRiderAppicationImage([]);
    setImeiImageList([]);
    setIphoneImageList([]);
    setProveOwnBusinessImageFlag(null);
    setProveOwnBusinessImageList([]);
    setRegisteredBusinessDocImage([]);
    setUnregisteredBusinessDocImage([]);
    setProductShopSaleImage([]);
    setOptionAdditionalCheckbox([]);
    setRiderAppicationImage([]);
    setProductCombo([]);
    setComboProductForm([]);
    setWalkin(false);
    setSaleChannel('');
    clearErrors();
    onClose();
    setCurrent(0);
    setProductGroup('');
    setBusiness('');
    setBusinessFullTime('');
    setDownPayment('');
    setCheckPrefix(true);
    setProductComboData([]);
    setTypeInstallment('')

    setConditionDevice({});
    setSelectedIssues({});
    setActivePanels([]);
    setDefaultSelectedIssues({})

    setDefaultCoupon([])
    setCouponData({})
    setDisabledDeleteCoupon([])

    setUrlSignOnline(null)
    setSignOnlineStatus('')

    setProductCode(null)
    setProductTurnCode(null)
    setSaleGrade(null)

    data.reApi(data.valueSearch);
  };

  const clearData = (ref) => {
    setProveOwnBusinessImageFlag('');
    setSaleChannel('');
    setBusiness('');
    setBusinessFullTime('');
    setValue('business', '');
    setValue('businessFullTime', null);
    setValue('businessFullTime2', null);
    setValue('businessGovernmentOfficer', null);
    setValue('businessPartTime', null);
    setValue('businessOwn', null);
    // setValue('companyNameFullTime', null)
    // setValue('companyNameGovernmentOfficer', null)
    // setValue('companyNamePartTime', null)
    // setValue('companyNameOwn', null)
    // setValue('companyLocationFullTime', null)
    // setValue('companyLocationGovernmentOfficer', null)
    // setValue('companyLocationPartTime', null)
    // setValue('companyLocationOwn', null)
    // setValue('companyPhoneNumber', null)
    // setValue('position', null)
    // setValue('experience', null)
    // setValue('salary', null)
    // setValue('incomeDoc', '')
    // setValue('proveOwnBusinessImageFlag', null)
    // setValue('salesChannels', null)
    // setValue('registeredBusinessDocImage', [])
    // setValue('unRegisteredbusinessDocImage', [])
    // setValue('urlOnlineSale', null)
    // setValue('productShopSaleImage', [])
    // setValue('statementImage', [])
    // setValue('filePassword', null)
    // setValue('customer_grade', null)
    // setValue('governmentOfficial', [])
    // setValue('proveWork', [])
    // setValue('riderAppication', [])
    // setIncomeDoc('')
  };

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
    clearErrors();
  };

  const handleProductCombo = async (sliceCombo, combo, set_value) => {
    setComboProductForm(sliceCombo);
    let opt = [];
    let product = [];

    Object.entries(sliceCombo).map((item) => {
      opt.push({ value: item[0], label: item[0] });
    });

    if (combo) {
      Object.entries(combo).map((item) => {
        product.push(item[0]);
        if (set_value) {
          setValue(`combo_${item[0]}`, item[1][0]?.code);
          setProductComboData([
            {
              key: item[0],
              code: item[1][0]?.code,
            },
          ]);
        }
      });
    }

    if (set_value) {
      setProductCombo(product);
      setValue('additionalProduct', product);
    }
    setOptionAdditionalCheckbox(opt);
  };

  const onPreview = async (file) => {
    if (file.response) {
      if (!file.response.file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
    }

    if (file.mimeType === 'application/pdf' || file.type === 'application/pdf') {
      window.open(file.url || file.preview || (file.response && file.response.file.url), '_blank', 'noopener,noreferrer');
    } else {
      setPreviewVisible(true);
      setPreviewImage((file.response && file.response.file.url) || file.url || file.preview);
      setPreviewTitle(file?.fileNameOrigin || file.name);
    }
  };

  const handleBlacklist = async (params) => {
    try {
      setLoading(true);
      const obj = {
        name: `${params.firstName} ${params.lastName}`,
        id_card: params.idCard,
        description: 'เพิ่ม blacklist จากหน้าพิจารณา',
        tel: params.phoneNumber,
        ref: (fbBlacklist && fbBlacklist.blacklist) || [],
      };
      let res = await POST(INSERT_BLACKLIST_CUSTOMER, { ...obj, type: 'MANUAL' });
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
            setValue('status', '');
            setValue('remark', '');
            setValue('salary_avg', '');
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSalesChannelValue = (prove, saleCh, url) => {
    if (jobDescriptionState === 'เจ้าของกิจการ') {
      if (typeInstallment === 'INSTALLMENT') {
        return { link: url };
      }
      if (saleCh === 'ONLINE') {
        return { link: url };
      }

      if (prove === 'UNREGISTERED_BUSINESS' && saleCh === 'OFFLINE') {
        const images = productShopSaleImage?.map((val) => ({
          fileName: val?.fileName || '',
          fileNameOrigin: val?.fileNameOrigin || '',
          mimeType: val?.mimeType || '',
          url: val?.url || '',
        }));
        return { images: images };
      }

      if (prove === 'REGISTERED_BUSINESS' && saleCh === 'OFFLINE') {
        const images = unRegisteredbusinessDocImage?.map((val) => ({
          fileName: val?.fileName || '',
          fileNameOrigin: val?.fileNameOrigin || '',
          mimeType: val?.mimeType || '',
          url: val?.url || '',
        }));
        return { images: images };
      }
    }
    if (jobDescriptionState !== 'เจ้าของกิจการ') {
      return { images: [] };
    }
  };

  const handleSave = async (params) => {
    const {
      model,
      storage,
      color,
      idCard,
      phoneNumber,
      prefix,
      otherPrefix,
      firstName,
      lastName,
      address,
      zipcode,
      subdistrict,
      district,
      province,
      facebook,
      lineId,
      countPerson,
      phoneSystem,
      serial_number,
      occupation,
      college,
      faculty,
      branch,
      degree,
      year,
      salary,
      avgIncome,
      jobDescription,
      business,
      businessFullTime,
      businessFullTime2,
      businessGovernmentOfficer,
      businessOwn,
      businessPartTime,
      companyName,
      companyNameFullTime,
      companyNameGovernmentOfficer,
      companyNamePartTime,
      companyNameOwn,
      companyLocation,
      companyLocationFullTime,
      companyLocationGovernmentOfficer,
      companyLocationPartTime,
      companyLocationOwn,
      position,
      experience,
      companyPhoneNumber,
      incomeDoc,
      filePassword,
      customer_grade,
      interestedBranch,
      othersDetails,
      relationship1,
      firstName1,
      lastName1,
      phoneNumber1,
      relationship2,
      firstName2,
      lastName2,
      phoneNumber2,
      relationship3,
      firstName3,
      lastName3,
      phoneNumber3,
      period,
      statusApp,
      grade,
      remark,
      remark_consider,
      remark_approve_special,
      remark_reject,
      salary_avg,
      dateOfBirth,
      newDownPayment,
      newInstallments,
      newMaximum,
      newPeriod,
      is_approve_special,
      proveOwnBusinessImageFlag,
      salesChannels,
      urlOnlineSale,
      productHand,
      chanelSalary,
      combo,
      additionalProduct,
      walkin,
      typeLock,
      model_newPrd,
      storage_newPrd,
      color_newPrd,
      productHand_newPrd,
      downPayment,
      installments,
      maximum
    } = params;
    let job = { type: occupation };
    let profile = {
      type: typeInstallment,
      idCard,
      phoneNumber,
      prefix: prefix === 'อื่นๆ' ? otherPrefix : prefix,
      firstName,
      lastName,
      address,
      zipcode,
      subdistrict,
      district,
      province,
      facebook,
      lineId,
      countPerson,
      phoneSystem,
      dateOfBirth: moment.utc(dateOfBirth, 'DD/MM/YYYY').subtract(543, 'years').toISOString() || null,
    };

    let summary = {
      newDownPayment: newDownPayment ? parseNumber(newDownPayment) : null,
      newInstallment: newInstallments ? parseNumber(newInstallments) : null,
      newMaximum: newMaximum ? parseNumber(newMaximum) : null,
      newPeriod: newPeriod || null,
      downPayment: downPayment || null,
      maximum: maximum || null,
      installments: installments || null,
      period: period || null,
    };

    if (typeInstallment === 'TURN1') {
      profile = {
        ...profile,
        serial_number,
        imeiImages: {
          type: typeInstallment,
          images:
            typeInstallment === 'INSTALLMENT'
              ? []
              : imeiImageList.map((val) => ({
                fileName: val?.fileName || '',
                fileNameOrigin: val?.fileNameOrigin || '',
                mimeType: val?.mimeType || '',
                url: val?.url || '',
              })),
        },
        iphoneStorage: {
          type: typeInstallment,
          images:
            typeInstallment === 'INSTALLMENT'
              ? []
              : iphoneImageList.map((val) => ({
                fileName: val?.fileName || '',
                fileNameOrigin: val?.fileNameOrigin || '',
                mimeType: val?.mimeType || '',
                url: val?.url || '',
              })),
        },
      };
    } else if (typeInstallment === 'INSTALLMENT') {
      profile = {
        ...profile,
      };
    }

    const businessMapping = {
      'พนักงานประจำ (พนักงานรายเดือน)':
        !_.isEmpty(businessFullTime) && businessFullTime !== 'OTHER'
          ? businessFullTime
          : !_.isEmpty(businessFullTime2) && businessFullTime === 'OTHER'
            ? businessFullTime2
            : business, // old field พนักงานประจำ #08-07-2026
      พนักงานเงินเดือน:
        !_.isEmpty(businessFullTime) && businessFullTime !== 'OTHER'
          ? businessFullTime
          : !_.isEmpty(businessFullTime2) && businessFullTime === 'OTHER'
            ? businessFullTime2
            : business, // new field พนักงานประจำ #08-07-2026
      'ข้าราชการ (ทหาร/ตำรวจ)': !_.isEmpty(businessGovernmentOfficer) ? businessGovernmentOfficer : business, // old field ข้าราชการ #16-06-2026
      พนักงานราชการ: !_.isEmpty(businessGovernmentOfficer) ? businessGovernmentOfficer : business, // new field ข้าราชการ #16-06-2026
      'พนักงานชั่วคราว เช่น รายวัน รับเป็นจ็อบ': !_.isEmpty(businessPartTime) ? businessPartTime : business, // old field พนักงานชั่วคราว #16-06-2026
      'พนักงานชั่วคราว/รับจ็อบ/Freelance': !_.isEmpty(businessPartTime) ? businessPartTime : business, // old field พนักงานชั่วคราว #16-06-2026
      'พนักงานชั่วคราว/พนักงานอิสระ': !_.isEmpty(businessPartTime) ? businessPartTime : business, // new field พนักงานชั่วคราว #16-06-2026
      เจ้าของกิจการ: !_.isEmpty(businessOwn) ? businessOwn : business,
    };

    const companyNameMapping = {
      'พนักงานประจำ (พนักงานรายเดือน)': !_.isEmpty(companyNameFullTime) ? companyNameFullTime : companyName, // old field พนักงานประจำ #08-07-2026
      พนักงานเงินเดือน: !_.isEmpty(companyNameFullTime) ? companyNameFullTime : companyName, // new field พนักงานประจำ #08-07-2026
      'ข้าราชการ (ทหาร/ตำรวจ)': !_.isEmpty(companyNameGovernmentOfficer) ? companyNameGovernmentOfficer : companyName, // old field ข้าราชการ #16-06-2026
      พนักงานราชการ: !_.isEmpty(companyNameGovernmentOfficer) ? companyNameGovernmentOfficer : companyName, // new field ข้าราชการ #16-06-2026
      'พนักงานชั่วคราว เช่น รายวัน รับเป็นจ็อบ': !_.isEmpty(companyNamePartTime) ? companyNamePartTime : companyName, // old field พนักงานชั่วคราว #16-06-2026
      'พนักงานชั่วคราว/รับจ็อบ/Freelance': !_.isEmpty(companyNamePartTime) ? companyNamePartTime : companyName, // old field พนักงานชั่วคราว #16-06-2026
      'พนักงานชั่วคราว/พนักงานอิสระ': !_.isEmpty(companyNamePartTime) ? companyNamePartTime : companyName, // new field พนักงานชั่วคราว #16-06-2026
      เจ้าของกิจการ: !_.isEmpty(companyNameOwn) ? companyNameOwn : companyName,
    };

    const companyLocationMapping = {
      'พนักงานประจำ (พนักงานรายเดือน)': !_.isEmpty(companyLocationFullTime) ? companyLocationFullTime : companyLocation, // old field พนักงานประจำ #08-07-2026
      พนักงานเงินเดือน: !_.isEmpty(companyLocationFullTime) ? companyLocationFullTime : companyLocation, // new field พนักงานประจำ #08-07-2026
      'ข้าราชการ (ทหาร/ตำรวจ)': !_.isEmpty(companyLocationGovernmentOfficer) ? companyLocationGovernmentOfficer : companyLocation, // old field ข้าราชการ #16-06-2026
      พนักงานราชการ: !_.isEmpty(companyLocationGovernmentOfficer) ? companyLocationGovernmentOfficer : companyLocation, // new field ข้าราชการ #16-06-2026
      'พนักงานชั่วคราว เช่น รายวัน รับเป็นจ็อบ': !_.isEmpty(companyLocationPartTime) ? companyLocationPartTime : companyLocation, // old field พนักงานชั่วคราว #16-06-2026
      'พนักงานชั่วคราว/รับจ็อบ/Freelance': !_.isEmpty(companyLocationPartTime) ? companyLocationPartTime : companyLocation, // old field พนักงานชั่วคราว #16-06-2026
      'พนักงานชั่วคราว/พนักงานอิสระ': !_.isEmpty(companyLocationPartTime) ? companyLocationPartTime : companyLocation, // new field พนักงานชั่วคราว #16-06-2026
      เจ้าของกิจการ: !_.isEmpty(companyLocationOwn) ? companyLocationOwn : companyLocation,
    };

    if (occupation === 'นักเรียน/นักศึกษา') {
      job = {
        ...job,
        college,
        faculty,
        branch,
        degree,
        year,
        grade,
        salary: avgIncome,
        chanelSalary,
        studentImages: fileListStudentCardImages
          ? fileListStudentCardImages.map((el) => {
            return {
              fileName: el?.fileName || '',
              fileNameOrigin: el?.fileNameOrigin || '',
              mimeType: el?.mimeType || '',
              url: el?.url || '',
            };
          })
          : [],
        transcriptOrTermFees: fileListStudentTranscriptImages
          ? fileListStudentTranscriptImages.map((el) => {
            return {
              fileName: el?.fileName || '',
              fileNameOrigin: el?.fileNameOrigin || '',
              mimeType: el?.mimeType || '',
              url: el?.url || '',
            };
          })
          : [],
      };
    } else if (occupation === 'บุคคลทั่วไป') {
      job = {
        ...job,
        occupation: jobDescription,
        business: businessMapping[jobDescription],
        companyName: companyNameMapping[jobDescription],
        companyLocation: companyLocationMapping[jobDescription],
        position,
        experience,
        companyPhoneNumber,
        salary,
      };
    }

    let comboPd = {};

    if (productCombo.length !== 0) {
      let comboItems = [];

      // Loop through keys of the object
      for (const key in params) {
        // Check if the key contains 'combo_'
        if (key.startsWith('combo_')) {
          // Extract the type (e.g., 'Pencil', 'Film') from the key
          const type = key.substring('combo_'.length);
          // Push an object with type and corresponding code to comboItems array
          comboItems.push({
            type: type,
            code: params[key],
          });
        }
      }
      comboItems = comboItems.filter((item) => additionalProduct.includes(item.type));

      const resultCombo = comboItems.reduce((acc, item) => {
        const { type, code } = item;
        const matchingItem = comboProductForm[type]?.find((opItem) => opItem.code === code);
        if (matchingItem) {
          acc.push({
            name: matchingItem.name,
            code: matchingItem.code,
            down_amount: matchingItem.down_amount,
            type: type,
          });
        }
        return acc;
      }, []);
      comboPd.combo = resultCombo;
    }

    let product = {}
    let productTurn = {}

    if (typeInstallment === 'INSTALLMENT') {
      const prdModel = handleFilterProductOption(model, optionsProducts).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      const prdStorage = handleFilterProductOption(storage, optionsStorage).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      const prdColor = handleFilterProductOption(color, optionsColor).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      product = {
        code: productTurnCode,
        model: prdModel?.[0],
        storage: prdStorage?.[0],
        color: prdColor?.[0],
      }
    }

    if (typeInstallment === 'SECONDHAND') {
      const prdModel = handleFilterProductOption(model, optionsProducts).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      const prdStorage = handleFilterProductOption(storage, optionsStorage).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      const prdColor = handleFilterProductOption(color, optionsColor).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      product = {
        code: productTurnCode,
        model: prdModel?.[0],
        storage: prdStorage?.[0],
        color: prdColor?.[0],
      }
    }

    if (typeInstallment === 'TURN1') {
      const prdModel = handleFilterProductOption(model, optionsProducts).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      const prdStorage = handleFilterProductOption(storage, optionsStorage).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      const prdColor = handleFilterProductOption(color, optionsColor).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      product = {
        code: productTurnCode,
        model: prdModel?.[0],
        storage: prdStorage?.[0],
        color: prdColor?.[0],
      }
    }

    if (typeInstallment === 'TURN2') {
      const prdTurnModel = handleFilterProductOption(model, optionsProducts).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      const prdTurnStorage = handleFilterProductOption(storage, optionsStorage).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      const prdTurnColor = handleFilterProductOption(color, optionsColor).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      productTurn = {
        code: productTurnCode,
        model: prdTurnModel?.[0],
        storage: prdTurnStorage?.[0],
        color: prdTurnColor?.[0],
      }
      const prdModel = handleFilterProductOption(model_newPrd, optionsProductsNewPrd).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      const prdStorage = handleFilterProductOption(storage_newPrd, optionsStorageNewPrd).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      const prdColor = handleFilterProductOption(color_newPrd, optionsColorNewPrd).map((el) => {
        return {
          code: el.value,
          text: el.label
        }
      })
      product = {
        code: productCode,
        model: prdModel?.[0],
        storage: prdStorage?.[0],
        color: prdColor?.[0],
      }
    }


    const obj = {
      // type : typeInstallment,
      remark,
      remark_consider,
      remark_approve_special,
      remark_reject,
      is_approve_special,
      salary_avg,
      // installments: typeInstallment === "TURN1" ? "" : period,
      installments: period,
      product: product,
      // product: {
      //   model,
      //   storage,
      //   color,
      // },
      // acceptCondition: acceptConditions,
      productHand: typeInstallment !== 'INSTALLMENT' ? productHand_newPrd : productHand,
      profile,
      job,
      customer_grade: customer_grade,
      statement: {
        type: incomeDoc,
        password: incomeDoc === 'NO' ? '' : filePassword,
        images:
          incomeDoc === 'NO'
            ? []
            : fileListStatement.map((val) => ({
              fileName: val?.fileName || '',
              fileNameOrigin: val?.fileNameOrigin || '',
              mimeType: val?.mimeType || '',
              url: val?.url || '',
            })),
      },
      knownUfriendFrom: knownUfriend,
      interestedBranch: interestedBranch,
      othersDetails: othersDetails,
      contact: [
        {
          relationship: relationship1,
          firstName: firstName1,
          lastName: lastName1,
          phoneNumber: phoneNumber1,
        },
        {
          relationship: relationship2,
          firstName: firstName2,
          lastName: lastName2,
          phoneNumber: phoneNumber2,
        },
        {
          relationship: relationship3,
          firstName: firstName3,
          lastName: lastName3,
          phoneNumber: phoneNumber3,
        },
      ],
      summary,
      forceSave: remark_consider !== '' ? true : false,
      combo: comboPd?.combo || [],
      walkin: walkin,
    };

    if (typeInstallment === 'TURN2') {
      obj.productTurn = productTurn;
    }

    if (occupation === 'บุคคลทั่วไป') {
      obj.proveOwnBusinessImageFlag = proveOwnBusinessImageFlag === 'REGISTERED_BUSINESS' ? true : false;
      obj.proveOwnBusinessImage =
        jobDescriptionState === 'เจ้าของกิจการ' && typeInstallment === 'TURN1' && proveOwnBusinessImageFlag === 'REGISTERED_BUSINESS'
          ? registeredBusinessDocImage &&
          registeredBusinessDocImage.map((val) => ({
            fileName: val?.fileName || '',
            fileNameOrigin: val?.fileNameOrigin || '',
            mimeType: val?.mimeType || '',
            url: val?.url || '',
          }))
          : jobDescriptionState === 'เจ้าของกิจการ' && typeInstallment === 'INSTALLMENT' && unRegisteredbusinessDocImage.length !== 0
            ? unRegisteredbusinessDocImage &&
            unRegisteredbusinessDocImage.map((val) => ({
              fileName: val?.fileName || '',
              fileNameOrigin: val?.fileNameOrigin || '',
              mimeType: val?.mimeType || '',
              url: val?.url || '',
            }))
            : jobDescriptionState === 'เจ้าของกิจการ' &&
              typeInstallment === 'TURN1' &&
              proveOwnBusinessImageFlag === 'UNREGISTERED_BUSINESS' &&
              unRegisteredbusinessDocImage.length !== 0
              ? unRegisteredbusinessDocImage &&
              unRegisteredbusinessDocImage.map((val) => ({
                fileName: val?.fileName || '',
                fileNameOrigin: val?.fileNameOrigin || '',
                mimeType: val?.mimeType || '',
                url: val?.url || '',
              }))
              : jobDescriptionState === 'เจ้าของกิจการ' &&
                proveOwnBusinessImageFlag === 'UNREGISTERED_BUSINESS' &&
                unRegisteredbusinessDocImage.length === 0
                ? proveOwnBusinessImageList &&
                proveOwnBusinessImageList.map((val) => ({
                  fileName: val?.fileName || '',
                  fileNameOrigin: val?.fileNameOrigin || '',
                  mimeType: val?.mimeType || '',
                  url: val?.url || '',
                }))
                : [];
      obj.salesChannelsImage = handleSalesChannelValue(proveOwnBusinessImageFlag, salesChannels, urlOnlineSale);
      obj.governmentOfficial =
        jobDescriptionState === 'พนักงานราชการ' &&
        fileListGovernmentCardImage &&
        fileListGovernmentCardImage.map((val) => ({
          fileName: val?.fileName || '',
          fileNameOrigin: val?.fileNameOrigin || '',
          mimeType: val?.mimeType || '',
          url: val?.url || '',
        }));
      obj.proveWork =
        (jobDescriptionState === 'พนักงานชั่วคราว/พนักงานอิสระ' &&
          business !== 'ไรเดอร์/แมสเซ็นเจอร์/แท็กซี่' &&
          proveWork &&
          proveWork.map((val) => ({
            fileName: val?.fileName || '',
            fileNameOrigin: val?.fileNameOrigin || '',
            mimeType: val?.mimeType || '',
            url: val?.url || '',
          }))) ||
        [];

      obj.riderAppication =
        jobDescriptionState === 'พนักงานชั่วคราว/พนักงานอิสระ' &&
        business === 'ไรเดอร์/แมสเซ็นเจอร์/แท็กซี่' &&
        riderAppicationImage &&
        riderAppicationImage.map((val) => ({
          fileName: val?.fileName || '',
          fileNameOrigin: val?.fileNameOrigin || '',
          mimeType: val?.mimeType || '',
          url: val?.url || '',
        }));

      obj.salesChannels = salesChannels || '';
    } else {
      obj.proveOwnBusinessImageFlag = '';
      obj.proveOwnBusinessImage = [];
    }

    // if (occupation === 'บุคคลทั่วไป') {
    //   obj['aScore'] = dataAscore?.aScore;
    // }

    obj.updatedWalkinUser = updatedWalkinUser

    /* typeLock */
    obj['typeLock'] = typeLock
    console.log('obj handleSave :>> ', obj);

    try {
      const res = await editConsider(registerId, { ...obj, fbBlacklist })
      const { success, message } = res;
      if (success) {
        if ((statusApp === 'APPROVED' || statusApp === 'REJECTED') && user && user.role === 'Admin') {
          onConfirmConsider(registerId, data.record[0]?.considerId, params);
        } else {
          Modal.success({
            title: message,
            // afterClose: () => {
            //   onCloseMain();
            //   data.reApi(data.valueSearch);
            // },
          });
        }
      }
    } catch (err) {
      const { message } = err;

      Modal.error({
        title: '',
        content: message || '',
      });
    }
  };

  const onSubmit = async (params) => {
    const { occupation, incomeDoc } = params;
    if (current === registerSteps.length - 1) {
      // if ((params.statusApp === "APPROVED") && Object.keys(fbBlacklist).length === 0) {
      // 	Modal.warning({
      // 		title: "กรุณากดปุ่ม 'ตรวจสอบข้อมูล' ก่อนทำรายการอนุมัติ",
      // 	});
      // 	return
      // }
      // if (typeInstallment === 'SECONDHAND') {
      //   onConfirmConsider(registerId, data.record[0].considerId, params);
      // } else {
      handleSave(params)
      // }
    } else {
      let err = {};
      if (current === 1) {
        let id_card = getValues('idCard');
        if (validateIdCardFn(id_card) !== '') {
          let error = validateIdCardFn(id_card);
          err.idCard = { type: 'custom', message: error };
        }

        let dob = getValues('dateOfBirth');
        if (dob !== '' || !_.isEmpty(dob)) {
          if (validateDate(dob) !== '') {
            let error = validateDate(dob);
            err.dateOfBirth = { type: 'custom', message: error };
          }
        }
      }

      if (current === 3) {
        if (fileListStatement.length === 0 && incomeDoc !== 'NO') {
          err.statementImage = { type: 'custom', message: 'โปรดแนบไฟล์เอกสาร' };
        }
        if (fileListStudentCardImages.length === 0 && occupation === 'นักเรียน/นักศึกษา') {
          // err.studentCardImages = { type: 'custom', message: 'โปรดอัพโหลดไฟล์บัตรนักศึกษา' }
        }
        if (fileListStudentTranscriptImages.length === 0 && occupation === 'นักเรียน/นักศึกษา') {
          // err.studentTranscriptImages = { type: 'custom', message: 'โปรดอัพโหลดไฟล์ระเบียนการศึกษา' }
        }

        if (jobDescriptionState === 'พนักงานชั่วคราว/พนักงานอิสระ') {
          if (business === 'ไรเดอร์/แมสเซ็นเจอร์/แท็กซี่' && riderAppicationImage && riderAppicationImage.length === 0) {
            // err.riderAppication = { type: 'custom', message: 'โปรดอัพโหลดไฟล์ภาพ' }
          }
          if (business !== 'ไรเดอร์/แมสเซ็นเจอร์/แท็กซี่' && proveWork && proveWork.length === 0) {
            // err.proveWork = { type: 'custom', message: 'โปรดอัพโหลดไฟล์ภาพ' }
          }
        }

        if (jobDescriptionState === 'เจ้าของกิจการ') {
          if (typeInstallment === 'INSTALLMENT') {
            if (proveOwnBusinessImageList && proveOwnBusinessImageList.length === 0) {
              // err.unRegisteredbusinessDocImage = { type: 'custom', message: 'โปรดอัพโหลดไฟล์ภาพ' }
            }
            if (
              proveOwnBusinessImageList &&
              proveOwnBusinessImageList.length === 0 &&
              unRegisteredbusinessDocImage &&
              unRegisteredbusinessDocImage.length === 0
            ) {
              // err.unRegisteredbusinessDocImage = { type: 'custom', message: 'โปรดอัพโหลดไฟล์ภาพ' }
            }
          }

          if (typeInstallment === 'TURN1') {
            if (proveOwnBusinessImageFlag === 'REGISTERED_BUSINESS' && registeredBusinessDocImage && registeredBusinessDocImage.length === 0) {
              // err.registeredBusinessDocImage = { type: 'custom', message: 'โปรดอัพโหลดไฟล์ภาพ' }
            }
            if (
              proveOwnBusinessImageFlag === 'UNREGISTERED_BUSINESS' &&
              proveOwnBusinessImageList &&
              proveOwnBusinessImageList.length === 0 &&
              unRegisteredbusinessDocImage &&
              unRegisteredbusinessDocImage.length === 0
            ) {
              // err.unRegisteredbusinessDocImage = { type: 'custom', message: 'โปรดอัพโหลดไฟล์ภาพ' }
            }
            if (
              proveOwnBusinessImageFlag === 'UNREGISTERED_BUSINESS' &&
              saleChannel === 'OFFLINE' &&
              productShopSaleImage &&
              productShopSaleImage.length === 0
            ) {
              // err.productShopSaleImage = { type: 'custom', message: 'โปรดอัพโหลดไฟล์ภาพ' }
            }
          }
        }
      }
      if (Object.keys(err).length > 0) {
        Object.keys(err).forEach((fieldName) => {
          setError(fieldName, err[fieldName]);
        });
        return; // Return here to stop further execution
      } else {
        next();
      }
    }
  };

  const onConfirmConsider = async (registerId, considerId, params) => {
    const { statusApp, remark_consider, chatcone, type, typeLock } = params;
    const obj = {
      status: typeInstallment === 'SECONDHAND' ? 'APPROVED' : statusApp,
      forceSave: remark_consider !== '' ? true : false,
    };

    try {
      setLoading(true);
      let res = await PUT(CONFIRM_CONSIDER(registerId, considerId), obj);
      const { success, message } = res;
      if (success) {

        await new Promise((resolve) => {
          if (statusApp === 'REJECTED') {
            Modal.confirm({
              title: 'ต้องการเพิ่ม blacklist',
              icon: <ExclamationCircleOutlined />,
              content: 'ต้องการเพิ่ม blacklist ท่านนี้ใช่หรือไม่',
              onOk() {
                handleBlacklist(params);
              },
              onCancel() {
                Modal.success({
                  title: message,
                  afterClose: () => {
                    onCloseMain();
                    setValue('status', '');
                    resolve(); // ให้ Promise สำเร็จเมื่อ Modal ปิด
                  },
                });
              },
              okText: 'ใช่',
              cancelText: 'ไม่ใช่',
            });
          } else {
            Modal.success({
              title: message,
              afterClose: () => {
                if (typeInstallment === 'INSTALLMENT') {
                  onCloseMain();
                  data.reApi(data.valueSearch);
                  setValue('status', '');
                } else {
                  handleClickGenerateLink();
                  setDisableField(true);
                }
                resolve(); // ให้ Promise สำเร็จเมื่อ Modal ปิด
              },
            });
          }
        });

        /* CHATCONE: 2 RESULT_REGISTER 'APPROVED' |'REJECTED'
          กรณีกดบันทึก โดยที่เลือก อนุมัติ หรือไม่อนุมัติ */
        if (chatcone?.social_id && (statusApp === 'APPROVED' || statusApp === 'REJECTED')) {
          let processType;

          if (statusApp === 'APPROVED' && type === 'INSTALLMENT' && typeLock === 'MDM') {
            processType = 'RESULT_REGISTER_APPROVE_INSTALLMENT_MDM';
          }
          if (statusApp === 'APPROVED' && type === 'INSTALLMENT' && typeLock === 'MDMICLOUD') {
            processType = 'RESULT_REGISTER_APPROVE_INSTALLMENT_MDMICLOUD';
          }
          if (statusApp === 'APPROVED' && type === 'TURN1' && typeLock === 'ICLOUD') {
            processType = 'RESULT_REGISTER_APPROVE_REFINANCE_ICLOUD';
          }
          if (statusApp === 'APPROVED' && type === 'TURN1' && typeLock === 'MDMICLOUD') {
            processType = 'RESULT_REGISTER_APPROVE_REFINANCE_MDMICLOUD';
          }

          const setInput = {
            process_type: processType,
            ref_id: registerId,
            route_from: `${APP_URL}${pathname}`,
            data: {
              remark: remark_consider,
              url_sign: urlSignOnline || null
            }
          }

          /* ติ๊ก ส่งผลการพิจารณาให้ลูกค้าผ่านทาง Chatcone */
          if (checkSendChatcone === true) {
            await sendChatconeByProcessFn({ ...setInput })
          } else {
            /* CHATCONE: 2.1 RESULT_REGISTER 'APPROVED' | 'REJECTED' update tag auto เมื่อกดบันทึก */
            await updateTagChatconeByProcessFn({ ...setInput });
          }
        }
      }
    } catch (err) {
      const { message } = err;

      if (message.includes('blacklist')) {
        setError('remark_consider', { type: 'custom', message: 'บุคคลนี้อยู่ใน Backlist กรุณาใส่หมายเหตุ' });
        // Modal.confirm({
        // 	title: message,
        // 	content: <div><label>หมายเหตุ</label><TextArea onChange={(e)=>{params = {...params,remark:e.target.value,forceSave:true}}}/></div>,
        // 	onOk() {
        // 		onConfirmConsider(registerId, considerId, params)
        // 	},
        // 	okText: "ยืนยัน",
        // 	cancelText: "ยกเลิก",
        // });
      } else {
        Modal.error({
          title: '',
          content: message || '',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const openConfirm = (params, message) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'หลังจากบันทึกข้อมูลสมัครผ่อนแล้ว ลูกค้าจะไม่สามารถแก้ไขข้อมูลได้ โปรดตรวจสอบให้แน่ใจก่อนกดบันทึก',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const replaceReceive = async (registerId) => {
    try {
      setLoading(true);
      let res = await POST(REPLACE_RECEIVE, { registerId });
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            setDisableField(false);
            data.reApi(data.valueSearch);
            clearErrors();
            setCurrent(0);
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: '',
        content: message || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const reRecieve = async (registerId) => {
    try {
      let res = await POST(RE_RECIEVE, { registerId });
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
            data.reApi(data.valueSearch);
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: '',
        content: message || '',
      });
    }
  };

  // const checkBlacklist = async () => {
  //   try {
  //     setLoadingPage(true);
  //     const arr = [
  //       {
  //         _id: getValues('registerId'),
  //         profile: {
  //           lastName: getValues('lastName'),
  //           firstName: getValues('firstName'),
  //           facebook: getValues('facebook'),
  //           phoneNumber: getValues('phoneNumber'),
  //           idCard: getValues('idCard'),
  //         },
  //       },
  //     ];
  //     let res = await checkBlacklistBeforeApprove(arr);
  //     if (res.success) {
  //       setFbBlacklist(res.result);
  //       if (res.result.statusBlacklist === 'BLACKLIST') {
  //         setOpenUserBlacklist(true);
  //       }
  //     }
  //   } catch (err) {
  //     const { message } = err;
  //     Modal.error({
  //       title: '',
  //       content: message || '',
  //     });
  //   } finally {
  //     setLoadingPage(false);
  //   }
  // };

  const installmentForm = FORM_PRODUCT_INSTALLMENT({
    options: {
      optionsColor,
      optionsStorage,
      optionsProducts,
      optionsPeriod,
      type,
      optionsBranchUser,
      occupation,
      newDownPayment,
      optionAdditionalCheckbox,
    },
    fn: { onChange, onChangeCombo },
    data: { productGroup, productCombo, comboProductForm },
    disabledField,
  });

  const turnStep1Form = FORM_PRODUCT_TURN_STEP1({
    options: {
      optionsColor,
      optionsStorage,
      optionsProducts,
      // optionsPeriod,
      // type,
      // optionsBranchUser,
      // optionRefinancePeriod,
      // occupation,
      // optionRefinanceNewPeriod,
      // newMaximum,
      conditionDevice,
      optionsProductCondition
    },
    data: { selectedIssues, activePanels, defaultSelectedIssues },
    fn: { onChange, setSelectedIssues, setActivePanels },
    disabledField,
  });

  useEffect(() => {
    const val = getValues('downPayment');
    setValue('downPayment', val);
  }, [installmentForm]);

  // useEffect(() => {

  //   console.log('TESTTTTTTTTT')

  //   if (productTurnCode) {
  //     const currentCondition = getValues('condition_approve');

  //     console.log("currentCondition => ", currentCondition)
  //     console.log("productTurnCode => ", productTurnCode)

  //     onFetchOptionsProductCondition(productTurnCode)
  //   } else {
  //     setValue('maximum', null);
  //     setValue('condition_approve', null);
  //     setOptionsProductCondition()
  //   }

  // }, [productTurnCode]);

  // const renderBlacklistResult = () => {
  //   if (JSON.stringify(fbBlacklist) !== '{}') {
  //     if (fbBlacklist.statusBlacklist === 'PASS') {
  //       return <div style={{ margin: '2%', color: 'green' }}>{'ผ่านการตรวจสอบ'}</div>;
  //     } else if (fbBlacklist.statusBlacklist === 'BLACKLIST') {
  //       return (
  //         <div style={{ display: 'flex', margin: '2%' }}>
  //           <div style={{ color: 'red', marginTop: '1%' }}>{'ไม่ผ่านการตรวจสอบ'}</div>
  //           <div style={{ marginLeft: '2%' }}>
  //             <Tooltip title="ผลการตรวจสอบ">
  //               <Button type="primary" danger shape="circle" icon={<SearchOutlined />} onClick={() => setOpenUserBlacklist(true)} />
  //             </Tooltip>
  //           </div>
  //         </div>
  //       );
  //     } else {
  //       return <div style={{ margin: '2%', color: 'red' }}>{'ระบบไม่สามารถตรวจสอบข้อมูลได้'}</div>;
  //     }
  //   }
  // };

  const handleCouponChange = (value) => {
    const couponToUpperCase = value.map((el) => {
      return el.toUpperCase()
    })
    setDefaultCoupon(couponToUpperCase)
  }

  const validateDate = (dateString) => {
    // validate dateOfBirth
    // if (dateString === '' || _.isEmpty(dateString)) {
    //   return 'กรุณาระบุ วัน/เดือน/ปีเกิด'
    // }
    const date = moment(dateString, 'DD/MM/YYYY', true);
    if (!date.isValid()) {
      return 'รูปแบบวันที่ไม่ถูกต้อง';
    }
    const thaiYear = date.year();
    const currentYear = moment().year();
    const thaiYearNow = currentYear + 543;
    const limitYear = thaiYearNow - 15;

    // Check if the input year is a Thai Buddhist year (greater than the current Gregorian year + 543)
    if (thaiYear <= currentYear) {
      return 'กรุณาใส่ปีเป็นพุทธศักราช';
    }

    // Validate if the Thai Buddhist year is more than 15 years ago
    if (thaiYear > limitYear) {
      return `กรุณาเลือกปีที่มากกว่าปี ${limitYear}`;
    }

    if (date.isValid()) {
      const age = getAgeMonth(moment(date).subtract(543, 'years')); // ใช้ปี ค.ศ. คิด
      if (age.years > 70) {
        // if (age.years > 70 || (age.years === 70 && age.months > 0)) {
        setValue('age', `${age.years} ปี ${age.months} เดือน`);
        return 'อายุต้องไม่เกิน 70 ปี';
      } else {
        setValue('age', `${age.years} ปี ${age.months} เดือน`);
      }
    }

    return '';
  };

  const validateIdCardFn = (value) => {
    if (value === '' || _.isEmpty(value)) {
      return 'กรุณาระบุเลขบัตรประชาชน';
    }

    const regex = /^[0-9]+$/;
    if (!regex.test(value)) {
      return 'กรอกเฉพาะตัวเลขเท่านั้น';
    }

    if (value.length !== 13) {
      return 'ระบุจำนวน 13 ตัวอักษร';
    }

    const validateIdCard = checkIdCardPattern(value);
    if (!validateIdCard) {
      return 'กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง';
    }

    return '';
  };

  const handleClickGenerateLink = async () => {
    try {
      const res = await generateUrlSignOnline({ id: registerId });

      const { success, result } = res
      if (success) {
        setUrlSignOnline(result?.url_sign)
      }
    } catch (error) {
      return Modal.error({
        title: error?.message || error?.error || 'ไม่สามารถ Generate Link ได้'
      })
    }
  }

  return (
    <>
      {(getChatconeHistoryLoading || sendChatconeByProcessLoading || updateTagChatconeByProcessLoading) ? <Spinners /> : null}
      <Modal
        title={title || 'modal'}
        open={visible}
        width={1350}
        onCancel={onCloseMain}
        footer={null}
        maskClosable={false}
        bodyStyle={{ maxHeight: '90vh' }}
      >
        <Spin spinning={loadingPage}>
          {data?.action !== 'VIEW' && statusConsider === 'CONSIDERING' && disabledField && (
            <div className="title-modal-vivid">
              <span>{`ผู้รับงาน : ${userCreateName}`}</span>
              <ButtonTheme useFor="TRANFER" title="รับงานแทน" onClick={() => replaceReceive(registerId)} />
            </div>
          )}
          {data?.action !== 'VIEW' && ['WAIT_APPOINTMENT_PROMISE', 'WAIT_PROMISE', 'FOLLOWUP'].includes(statusConsider) && (
            <div className="title-modal-rerecieve-vivid">
              <ButtonTheme useFor="REJECT" title="พิจารณาใหม่อีกครั้ง" loading={loading} onClick={() => reRecieve(registerId)} />
            </div>
          )}
          <Steps current={current} style={{ marginBottom: '20px' }}>
            {registerSteps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div style={{ maxHeight: '70vh', overflowY: 'scroll', overflowX: 'hidden', whiteSpace: 'nowrap' }}>
            <form onSubmit={handleSubmit((e) => (current === registerSteps.length - 1 ? openConfirm(e) : onSubmit(e)))}>
              <Row style={{ marginBottom: '22px', marginTop: '22px' }}>
                {((current === 0 || current === 1 && (typeInstallment === 'TURN1' || typeInstallment === 'TURN2'))) && (
                  <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} style={{ textAlign: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                      <Image src={(current === 0 ? productImage : productNewImage) || noImage} preview={false} style={{ width: '70%', margin: 'auto' }} />
                    </div>
                  </Col>
                )}
                <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: ((current === 0 || current === 1 && (typeInstallment === 'TURN1' || typeInstallment === 'TURN2'))) ? 12 : 24 }} lg={{ span: current === 0 ? 12 : 24 }} style={{ paddingRight: '10px' }}>
                  {current === 0 && typeInstallment === 'INSTALLMENT' && (
                    <RenderForm
                      control={control}
                      errors={errors}
                      clearErrors={clearErrors}
                      justify={'left'}
                      formList={installmentForm}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  )}
                  {current === 0 && (typeInstallment === 'TURN1' || typeInstallment === 'TURN2' || typeInstallment === 'SECONDHAND') && (
                    <RenderForm
                      control={control}
                      errors={errors}
                      clearErrors={clearErrors}
                      formList={turnStep1Form}
                      justify={'left'}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  )}
                  {current === 1 && (typeInstallment === 'TURN1' || typeInstallment === 'TURN2') && (
                    <RenderForm
                      control={control}
                      errors={errors}
                      clearErrors={clearErrors}
                      setValue={setValue}
                      getValues={getValues}
                      justify={'left'}
                      formList={FORM_PRODUCT_TURN_STEP2({
                        options: {
                          optionsColorNewPrd,
                          optionsStorageNewPrd,
                          optionsProductsNewPrd,
                          optionsPeriod,
                          // type,
                          optionsBranchUser,
                          // optionRefinancePeriod,
                          // occupation,
                          // optionRefinanceNewPeriod,
                          newMaximum,
                        },
                        data: { defaultCoupon, typeInstallment, disabledDeleteCoupon },
                        fn: { onChange, handleCouponChange },
                        disabledField,
                      })}
                    />
                  )}
                  {((current === 1 && (typeInstallment === 'INSTALLMENT' || typeInstallment === 'SECONDHAND')) || (current === 2 && (typeInstallment === 'TURN1' || typeInstallment === 'TURN2'))) && (
                    <RenderForm
                      control={control}
                      errors={errors}
                      clearErrors={clearErrors}
                      justify={'left'}
                      formList={FORM_PERSONAL_INFO({
                        options: {
                          optionsPrefix,
                          optionProvince,
                          optionAmphoe,
                          optionDistrict,
                          optionsCountPerson,
                          optionsPhoneSystem,
                        },
                        data: { typeInstallment, checkPrefix, imeiImageList, iphoneImageList },
                        fn: { onChange, onPreview, onChangeUploadImage, setLoadingUpload },
                        disabledField,
                      })}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  )}
                  {((current === 2 && typeInstallment === 'INSTALLMENT') || (current === 3 && (typeInstallment === 'TURN1' || typeInstallment === 'TURN2'))) && (
                    <RenderForm
                      control={control}
                      errors={errors}
                      clearErrors={clearErrors}
                      justify={'left'}
                      formList={FORM_OCCUPATION({
                        options: {
                          optionsDoc,
                          optionsOccupation,
                          optionsJobDescription,
                          optionsExperience,
                          optionAvgIncome,
                          optionStudentGrade,
                          optionsBusinessFullTime,
                          optionsBusinessFullTime2,
                          optionsBusinessPartTime,
                          optionsBusinessGovernment,
                          optionsBusinessOwn,
                        },
                        fn: { onChange, onPreview, onChangeUploadImage, setLoadingUpload },
                        data: {
                          occupation,
                          jobDescriptionState,
                          incomeDoc,
                          fileListStudentCardImages,
                          fileListStudentTranscriptImages,
                          fileListStatement,
                          proveOwnBusinessImageList,
                          fileListGovernmentCardImage,
                          business,
                          businessFullTime,
                          proveWork,
                          riderAppicationImage,
                          typeInstallment,
                          proveOwnBusinessImageFlag,
                          saleChannel,
                          registeredBusinessDocImage,
                          unRegisteredbusinessDocImage,
                          productShopSaleImage,
                          visibleBusinessDocField,
                        },
                        disabledField,
                      })}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  )}
                  {((current === 3 && typeInstallment === 'INSTALLMENT') || (current === 4 && (typeInstallment === 'TURN1' || typeInstallment === 'TURN2'))) && (
                    <RenderForm
                      control={control}
                      errors={errors}
                      clearErrors={clearErrors}
                      justify={'left'}
                      formList={FORM_PERSONAL_CONTACT({ options: { optionRelationship }, disabledField, data: { typeInstallment } })}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  )}
                  {((current === 4 && typeInstallment === 'INSTALLMENT') || (current === 5 && (typeInstallment === 'TURN1' || typeInstallment === 'TURN2')) || (current === 2 && typeInstallment === 'SECONDHAND')) && (
                    <RenderForm
                      control={control}
                      errors={errors}
                      clearErrors={clearErrors}
                      justify={'left'}
                      formList={FORM_CONSIDER({
                        options: {
                          statusApprove,
                          optionsTypeLockInstallment,
                          optionsTypeLockRefinance,
                          optionsBranchUser
                        },
                        disabledField,
                        data: {
                          statusAppDefault,
                          knownUfriend,
                          defaultRemark,
                          occupation,
                          dataAscore,
                          isApproveSpecial,
                          fgfData,
                          walkin,
                          acceptConditions,
                          perAction,
                          typeInstallment,
                          defaultTypeLock,
                          urlSignOnline,
                          signOnlineStatus,
                          registerId
                        },
                        fn: { onChange, handleClickGenerateLink },
                      })}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  )}
                  {current === registerSteps.length - 1 && (
                    <div>
                      {/* <ContainerButton left>
                        <ButtonTheme
                          useFor="VIEW"
                          disabled={false}
                          title="ตรวจสอบข้อมูล"
                          width={current === 2 ? '96%' : '48%'}
                          widthMb={current === 2 ? '96%' : '45%'}
                          loading={loading}
                          onClick={() => checkBlacklist()}
                        />
                      </ContainerButton>
                      {renderBlacklistResult()} */}

                      {/* History Send Chatcone */}
                      {!_.isEmpty(data?.record[0]?.chatcone?.social_id) ? (
                        <DatatableLog
                          style={{ marginTop: '20px' }}
                          columns={ApproveHistoryColumn()}
                          data={getChatconeHistoryData}
                          pagination={{ pageSize: 2 }}
                        />
                      ) : null}
                    </div>
                  )}
                  <div>
                    {current === registerSteps.length - 1 && !_.isEmpty(data?.record[0]?.chatcone?.social_id) && (
                      <ContainerButton center style={{ marginBottom: '15px' }}>
                        <Checkbox
                          checked={checkSendChatcone}
                          onChange={(e) => {
                            setCheckSendChatcone(e.target.checked);
                          }}>
                          {'ส่งผลการพิจารณา และ ลิงค์เซ็นสัญญาออนไลน์ ให้ลูกค้าผ่านทาง Chatcone'}
                        </Checkbox>
                      </ContainerButton>
                    )}

                    <ContainerButton center>
                      {current > 0 && ((current < 5 && typeInstallment === 'INSTALLMENT') || (current < 6 && (typeInstallment === 'TURN1' || typeInstallment === 'TURN2')) || (current < 3 && typeInstallment === 'SECONDHAND')) && <ButtonTheme useFor="BACK" title="ย้อนกลับ" width="100%" onClick={() => prev()} loading={loadingUpload} />}

                      {current < registerSteps.length - 1 && (
                        <ButtonTheme useFor="NEXT" title="ถัดไป" htmlType="submit" width={current === 0 ? '100%' : '48%'} loading={loadingUpload} />
                      )}
                      {current === registerSteps.length - 1 && (
                        <ButtonTheme
                          useFor="SUBMIT"
                          disabled={disabledField}
                          title="บันทึก"
                          htmlType="submit"
                          width={current === 2 ? '96%' : '48%'}
                          widthMb={current === 2 ? '96%' : '45%'}
                          loading={loading}
                        />
                      )}
                      {/* {current === registerSteps.length - 1 && ["WAIT_APPOINTMENT_PROMISE", "WAIT_PROMISE", "FOLLOWUP"].includes(statusConsider) &&(
                                <ButtonTheme useFor="REJECT"  title="พิจารณาใหม่อีกครั้ง"   loading={loading} onClick={()=>reRecieve(registerId)}/>
                              )} */}
                    </ContainerButton>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Spin>
      </Modal >
      <Modal open={previewVisible} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <BlacklistCustomerModal openUserBlacklist={openUserBlacklist} onClose={() => setOpenUserBlacklist(false)} fbBlacklist={fbBlacklist} />
    </>
  );
};
