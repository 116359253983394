import {useEffect, useState, useMemo} from 'react';
import {Form, Select, Input, Row, Col, Modal, DatePicker, InputNumber} from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';
import {CONTRACT_TYPE} from '../constants';
import {checkSameNameWithPrefix, convertNumberToComma} from '../../../../functions/fn';
import {handleTrimValue} from '../../../../utils/antform';
import {disabledBeforeCurrentDate} from '../../../../utils/date';
import {ButtonTheme, Spinners, Upload} from '../../../../components';
import {SelectInsuranceStatus} from '../../../../components/masterdata';
import {SelectBank, SelectProductAll, SelectProductCondition} from '../../../../components/inputfromapi';
import {GCS_BUCKET_CONTRACT_IMAGE, GCS_PATH_CONTRACT_IMAGE} from '../../../../env';
import {GET_DATA_OCR, POST} from '../../../../services';
import {getProductData} from '../../../../services/api/svAPI';

const formName = 'FormSecondHand';
const {SECONDHAND} = CONTRACT_TYPE;
const ININT_CAL = {
  contractType: SECONDHAND, //ประเภทสัญญา
  condition: '', //เงื่อนไข
  price: 0, //ราคา
  productCode: '', //รหัสสินค้า
};

export const FormSecondHand = ({defaultData, data, fn, clear}) => {
  const {options, admin_branch, record, contractType} = data;
  const {checkCustomer} = fn;

  /* Form */
  const [form] = Form.useForm();
  /* Fullname */
  const [fullname, setFullName] = useState(null);
  const [enabledChangeName, setEnabledChangeName] = useState(false);
  /* Product */
  const [productData, setProductData] = useState({});
  /* Maximum */
  const [maxPrice, setMaxPrice] = useState(0);
  /* Insurance */
  const [insuranceStatus, setInsuranceStatus] = useState(null);
  /* Insurance */
  const [bankKeyData, setBankKeyData] = useState({});
  /* Calculate */
  const [calculateData, setCalculateData] = useState({...ININT_CAL});
  /* File */
  const [bookBankImageList, setBookBankImageList] = useState([]);
  const [mobileImageList, setMobileImageList] = useState([]);
  const [documentImageList, setDocumentImageList] = useState([]);
  const [changeNameImageList, setChangeNameImageList] = useState([]);
  /* Disabled */
  const [disableBankCode, setDisableBankCode] = useState(false);
  const [disableBankName, setDisableBankName] = useState(false);
  const [disableBankNumber, setDisableBankNumber] = useState(false);
  /* Loading API */
  const [uploadBookBankLoading, setUploadBookBankLoading] = useState(false);
  const [uploadMobileLoading, setUploadMobileLoading] = useState(false);
  const [uploadDocumentLoading, setUploadDocumentLoading] = useState(false);
  const [uploadChangeNameLoading, setUploadChangeNameLoading] = useState(false);
  const [getOCRLoading, setGetOCRLoading] = useState(false);
  const [productDataLoading, setProductDataLoading] = useState(false);

  /* --------- useMemo calculateData ---------*/
  const calDtMemo = useMemo(() => {
    return calculateData;
  }, [calculateData]);

  /* --------- useEffect set default data ---------*/
  useEffect(() => {
    if (!_.isEmpty(defaultData)) {
      const {condition, serialNumber, productTurn} = defaultData;

      form.setFieldsValue({
        branch: admin_branch,
        condition: condition,
        product_code: productTurn?.code,
        serial_number: serialNumber,
      });

      setCalculateData((prev) => ({
        ...prev,
        condition: condition,
        productCode: productTurn?.code,
      }));
    }
  }, [defaultData]);

  /* --------- useEffect SetMaxPrice ---------*/
  useEffect(() => {
    const maxPrice = productData?.productTurn?.price || 0;
    setMaxPrice(maxPrice);
    form.setFieldValue('maximum', maxPrice);
  }, [calDtMemo?.productCode, calDtMemo?.condition, productData]);

  /* --------- useEffect Get Product ---------*/
  useEffect(() => {
    if (contractType && !_.isEmpty(calDtMemo?.productCode) && calDtMemo?.condition)
      getProductDataFn({
        type: contractType,
        productCode: calDtMemo?.productCode,
        condition: calDtMemo?.condition,
      });
  }, [contractType, calDtMemo?.productCode, calDtMemo?.condition]);

  /* --------- useEffect set default data ---------*/
  useEffect(() => {
    if (!_.isEmpty(defaultData)) {
      const {condition, product} = defaultData;

      form.setFieldsValue({
        branch: admin_branch,
        condition: condition,
        product_code: product?.code,
      });

      setCalculateData((prev) => ({
        ...prev,
        condition: condition,
        productCode: product?.code,
      }));
    }
  }, [defaultData]);

  /* --------- useEffect setFullName ---------*/
  useEffect(() => {
    if (record?.fullname) {
      setFullName(record?.fullname);
    }
  }, [record?.fullname]);

  /* --------- useEffect getDataOCR ---------*/
  useEffect(() => {
    if (bookBankImageList && bookBankImageList[0]?.url) {
      setDisableBankCode(true);
      setDisableBankName(true);
      setDisableBankNumber(true);
      getDataOCR(bookBankImageList[0]?.url);
    }
  }, [bookBankImageList]);

  /* --------- API Fn ---------*/
  const getProductDataFn = async (params) => {
    try {
      if (!_.isEmpty(params)) {
        setProductDataLoading(true);

        const response = await getProductData(params);
        const {success, result} = response;

        if (success) {
          setProductData(result);
        }
      }
    } catch (err) {
      Modal.error({
        title: 'ไม่สามารถดึงข้อมูลสินค้าได้',
        content: err?.message,
      });
    } finally {
      setProductDataLoading(false);
    }
  };

  const getDataOCR = async (url) => {
    try {
      setGetOCRLoading(true);
      const res = await POST(GET_DATA_OCR, {link: url});
      const {message, data} = res;
      const setFields = [];

      if (message === 'get ocr success') {
        if (JSON.stringify(data?.ocr) !== '{}') {
          /* ธนาคาร */
          if (!data?.ocr?.bankCode) {
            setFields.push({
              name: 'bankCode',
              errors: ['ไม่สามารถอ่าน OCR รูปหน้า Book Bank ได้'],
            });
          } else {
            setFields.push({
              name: 'bankCode',
              value: data?.ocr?.bankCode,
              errors: [],
            });
            setFields.push({
              name: 'bankName',
              value: bankKeyData[data?.ocr?.bankCode]?.label,
              errors: [],
            });
          }

          /* ชื่อบัญชี */
          if (!data?.ocr?.accountName) {
            setFields.push({
              name: 'bookBankName',
              errors: ['ไม่สามารถอ่าน OCR รูปหน้า Book Bank ได้'],
            });
          } else {
            if (!fullname) {
              setFields.push({
                name: 'bookBankName',
                errors: ['ไม่พบข้อมูลชื่อ-นามสกุล'],
              });
            } else {
              const checkName = checkSameNameWithPrefix(fullname, data?.ocr.accountName);
              if (checkName === false) {
                setEnabledChangeName(true);
              } else if (checkName === true) {
                setEnabledChangeName(false);
              }
              setFields.push({
                name: 'bookBankName',
                value: data?.ocr?.accountName,
                errors: [],
              });
            }
          }

          /* เลขบัญชี */
          if (!data?.ocr?.accountNo) {
            setFields.push({
              name: 'bookBankNo',
              errors: ['ไม่สามารถอ่าน OCR รูปหน้า Book Bank ได้'],
            });
          } else {
            setFields.push({
              name: 'bookBankNo',
              value: data?.ocr?.accountNo,
              errors: [],
            });
          }
        }

        form.setFields(setFields);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message || '',
        content: error || '',
      });
    } finally {
      setGetOCRLoading(false);
      setDisableBankCode(false);
      setDisableBankName(false);
      setDisableBankNumber(false);
    }
  };

  /* --------- Fn ---------*/
  const handleProductChange = (value) => {
    form.setFields([{name: 'product_code', value: value, errors: []}]);
    setCalculateData((prev) => ({...prev, productCode: value}));
  };

  const handleConditionChange = (value) => {
    form.setFields([{name: 'condition', value: value, errors: []}]);
    setCalculateData((prev) => ({...prev, condition: value}));
  };

  const handlePriceChange = (value) => {
    const rawValue = value?.replace(/,/g, ''); // ลบ "," ออกจากตัวเลข
    const newValue = Number(rawValue) || 0;
    const finalValue = Math.min(newValue, maxPrice || Number.MAX_SAFE_INTEGER); // จำกัดค่ามากสุด
    form.setFields([{name: 'price', value: finalValue, errors: []}]);
    setCalculateData((prev) => ({...prev, price: finalValue}));
  };

  const handleChangeInsuranceStatus = (e) => {
    form.setFieldsValue({insurance_status: e});
    if (e === 'NO') {
      form.setFieldsValue({insurance_date: null});
    }
    setInsuranceStatus(e);
  };

  const handleChangeBookBankName = (e) => {
    let checkName = checkSameNameWithPrefix(fullname, e);
    if (checkName === false) {
      setEnabledChangeName(true);
    } else if (checkName === true) {
      setEnabledChangeName(false);
    }
  };

  const handleClearForm = () => {
    form.resetFields();
    setProductData({});
    setMaxPrice(0);
    setInsuranceStatus(null);
    setCalculateData({...ININT_CAL});
    setBookBankImageList([]);
    setMobileImageList([]);
    setDocumentImageList([]);
    setChangeNameImageList([]);
  };

  const onFinish = async (values) => {
    try {
      const {
        product_code,
        serial_number,
        branch,
        condition,
        price,
        imei,
        insurance_status,
        insurance_date,
        imageBookBank,
        bankCode,
        bankName,
        branchBankName,
        bookBankName,
        bookBankNo,
        mobile_image,
        document_image,
        change_name_image,
        remark,
      } = values;

      const productCode = product_code;
      const productName = productData?.productName || '';

      const payload = {
        user_id: record?._id,
        type: contractType,
        branch: branch,
        price: price,
        productCode: [{code: productCode, name: productName, serialNumber: serial_number}],
        serial_number: [
          {
            product_code: productCode,
            product_name: productName,
            serial_number: serial_number,
            price: price,
            insurance: {
              date: dayjs(insurance_date).format('YYYY-MM-DD'),
              status: insurance_status,
            },
            imei: imei,
          },
        ],
        serial_number2: serial_number,
        condition: condition,
        contract: {
          type: contractType,
          package: '',
          price_package: 0,
          promotion_code: 'none',
          imageBookBank,
          bankCode,
          bankName,
          branchBankName,
          bookBankName,
          bookBankNo,
          mobile_image,
          document_image,
          change_name_image,
        },
        remark: remark,
      };

      checkCustomer(payload);
    } catch (err) {}
  };

  return (
    <>
      {productDataLoading && <Spinners />}
      <Form
        form={form}
        name={formName}
        layout={'horizontal'}
        labelAlign="right"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
        style={{padding: '0px 20px 20px 20px'}}
        onFinish={onFinish}
        autoComplete="off"
        scrollToFirstError>
        <Form.Item
          name={'branch'}
          label="สาขา"
          labelCol={{span: 4}}
          rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}
          initialValue={admin_branch}>
          <Select options={options?.choicesOptionBranch} />
        </Form.Item>

        <Form.Item name={'product_code'} label="ชื่อสินค้า" labelCol={{span: 4}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <SelectProductAll placeholder={'เลือกสินค้า'} handleChange={(e) => handleProductChange(e?.value)} filter={{type: contractType}} />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'serial_number'} label="S/N" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <Input placeholder={'กรุณากรอก S/N'} onChange={(e) => handleTrimValue(form, 'serial_number', e?.target?.value)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'imei'} label="IMEI" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <Input placeholder={'กรุณากรอกหมายเลขเครื่อง IMEI'} onChange={(e) => handleTrimValue(form, 'imei', e?.target?.value)} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name={'condition'} label="สภาพเครื่อง" labelCol={{span: 4}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <SelectProductCondition placeholder={'เลือก สภาพเครื่อง'} handleChange={(e) => handleConditionChange(e?.value)} />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'insurance_status'} label="ประกัน" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectInsuranceStatus placeholder={'กรุณาเลือกสถานะประกันของเครื่อง'} onChange={(e) => handleChangeInsuranceStatus(e)} />
            </Form.Item>
          </Col>
          {insuranceStatus === 'YES' && (
            <Col span={12}>
              <Form.Item name={'insurance_date'} label="วันที่หมดประกัน" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
                <DatePicker style={{width: '100%'}} placeholder={'กรุณากรอกวันที่หมดประกัน'} disabledDate={disabledBeforeCurrentDate} />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name={'maximum'}
              label="วงเงินสูงสุดที่จะได้รับ"
              labelCol={{span: 8}}
              rules={[{required: true, message: 'ค่าวงเงินสูงสุดที่จะได้รับเป็นค่าว่าง'}]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'price'} label="วงเงินที่อนุมัติ" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <InputNumber
                style={{width: '100%'}}
                placeholder={'กรุณากรอกวงเงินที่อนุมัติ'}
                min={0}
                max={maxPrice || Number.MAX_SAFE_INTEGER}
                formatter={(value, info) => {
                  if (!info.userTyping) {
                    return convertNumberToComma(value);
                  } else {
                    return value;
                  }
                }}
                onBlur={(e) => handlePriceChange(e?.target?.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name={'imageBookBank'} label={'อัพโหลดรูปหน้า Book Bank'} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={bookBankImageList}
            setLoading={setUploadBookBankLoading}
            handleChange={(files) => {
              form.setFields([{name: 'imageBookBank', value: files[0]?.url, errors: []}]);
              setBookBankImageList(files);
            }}
            handleRemove={() => {
              form.setFields([{name: 'imageBookBank', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Row gutter={[16, 16]} justify={'left'}>
          <Col span={12}>
            <Form.Item name={'bankCode'} label="ธนาคาร" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectBank
                placeholder={'กรุณาเลือกธนาคาร'}
                handleChange={(e) => {
                  form.setFields([
                    {name: 'bankCode', value: e?.value, errors: []},
                    {name: 'bankName', value: e?.label, errors: []},
                  ]);
                }}
                dataResponse={(e) => setBankKeyData(_.keyBy(e, 'value'))}
                disabled={disableBankCode}
              />
            </Form.Item>
            <Form.Item name={'bankName'} hidden>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'branchBankName'} label="สาขาธนาคาร" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <Input placeholder={'กรุณากรอกชื่อสาขาธนาคาร'} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify={'center'}>
          <Col span={12}>
            <Form.Item name={'bookBankName'} label="ชื่อบัญชี" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <Input placeholder={'กรุณากรอกชื่อบัญชี'} onChange={(e) => handleChangeBookBankName(e?.target?.value)} disabled={disableBankName} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'bookBankNo'} label="เลขที่บัญชี" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <Input placeholder={'กรุณากรอกเลขที่บัญชี'} disabled={disableBankNumber} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name={'mobile_image'} label="อัพโหลดรูปเครื่อง" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={mobileImageList}
            setLoading={setUploadMobileLoading}
            handleChange={(files) => {
              form.setFields([{name: 'mobile_image', value: files[0]?.url, errors: []}]);
              setMobileImageList(files);
            }}
            handleRemove={() => {
              form.setFields([{name: 'mobile_image', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'document_image'} label="อัพโหลดรูปเอกสาร" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={documentImageList}
            setLoading={setUploadDocumentLoading}
            handleChange={(files) => {
              form.setFields([{name: 'document_image', value: files[0]?.url, errors: []}]);
              setDocumentImageList(files);
            }}
            handleRemove={() => {
              form.setFields([{name: 'document_image', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'change_name_image'} label="อัพโหลดรูปใบเปลี่ยนชื่อ" rules={[{required: enabledChangeName, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={changeNameImageList}
            setLoading={setUploadChangeNameLoading}
            handleChange={(files) => {
              form.setFields([{name: 'change_name_image', value: files[0]?.url, errors: []}]);
              setChangeNameImageList(files);
            }}
            handleRemove={() => {
              form.setFields([{name: 'change_name_image', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'remark'} label="หมายเหตุ" labelCol={{span: 4}}>
          <Input.TextArea rows={4} maxLength={300} showCount />
        </Form.Item>

        <Row justify={'center'}>
          <ButtonTheme useFor="CLEAR" style={{width: 150}} onClick={() => handleClearForm()} />
          <ButtonTheme
            useFor="CUSTOM"
            htmlType="submit"
            title={'ยืนยัน'}
            style={{width: 150}}
            loading={uploadBookBankLoading || uploadMobileLoading || uploadDocumentLoading || uploadChangeNameLoading || getOCRLoading}
          />
        </Row>
      </Form>
    </>
  );
};
