import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Modal, Form, Button, message} from 'antd';
import _ from 'lodash';
import Example from '../../../assets/images/example_detail.jpg';
import {CHECK_CUSTOMER, GET} from '../../../services';
import {useContract} from '../../../hook/useContract';
import {FormInstallment, FormTurn1, FormTurn2, FormSecondHand} from './form';
import {getContractDefault, createContract} from '../../../services/api/svAPI';
import {Spinners} from '../../../components';
import {CONTRACT_TYPE} from './constants';
import {Icon} from '../../../resources/icon';
import {SelectContractType} from '../../../components/masterdata';
import {APP_URL} from '../../../env';
import {useUpdateTagChatconeByProcess} from '../../../hook/chatcone';

const formName = 'CreateContract';
const admin_branch = localStorage.getItem('admin_branch');
const {INSTALLMENT, TURN1, TURN2, SECONDHAND} = CONTRACT_TYPE;

const CreateContractForm = (props) => {
  const {visible, onClose, data, reApi} = props;

  const {pathname} = useLocation();

  /* Reducter */
  const {dispatchExportContract} = useContract();
  /* Form */
  const [form] = Form.useForm();
  /* Type */
  const [contractType, setContactType] = useState(INSTALLMENT);
  /* Modal */
  const [isModalVisible, setIsModalVisible] = useState(false);
  /* Option */
  const [choicesOptionBranch, setChoicesOptionBranch] = useState([]);
  /* Default Data */
  const [defaultDataForm, setDefaultDataForm] = useState({});
  /* is Data ข้อมูลการลงทะเบียน user_beforeverify สถานะรอทำสัญญา */
  const [isWaitPromiseData, setIsWaitPromiseData] = useState(false);
  /* Loading API */
  const [getContractDefaultLoading, setGetContractDefaultLoading] = useState(false);
  const [createContractLoading, setCreateContractLoading] = useState(false);

  const [updateTagChatconeByProcessFn, updateTagChatconeByProcessLoading] = useUpdateTagChatconeByProcess();

  useEffect(() => {
    if (!_.isEmpty(data?.record?.id_card) && visible) {
      setChoicesOptionBranch(data?.optionBranch || []);
      getContractDefaultFn({id_card: data?.record?.id_card});
    }
  }, [data?.record?.id_card, visible]);

  useEffect(() => {
    form.setFieldValue('contractType', contractType);
  }, [contractType]);

  /*-----------------------------API-----------------------------------------*/
  const getContractDefaultFn = async (params) => {
    try {
      if (!_.isEmpty(params?.id_card)) {
        setGetContractDefaultLoading(true);

        const {success, result} = await getContractDefault(params);
        if (success) {
          // show message

          if (result?.product?.code) {
            showMessage('success', 'ดึงข้อมูลลงทะเบียนนัดทำสัญญาสำเร็จ');
            setIsWaitPromiseData(true);
          } else {
            showMessage('warning', 'ไม่พบข้อมูลลงทะเบียนนัดทำสัญญา');
          }

          if (!result?.product?.code) {
          } else {
            const defaultData = {
              ...result,
              coupon: result?.coupon || [],
            };
            setContactType(result?.type);
            setDefaultDataForm(defaultData);
          }
        }
      }
    } catch (err) {
      setDefaultDataForm({});
      showMessage('error', 'ดึงข้อมูลลงทะเบียนนัดทำสัญญาไม่สำเร็จ');
    } finally {
      setGetContractDefaultLoading(false);
    }
  };

  const checkCustomer = async (payload) => {
    try {
      const {success, result} = await GET(CHECK_CUSTOMER(data?.record?.id_card));
      if (success) {
        if (!payload?.contract?.mail && payload?.contract?.checkMail) throw new Error('ไม่สามารถสร้างสัญญาได้ เนื่องจากอีเมล์เดิมไม่พร้อมใช้งาน');

        if (!result?.isRegister) {
          Modal.confirm({
            title: 'ยืนยัน',
            icon: <Icon.warningAlert />,
            content: <div style={{fontSize: '1rem'}}>ลูกค้ายังไม่ได้ลงทะเบียน ต้องการสร้างสัญญาต่อหรือไม่</div>,
            onOk() {
              submitCreateContract(payload);
            },
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
          });
        } else {
          Modal.confirm({
            title: 'ยืนยัน',
            icon: <Icon.warningAlert />,
            content: 'กดยืนยันการสร้างสัญญา',
            onOk() {
              submitCreateContract(payload);
            },
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
          });
        }
      }
    } catch (err) {
      Modal.error({
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        content: err?.message,
      });
    }
  };

  const submitCreateContract = async (payload) => {
    try {
      if (!_.isEmpty(payload?.user_id)) {
        setCreateContractLoading(true);

        const {message, data: resultData} = await createContract(payload);

        if (message === 'create contract success') {
          /* CHATCONE: 6.1 สร้างสัญญาสำเร็จ (แบบเก่า) */
          if (resultData?.status === 'ACTIVE' && data?.record?.chatcone?.social_id) {
            const setInput = {
              ref_id: resultData?.contract_no,
              process_type: 'COMPLETE_CONTRACT',
              route_from: `${APP_URL}${pathname}`,
            };

            await updateTagChatconeByProcessFn({...setInput});
          }

          Modal.success({
            content: (
              <div>
                สร้างสัญญาเรียบร้อย
                <div style={{position: 'relative', height: 32, marginBottom: -32, top: 24}}>
                  <Button onClick={() => dispatchExportContract(data?.contract_no)}>ดาวน์โหลดสัญญา</Button>
                </div>
              </div>
            ),
            afterClose: () => {
              reApi();
              onCloseModal();
            },
          });
        }
      }
    } catch (err) {
      Modal.error({
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        content: err?.message,
      });
    } finally {
      setCreateContractLoading(false);
    }
  };

  /*-----------------------------handleChange Field-----------------------------------------*/
  const handleChangeContractType = (e) => {
    setContactType(e);
  };

  /*--------------------------------Function------------------------------------------*/
  const showMessage = (type, msg) => {
    switch (type) {
      case 'success':
        message.success(msg);
        break;
      case 'error':
        message.error(msg);
        break;
      case 'warning':
        message.warning(msg);
        break;
      default:
        break;
    }
  };

  const setErrorMessage = ({form, error, name, txtError, value}) => {
    // error = สถานะอยากให้เป็น error มั้ย (boolean) true = ใช่ | false = ไม่
    // name = ชื่อ Field ของ Form.Item ที่อยากให้โชว์ error
    // txtError = ข้อความที่อยากให้โชว์ใน error ex. [ไม่มีข้อมูล]
    // value = value ที่อยากให้ set ใน Form.Item นั้น

    const errorForm = form?.getFieldsError();
    let errors = _.cloneDeep(errorForm);

    let newError = {
      name: name,
      errors: error ? txtError : [],
      ...(value ? {value: value} : {}), // Optional: set a specific value
    };

    const exists = errors?.some((item) => {
      return item.name === newError.name && JSON.stringify(item.errors) === JSON.stringify(newError.errors);
    });
    if (!exists) {
      errors?.push(newError);
    }

    form.setFields(errors);
    if (errors.length > 0) {
      form.scrollToField(errors[errors.length - 1].name);
    }
  };

  const onCloseModal = () => {
    onClose();
  };

  const resetState = () => {
    form.resetFields();
    setDefaultDataForm({});
    setContactType(INSTALLMENT);
    setIsWaitPromiseData(false);
  };

  return (
    <>
      {(getContractDefaultLoading || createContractLoading || updateTagChatconeByProcessLoading) && <Spinners />}
      <Modal
        key={data?.record?._id}
        title="สร้างสัญญา ใหม่"
        open={visible}
        maskClosable={false}
        width={1200}
        onCancel={onCloseModal}
        afterClose={resetState}
        destroyOnClose={true}
        footer={null}
        bodyStyle={{maxHeight: '80vh', overflowY: 'scroll'}}>
        <Form
          form={form}
          name={formName}
          layout={'horizontal'}
          labelAlign="right"
          labelCol={{span: 4}}
          wrapperCol={{span: 20}}
          style={{padding: '20px 20px 0px 20px'}}>
          <Form.Item name={'contractType'} label="ประเภทสัญญา" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]} initialValue={'INSTALLMENT'}>
            <SelectContractType placeholder={'กรุณาเลือกประเภทสัญญา'} onChange={(e) => handleChangeContractType(e)} disabled={isWaitPromiseData} />
          </Form.Item>
        </Form>

        {contractType === INSTALLMENT && (
          <FormInstallment
            defaultData={defaultDataForm}
            data={{
              options: {choicesOptionBranch},
              admin_branch: admin_branch,
              record: data?.record,
              contractType,
            }}
            fn={{
              checkCustomer,
              setErrorMessage,
            }}
          />
        )}

        {contractType === TURN1 && (
          <FormTurn1
            defaultData={defaultDataForm}
            data={{
              options: {choicesOptionBranch},
              admin_branch: admin_branch,
              record: data?.record,
              contractType,
            }}
            fn={{
              checkCustomer,
              setErrorMessage,
            }}
          />
        )}

        {contractType === TURN2 && (
          <FormTurn2
            defaultData={defaultDataForm}
            data={{
              options: {choicesOptionBranch},
              admin_branch: admin_branch,
              record: data?.record,
              contractType,
            }}
            fn={{
              checkCustomer,
              setErrorMessage,
            }}
          />
        )}

        {contractType === SECONDHAND && (
          <FormSecondHand
            defaultData={defaultDataForm}
            data={{
              options: {choicesOptionBranch},
              admin_branch: admin_branch,
              record: data?.record,
              contractType,
            }}
            fn={{
              checkCustomer,
            }}
          />
        )}
      </Modal>
      <Modal title="ตัวอย่างรูปรายละเอียดเครื่อง" open={isModalVisible} onCancel={() => setIsModalVisible(false)} width={380} footer={null}>
        <img src={Example} style={{width: '100%'}} alt="No" />
      </Modal>
    </>
  );
};

CreateContractForm.propTypes = {
  data: PropTypes.object.isRequired,
};

CreateContractForm.defaultProps = {
  data: {},
};

export default CreateContractForm;
