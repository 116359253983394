import {useEffect, useMemo, useState} from 'react';
import {Form, Select, Input, Divider, Row, Col, InputNumber, Checkbox, Modal, Space} from 'antd';
import _ from 'lodash';
import {FILTER_MASTER, CONTRACT_TYPE} from '../constants';
import Example from '../../../../assets/images/example_detail.jpg';
import {getImageObject} from '../../fn';
import {convertNumberToComma} from '../../../../functions/fn';
import {handleTrimValue} from '../../../../utils/antform';
import {ButtonTheme, Spinners, Upload} from '../../../../components';
import {InputCoupon} from '../components';
import {
  SelectMDMProvider,
  SelectMDMSite,
  SelectProductAll,
  SelectPeriods,
  SelectTypeLock,
  SelectProductCondition,
} from '../../../../components/inputfromapi';
import {GCS_BUCKET_CONTRACT_IMAGE, GCS_PATH_CONTRACT_IMAGE} from '../../../../env';
import {POST, RAMDOM_MAIL, GET_FGF_DISCOUNT} from '../../../../services';
import {getAdminMDMDefault} from '../../../../apiv2';
import {calculateCreateContract} from '../../../../services/api/svAPI';

/*
coupon: [
  {
    coupon_id: 'PON-25-0006',
    coupon_code: 'REGISTERCOUPON',
    coupon_discount: 200,
  },
];
*/

const formName = 'FormTurn1';
const {TURN1} = CONTRACT_TYPE;
const ININT_CAL = {
  contractType: TURN1, //ประเภทสัญญา
  productCode: '', //รหัสสินค้า
  condition: '', //เงื่อนไข
  price: 0, //ราคา
  periods: 0, //งวด
  coupon: [], //คูปอง
  discountPeriod: {}, //ส่วนลดค่าเครื่อง
  discountTurn1: {}, //ส่วนลดเทิร์น1
};

export const FormTurn1 = ({defaultData, data, fn}) => {
  const {options, admin_branch, record, contractType} = data;
  const {checkCustomer, setErrorMessage} = fn;

  /* Form */
  const [form] = Form.useForm();
  /* Product */
  const [productSaleByPeriod, setProductSaleByPeriod] = useState({}); //สำหรับ map period เพื่อได้ค่า price
  /* Maximum */
  const [maxPrice, setMaxPrice] = useState(0);
  /* Coupon */
  const [couponList, setCouponList] = useState([]);
  const [couponDataList, setCouponDataList] = useState([]);
  /* Type Lock */
  const [typeLock, setTypeLock] = useState('');
  const [defualtMDMByKey, setDefualtMDMByKey] = useState('');
  /* Frind Get Friend */
  const [fgfData, setFGFData] = useState({});
  const [fgfTextExtra, setFGFTextExtra] = useState('');
  /* Calculate */
  const [calculateData, setCalculateData] = useState({...ININT_CAL});
  /* File */
  const [fileList, setFileList] = useState([]);
  const [fileMachineDetailImageList, setFileMachineDetailImageList] = useState([]);
  const [fileImeiImageList, setFileImeiImageList] = useState([]);
  const [fileOtherImageList, setFileOtherImageList] = useState([]);
  /* CheckMail */
  const [checkMail, setCheckMail] = useState(false);
  /* Modal */
  const [isModalVisible, setIsModalVisible] = useState(false);
  /* Loading API */
  const [imageLoading, setImageLoading] = useState(false);
  const [machineDetailImageLoading, setMachineDetailImageLoading] = useState(false);
  const [imeiImageLoading, setImeiImageLoading] = useState(false);
  const [otherImageLoading, setOtherImageLoading] = useState(false);
  const [calculateLoading, setCalculateLoading] = useState(false);
  const [getAdminMDMDefaultLoading, setMDMDefaultLoading] = useState(false);

  /* --------- useMemo calculateData ---------*/
  const calDtMemo = useMemo(() => {
    return calculateData;
  }, [calculateData]);

  /* --------- useEffect get default MDM ---------*/
  useEffect(() => {
    getAdminMDMDefaultFn();
    getFGFCodeFn(record?.id_card);
  }, [record?.id_card]);

  /* --------- useEffect set default data ---------*/
  useEffect(() => {
    if (!_.isEmpty(defaultData)) {
      const {condition, coupon, summary, discountPeriod, discountTurn1, totalDiscount, product, serialNumber} = defaultData;

      form.setFieldsValue({
        product_code: product?.code,
        serial_number: serialNumber,
        down_amount: summary?.downPayment || 0,
        periods: summary?.period,
        installments: summary?.installmet || 0,
        discount: totalDiscount || 0,
        total: summary?.total || 0,
        condition: condition,
      });

      setCouponDataList((prev) => [...prev, ...coupon]);
      setCalculateData((prev) => ({
        ...prev,
        condition: condition,
        down_amount: summary?.downPayment || 0,
        periods: summary?.period,
        coupon: coupon || [],
        discountPeriod: discountPeriod,
        discountTurn1: discountTurn1,
        productCode: product?.code,
      }));
    }
  }, [defaultData]);

  /* --------- useEffect calculateFn ---------*/
  useEffect(() => {
    if (contractType && !_.isEmpty(calDtMemo?.productCode) && calDtMemo?.price && calDtMemo?.periods) {
      calculateFn(calDtMemo);
    }
  }, [calDtMemo]);

  /* --------- useEffect SetMaxPrice and discountPeriod and discountTurn1 ---------*/
  useEffect(() => {
    const discountPeriod = productSaleByPeriod[calDtMemo?.periods]?.discountPeriod || null;
    const discountTurn1 = productSaleByPeriod[calDtMemo?.periods]?.discountTurn1 || null;
    const price = productSaleByPeriod[calDtMemo?.periods]?.price || 0;

    setMaxPrice(price);
    setCalculateData((prev) => ({
      ...prev,
      discountPeriod: discountPeriod || prev?.discountPeriod || {},
      discountTurn1: discountTurn1 || prev?.discountTurn1 || {},
    }));
  }, [calDtMemo?.productCode, calDtMemo?.periods, calDtMemo?.condition, productSaleByPeriod]);

  /* --------- useEffect set coupon all (discountPeriod+discountTurn1+coupon)---------*/
  useEffect(() => {
    handleSetCouponList(calDtMemo);
  }, [calDtMemo?.discountPeriod]);

  /* --------- useEffect set Calculate coupon only ---------*/
  useEffect(() => {
    setCalculateData((prev) => ({...prev, coupon: couponDataList}));
  }, [couponDataList]);

  /* --------- API Fn ---------*/
  const getAdminMDMDefaultFn = async () => {
    try {
      setMDMDefaultLoading(true);
      const res = await getAdminMDMDefault();

      if (res) {
        const MDMDefaultKey = _.keyBy(res, 'type_lock');
        setDefualtMDMByKey(MDMDefaultKey);
      }
    } catch (err) {
      Modal.error({
        title: 'ไม่สามารถดึงค่า default MDM ได้',
        content: err?.message,
      });
    } finally {
      setMDMDefaultLoading(false);
    }
  };

  const getFGFCodeFn = async (idCard) => {
    try {
      const res = await POST(GET_FGF_DISCOUNT, {id_card: idCard});
      const {friend_get_friend, success} = res;
      if (success) {
        form.setFieldValue('friend_get_friend', friend_get_friend?.code);
        setFGFData(friend_get_friend);
      } else {
        setFGFTextExtra(res?.message || 'ไม่พบข้อมูล');
      }
    } catch (err) {
      Modal.error({title: 'ไม่สามารถดึงค่า friend get friend ได้', content: err?.message});
    }
  };

  const calculateFn = async (params) => {
    try {
      if (!_.isEmpty(params)) {
        setCalculateLoading(true);

        const inputParmas = {
          price: calDtMemo?.price || 0,
          priceTurn: calDtMemo?.price || 0,
          down: calDtMemo?.down_amount,
          discountPeriod: Number(calDtMemo?.discountPeriod?.discount) || 0,
          discountTurn1: Number(calDtMemo?.discountPeriod?.discount) || 0,
          period: calDtMemo?.periods,
          couponDiscount: _.sumBy(calDtMemo?.coupon, 'coupon_discount') || 0,
        };

        const response = await calculateCreateContract(inputParmas);

        const {success, result} = response;

        if (success) {
          const sumDiscount = result?.discount?.couponDiscount + result?.discount?.discountPeriod + result?.discount?.discountTurn1;

          const setFieldsValue = {
            discount: sumDiscount || 0,
            installments: result?.installments || 0,
            total: result?.total || 0,
          };
          form.setFieldsValue(setFieldsValue);
        }
      }
    } catch (err) {
      Modal.error({
        title: 'ไม่สามารถคํานวณราคาได้',
        content: err?.message,
      });
    } finally {
      setCalculateLoading(false);
    }
  };

  const getMailIcloudOld = async () => {
    const obj = {
      useOldMail: true,
      idCard: record?.id_card,
    };
    try {
      const {success, result} = await POST(RAMDOM_MAIL, obj);
      if (success) {
        setCheckMail(true);
        form.setFieldValue('checkMail', true);
        form.setFieldValue('mail', result.mail);
      }
    } catch (err) {
      const {message} = err;
      setCheckMail(false);
      setErrorMessage({form, error: true, name: 'checkMail', txtError: [message]});
    }
  };

  /* --------- Fn ---------*/
  const handleProductChange = (value) => {
    form.setFields([{name: 'product_code', value: value, errors: []}]);
    setCalculateData((prev) => ({...prev, productCode: value}));
  };

  const handleConditionChange = (value) => {
    form.setFields([{name: 'condition', value: value, errors: []}]);
    setCalculateData((prev) => ({...prev, condition: value}));
  };

  const handlePriceChange = (value) => {
    form.setFields([{name: 'price', value: value, errors: []}]);
    setCalculateData((prev) => ({...prev, price: value}));
  };

  const handlePeriodsChange = (value) => {
    form.setFields([{name: 'periods', value: value, errors: []}]);
    setCalculateData((prev) => ({...prev, periods: value}));
  };

  const handleCouponChange = (value) => {
    form.setFields([{name: 'coupon_code', VALUE: value, errors: []}]);
  };

  const handleSetCouponList = () => {
    const {discountPeriod, discountTurn1, coupon} = calDtMemo;
    const couponCode = _.map(coupon, 'coupon_code');
    const couponCodeAll = _.compact([discountPeriod?.code, discountTurn1?.code, ...couponCode]);
    setCouponList(couponCodeAll);
  };

  const handleChangeCheckMail = (e) => {
    // clear Error of field 'checkMail' before fire api
    setErrorMessage({form, error: false, name: 'checkMail', txtError: [], value: e.target.checked});

    if (e?.target?.checked) {
      setCheckMail(true);
      getMailIcloudOld();
    } else {
      form.setFieldValue('mail', '');
      setCheckMail(false);
    }
  };
  const handleChangeTypeLock = (value) => {
    setTypeLock(value);
    form.setFields([
      {name: 'type_lock', value: value, errors: []},
      {name: 'mdm_provider_code', value: defualtMDMByKey[value]?.mdm_provider_code || '', errors: []},
      {name: 'mdm_site_code', value: defualtMDMByKey[value]?.mdm_site_code || '', errors: []},
    ]);
  };

  const handleClearForm = () => {
    form.resetFields();
    setProductSaleByPeriod({});
    setMaxPrice(0);
    setCouponList([]);
    setCouponDataList([]);
    setTypeLock('');
    setCalculateData({...ININT_CAL});
    setFileList([]);
    setFileMachineDetailImageList([]);
    setFileImeiImageList([]);
    setFileOtherImageList([]);
    setCheckMail(false);
  };

  const onFinish = async (values) => {
    try {
      const {
        product_code,
        serial_number,
        branch,
        condition,
        price,
        down_amount,
        friend_get_friend,
        periods,
        type_lock,
        mdm_provider_code,
        mdm_site_code,
        checkMail,
        mail,
        image,
        machineDetailImage,
        imeiImage,
        otherImage,
        remark,
        isOldContract,
      } = values;

      const productCode = product_code;
      const productName = productSaleByPeriod[periods]?.productName || '';

      const couponCode = _.map(couponDataList, (el) => {
        return {code: el?.coupon_code};
      });

      const payload = {
        user_id: record?._id,
        type: contractType,
        branch: branch,
        price: price,
        productCode: [{code: productCode, name: productName, serialNumber: serial_number}],
        serial_number: [
          {
            product_code: productCode,
            product_name: productName,
            serial_number: serial_number,
            price: price,
          },
        ],
        serial_number2: serial_number,
        contract: {
          type: contractType,
          down_amount: down_amount,
          periods: periods,
          type_lock: type_lock === 'NONE' ? '' : type_lock,
          mdm_provider_code: mdm_provider_code,
          mdm_site_code: mdm_site_code,
          checkMail: checkMail,
          package: '',
          price_package: 0,
          promotion_code: 'none',
          couponCode: couponCode,
          ...(checkMail ? {mail} : {}),
          ...(image ? getImageObject('image', image) : {}),
          ...(machineDetailImage ? getImageObject('machineDetailImage', machineDetailImage) : {}),
          ...(imeiImage ? getImageObject('imeiImage', imeiImage) : {}),
          ...(otherImage ? getImageObject('otherImage', otherImage) : {}),
        },
        coupon_id: couponDataList[0]?.coupon_id ?? '',
        remark: remark,
        isOldContract: isOldContract,
        ...(friend_get_friend ? {friend_get_friend: fgfData} : {}),
        condition: condition,
      };

      await checkCustomer(payload);
    } catch (error) {}
  };

  return (
    <>
      {(getAdminMDMDefaultLoading || calculateLoading) && <Spinners />}
      <Form
        form={form}
        name={formName}
        layout={'horizontal'}
        labelAlign="right"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
        style={{padding: '0px 20px 20px 20px'}}
        onFinish={onFinish}
        autoComplete="off"
        scrollToFirstError>
        <Form.Item name={'product_code'} label="ค้นหาสินค้า" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <SelectProductAll placeholder={'เลือกสินค้า'} handleChange={(e) => handleProductChange(e?.value)} filter={{type: contractType}} />
        </Form.Item>

        <Form.Item
          name={'serial_number'}
          label="ซีเรียลเครื่อง"
          rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}
          extra={`ห้ามใส่ ' - ' และช่องว่าง เมื่อสร้างสัญญาใหม่โดยใช้เลขซีเรียลเครื่องเดิม`}>
          <Input onChange={(e) => handleTrimValue(form, 'serial_number', e?.target?.value)} />
        </Form.Item>

        <Divider />

        <Form.Item name={'branch'} label="สาขา" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]} initialValue={admin_branch}>
          <Select options={options?.choicesOptionBranch || []} />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name={'condition'}
              label="Condition"
              labelCol={{span: 8}}
              rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}
              initialValue={0}>
              <SelectProductCondition placeholder={'เลือก Condition'} handleChange={(e) => handleConditionChange(e?.value)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={'price'}
              label="ราคา"
              labelCol={{span: 8}}
              rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}
              extra={`สูงสุด ${maxPrice ? convertNumberToComma(maxPrice) : ''}`}>
              <InputNumber
                style={{width: '100%'}}
                min={0}
                max={maxPrice || Number.MAX_SAFE_INTEGER}
                formatter={(value, info) => {
                  if (!info.userTyping) {
                    return convertNumberToComma(value);
                  } else {
                    return value;
                  }
                }}
                onBlur={(e) => handlePriceChange(Number(e?.target?.value) || 0)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name={'down_amount'}
              label="เงินดาวน์"
              rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}
              initialValue={0}
              labelCol={{span: 8}}>
              <InputNumber
                disabled
                style={{width: '100%'}}
                formatter={(value, info) => {
                  if (!info.userTyping) {
                    return convertNumberToComma(value);
                  } else {
                    return value;
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'friend_get_friend'} label="รหัสเพื่อนชวนเพื่อน" labelCol={{span: 8}} extra={fgfTextExtra}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'coupon_code'} label={'Code คูปอง'} labelCol={{span: 8}} extra={'เมื่อกรอกโค้ดเสร็จ กรุณากด "Enter'}>
              <InputCoupon
                form={form}
                fieldName={'coupon_code'}
                setErrorMessage={setErrorMessage}
                disabledCoupon={_.compact([calDtMemo?.discountPeriod?.code, calDtMemo?.discountTurn1?.code])}
                couponList={couponList}
                setCouponList={setCouponList}
                couponDataList={couponDataList}
                setCouponDataList={setCouponDataList}
                productCodeList={calDtMemo?.productCode ? [calDtMemo?.productCode] : []}
                onChange={(e) => handleCouponChange(e)}
                disabled={_.isEmpty(calDtMemo?.productCode)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'discount'} label="ส่วนลด" labelCol={{span: 8}} initialValue={0}>
              <InputNumber style={{width: '100%'}} disabled formatter={convertNumberToComma} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'periods'} label="จำนวนงวด" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectPeriods
                placeholder={'เลือกจำนวนงวด'}
                handleChange={(e) => handlePeriodsChange(e?.value)}
                filter={{type: contractType, productCode: calDtMemo?.productCode, condition: calDtMemo?.condition}}
                productSaleByPeriod={(e) => setProductSaleByPeriod(e)} //getProductData ด้วย
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'installments'} label="ยอดผ่อนต่อเดือน" labelCol={{span: 8}} initialValue={0}>
              <InputNumber style={{width: '100%'}} disabled formatter={convertNumberToComma} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'total'} label="ยอดชำระรวม" labelCol={{span: 8}} initialValue={0}>
              <InputNumber style={{width: '100%'}} disabled formatter={convertNumberToComma} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'type_lock'} label="การล็อคเครื่อง" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectTypeLock
                placeholder={'เลือกรูปแบบการล็อกเครื่อง'}
                handleChange={(e) => {
                  form.setFields([{name: 'type_lock', value: e?.value, errors: []}]);
                  handleChangeTypeLock(e?.value);
                }}
                filter={FILTER_MASTER}
              />
            </Form.Item>
          </Col>
        </Row>

        {typeLock && ['MDMICLOUD', 'MDM'].includes(typeLock) && (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={'mdm_provider_code'}
                label="ผู้ให้บริการ MDM"
                labelCol={{span: 8}}
                rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
                <SelectMDMProvider
                  placeholder={'เลือกผู้ให้บริการ MDM'}
                  handleChange={(e) => {
                    form.setFields([{name: 'mdm_provider_code', value: e?.value, errors: []}]);
                  }}
                  filter={FILTER_MASTER}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'mdm_site_code'} label="MDM Site" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
                <SelectMDMSite
                  placeholder={'เลือก MDM Site'}
                  handleChange={(e) => {
                    form.setFields([{name: 'mdm_site_code', value: e?.value, errors: []}]);
                  }}
                  filter={FILTER_MASTER}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={[16, 16]}>
          {typeLock && ['MDMICLOUD', 'ICLOUD'].includes(typeLock) && (
            <Col span={12}>
              <Form.Item
                name={'checkMail'}
                label="อีเมล์ ไอคลาวน์"
                labelCol={{span: 8}}
                initialValue={false}
                valuePropName="checked"
                onChange={handleChangeCheckMail}>
                <Checkbox style={{marginLeft: 5}}>ใช้บัญชีเดิม</Checkbox>
              </Form.Item>
            </Col>
          )}
          {typeLock && ['MDMICLOUD', 'ICLOUD'].includes(typeLock) && checkMail && (
            <Col span={12}>
              <Form.Item name={'mail'} label="อีเมล์" labelCol={{span: 8}}>
                <Input disabled />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Form.Item name={'image'} label="รูปผู้ทำสัญญา" labelCol={{span: 4}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileList}
            setLoading={setImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'image', value: files[0]?.url, errors: []}]);
            }}
            handleRemove={() => {
              form.setFields([{name: 'image', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item
          name={'machineDetailImage'}
          label="รูปรายละเอียด"
          labelCol={{span: 4}}
          rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}
          extra={
            <Space size={[36, 16]} wrap>
              <span>{`เข้าไปที่ การตั้งค่า > เกี่ยวกับ`}</span>
              <span className="text-link" onClick={() => setIsModalVisible(true)}>
                ดูรูปตัวอย่าง
              </span>
            </Space>
          }>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileMachineDetailImageList}
            setLoading={setMachineDetailImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'machineDetailImage', value: files[0]?.url, errors: []}]);
            }}
            handleRemove={() => {
              form.setFields([{name: 'machineDetailImage', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'imeiImage'} label="รูป IMEI" labelCol={{span: 4}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileImeiImageList}
            setLoading={setImeiImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'imeiImage', value: files[0]?.url, errors: []}]);
            }}
            handleRemove={() => {
              form.setFields([{name: 'imeiImage', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'otherImage'} label="รูปอื่นๆ" labelCol={{span: 4}}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileOtherImageList}
            setLoading={setOtherImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'otherImage', value: files[0]?.url, errors: []}]);
            }}
            handleRemove={() => {
              form.setFields([{name: 'otherImage', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'remark'} label="หมายเหตุ" labelCol={{span: 4}}>
          <Input />
        </Form.Item>

        <Form.Item name={'isOldContract'} label="สร้างสัญญาแบบเก่า" labelCol={{span: 4}} initialValue={false} valuePropName="checked">
          <Checkbox style={{marginLeft: 5}} />
        </Form.Item>

        <Row justify={'center'}>
          <ButtonTheme useFor="CLEAR" style={{width: 150}} onClick={() => handleClearForm()} />
          <ButtonTheme
            useFor="CUSTOM"
            htmlType="submit"
            title={'ยืนยัน'}
            style={{width: 150}}
            loading={imageLoading || machineDetailImageLoading || imeiImageLoading || otherImageLoading}
          />
        </Row>
      </Form>
      <Modal title="ตัวอย่างรูปรายละเอียดเครื่อง" open={isModalVisible} onCancel={() => setIsModalVisible(false)} width={380} footer={null}>
        <img src={Example} style={{width: '100%'}} alt="No" />
      </Modal>
    </>
  );
};
