import { ButtonTheme } from '../../../components/buttons';
import { generateUrlSignOnline } from '../../../services/api/adminservice/sign-online';
import { Modal , message} from 'antd';
import { useState } from 'react';

const GenSignedLink = ({ registerId, status }) => {
    const [urlSignOnline, setUrlSignOnline] = useState(null);

    const getStatusText = (status) => {
        const statusConfig = {
            'WAITING_SIGNATURE': {
                text: 'รอเซ็นสัญญา',
                color: '#FF8C00' // สีส้ม
            },
            'SUCCESS': {
                text: 'เซ็นสัญญาเสร็จสิ้น',
                color: '#52c41a' // สีเขียว
            }
        };
        
        const config = statusConfig[status] || { text: status, color: '#000000' };
        return <span style={{ color: config.color }}>{config.text}</span>;
    };

    const handleClickGenerateLink = async () => {
        try {
            const res = await generateUrlSignOnline({ id: registerId });
    
            const { success, result } = res
            if (success) {
                setUrlSignOnline(result?.url_sign)
                message.success('คัดลอก Link สำเร็จ')
                navigator.clipboard.writeText(result?.url_sign)
                console.log(result);
            }
        } catch (error) {
            Modal.error({
                title: error?.message || error?.error || 'ไม่สามารถ Generate Link ได้'
            })
            return;
        }
    }

    
    return (
        <div style={{marginTop: '15px'}}>
            <p style={{fontSize: '14px', fontWeight: 'bold'}}>ลิงค์เซ็นสัญญาซื้อขายออไลน์</p>
            <ButtonTheme 
                useFor="CUSTOM" 
                title={'Generate Signed Link'} 
                onClick={handleClickGenerateLink}
                style={{ margin: '0px' }}
            />
            <p style={{marginTop: '10px'}}>สถาณะการเซ็นสัญญาซื้อขายออไลน์: {getStatusText(status)}</p>
            {urlSignOnline && (
                <pre>{urlSignOnline}</pre>
            )}
        </div>
        
    )
};

export default GenSignedLink;

