import {SV_API, handleSVApiError} from './utils';
const path = 'admin';

export const getContractDefault = async (formData) => {
  try {
    const res = await SV_API.post(`${path}/contract/get-contract-default`, formData);
    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};

export const getCheckCoupon = async (formData) => {
  try {
    const res = await SV_API.post(`${path}/contract/checkCoupon`, formData);
    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};

export const calculateCreateContract = async (formData) => {
  try {
    const res = await SV_API.post(`${path}/product-v2/installment/calculate`, formData);
    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};

export const createContract = async (formData) => {
  try {
    const res = await SV_API.post(`${path}/contract/create_contract`, formData);
    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};

export const getProductData = async (formData) => {
  try {
    const res = await SV_API.post(`${path}/product-v2/product-data`, formData);
    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};

export const getProductAll = async (params) => {
  try {
    const res = await SV_API.get(`${path}/product-v2/product-all`, params);
    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};
