import axios from "axios";
import { cutStringImgSigned } from "../../functions/fn";
import { GET, UPLOAD_CONTRACT_IMAGE, UPLOAD_IMAGE } from "../../services";

export const filterOption = (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export const uploadContractImg = async (options) => {
  const { file, onSuccess, onError } = options;

  let extension = null;

  file.name.replace(/\.[^.]{3,4}$/, (m) => (extension = m));

  if (!extension) {
    onError(new Error('extension not found'));
    return;
  }

  let qs = new URLSearchParams({ extension });
  let res = await GET(UPLOAD_CONTRACT_IMAGE(qs));
  let gcsUrl = res?.data?.url;

  try {
    await axios.put(gcsUrl, file, { headers: { 'Content-Type': file.type } });

    // Update file status to 'done'
    file.status = 'done';
    file.gcsUrl = gcsUrl;

    // Call the onSuccess callback to indicate the upload was successful
    onSuccess(null, file);
  } catch (error) {
    console.error('Upload error:', error);
    // Update file status to 'error'
    file.status = 'error';
    onError(error);
  }

  return gcsUrl;
};

export const extractImageList = (arr) => {
  // if (arr.length > 1) {
  //   return arr.map((item) => ({url: item.url}));
  // } else
  if (arr.length === 1) {
    return { url: cutStringImgSigned(arr[0].url) };
  }
  return null;
};

export const getImageObject = (name, url) => {
  if (name && url) {
    return { [name]: {url: url} };
  }
  return null;
};