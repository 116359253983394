import React from 'react'
import { Row, Col } from 'antd'

const SeconHandType = ({ contractInfo }) => {
  const { product_secondHand } = contractInfo.info
  const products = product_secondHand[0]

  return (
    <>
      <p>สินค้า: {products.product_name}</p>
      <p>Serial Number: {products.serial_number}</p>
      <p>IMEI: {products.imei}</p>
      <p>สภาพเครื่อง: {products.condition}</p>
      <p>ประกันหมดอายุ: {products.insurance.date}</p>
      <p>วงเงินที่อนุมัติ: {products.price}</p>
      <p>วันที่อนุมัติ: </p>
      <p>วันที่ยกเลิก: </p>
    </>
  )
}


export default SeconHandType
