import {useEffect, useMemo, useState} from 'react';
import {Form, Select, Input, Divider, Row, Col, InputNumber, Checkbox, Modal} from 'antd';
import _ from 'lodash';
import {FILTER_MASTER, CONTRACT_TYPE} from '../constants';
import {convertNumberToComma} from '../../../../functions/fn';
import {ButtonTheme, Spinners, Upload} from '../../../../components';
import {SerialNumberInput, InputCoupon} from '../components';
import {SelectMDMProvider, SelectMDMSite, SelectPeriods, SelectTypeLock} from '../../../../components/inputfromapi';
import {GCS_BUCKET_CONTRACT_IMAGE, GCS_PATH_CONTRACT_IMAGE} from '../../../../env';
import {POST, GET_FGF_DISCOUNT} from '../../../../services';
import {getAdminMDMDefault} from '../../../../apiv2';
import {calculateCreateContract} from '../../../../services/api/svAPI';

/*
coupon: [
  {
    coupon_id: 'PON-25-0006',
    coupon_code: 'REGISTERCOUPON',
    coupon_discount: 200,
  },
];
*/

const {INSTALLMENT} = CONTRACT_TYPE;
const formName = 'FormInstallment';
const ININT_CAL = {
  contractType: INSTALLMENT, //ประเภทสัญญา
  productCode: [], //รหัสสินค้า
  down_amount: 0, //เงินดาวน์
  periods: 0, //งวด
  coupon: [], //คูปอง
  discountPeriod: {
    label: '', //'ส่วนลดค่าเครื่อง:1000'
    code: '', //DISPERIOD1000
    discount: '', // 1000
  }, //ส่วนลดค่าเครื่อง
  price: 0, //ราคา
};

export const FormInstallment = ({defaultData, data, fn}) => {
  const {options, admin_branch, record, contractType} = data;
  const {checkCustomer, setErrorMessage} = fn;

  /* Form */
  const [form] = Form.useForm();
  /* Product */
  const [productList, setProductList] = useState([]);
  const [serialnumber, setSerialnumber] = useState([]);
  const [productSaleByPeriod, setProductSaleByPeriod] = useState({}); //สำหรับ map period เพื่อได้ค่า price
  /* Coupon */
  const [couponList, setCouponList] = useState([]);
  const [couponDataList, setCouponDataList] = useState([]);
  /* Type Lock */
  const [typeLock, setTypeLock] = useState('');
  const [defualtMDMByKey, setDefualtMDMByKey] = useState('');
  /* Frind Get Friend */
  const [fgfData, setFGFData] = useState({});
  const [fgfTextExtra, setFGFTextExtra] = useState('');
  /* Calculate */
  const [calculateData, setCalculateData] = useState({...ININT_CAL});
  /* File */
  const [fileImageList, setFileImageList] = useState([]);
  /* Loading API */
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [calculateLoading, setCalculateLoading] = useState(false);
  const [getAdminMDMDefaultLoading, setMDMDefaultLoading] = useState(false);

  /* --------- useMemo calculateData ---------*/
  const calDtMemo = useMemo(() => {
    return calculateData;
  }, [calculateData]);

  /* --------- useEffect get default MDM ---------*/
  useEffect(() => {
    getAdminMDMDefaultFn();
    getFGFCodeFn(record?.id_card);
  }, [record?.id_card]);

  /* --------- useEffect set default data ---------*/
  useEffect(() => {
    if (!_.isEmpty(defaultData)) {
      const {coupon, summary, discountPeriod, downpayment_amount} = defaultData;

      form.setFieldsValue({
        downpayment_amount: downpayment_amount || 0,
        down_amount: summary?.downPayment || 0,
        periods: summary?.period,
        coupon_code: _.compact([discountPeriod?.code, coupon]),
        installments: summary?.installmet || 0,
        total: summary?.total || 0,
      });

      if (!_.isEmpty(coupon)) {
        setCouponDataList((prev) => [...prev, ...coupon]);
        handleSetCouponList(defaultData);
      }
      setCalculateData((prev) => ({
        ...prev,
        down_amount: summary?.downPayment || 0,
        periods: summary?.period,
        coupon: coupon || [],
        discountPeriod: discountPeriod || {},
      }));
    }
  }, [defaultData]);

  /* --------- useEffect calculateFn ---------*/
  useEffect(() => {
    if (contractType && !_.isEmpty(calDtMemo?.productCode) && calDtMemo?.down_amount && calDtMemo?.periods) {
      calculateFn(calDtMemo);
    }
  }, [calDtMemo]);

  /* --------- useEffect SetMaxPrice and discountPeriod ---------*/
  useEffect(() => {
    const discountPeriod = productSaleByPeriod[calDtMemo?.periods]?.discountPeriod || {};
    const price = productSaleByPeriod[calDtMemo?.periods]?.price || 0;
    setCalculateData((prev) => ({...prev, discountPeriod: discountPeriod, price: price}));
  }, [calDtMemo?.productCode, calDtMemo?.periods, productSaleByPeriod]);

  /* --------- useEffect set coupon all (discountPeriod+coupon)---------*/
  useEffect(() => {
    handleSetCouponList();
  }, [calDtMemo?.discountPeriod]);

  /* --------- useEffect set Calculate coupon only ---------*/
  useEffect(() => {
    setCalculateData((prev) => ({...prev, coupon: couponDataList}));
  }, [couponDataList]);

  /* --------- API Fn ---------*/
  const getAdminMDMDefaultFn = async () => {
    try {
      setMDMDefaultLoading(true);
      const res = await getAdminMDMDefault();

      if (res) {
        const MDMDefaultKey = _.keyBy(res, 'type_lock');
        setDefualtMDMByKey(MDMDefaultKey);
      }
    } catch (err) {
      Modal.error({
        title: 'ไม่สามารถดึงค่า default MDM ได้',
        content: err?.message,
      });
    } finally {
      setMDMDefaultLoading(false);
    }
  };

  const getFGFCodeFn = async (idCard) => {
    try {
      const res = await POST(GET_FGF_DISCOUNT, {id_card: idCard});
      const {friend_get_friend, success} = res;
      if (success) {
        form.setFieldValue('friend_get_friend', friend_get_friend?.code);
        setFGFData(friend_get_friend);
      } else {
        setFGFTextExtra(res?.message || 'ไม่พบข้อมูล');
      }
    } catch (err) {
      Modal.error({title: 'ไม่สามารถดึงค่า friend get friend ได้', content: err?.message});
    }
  };

  const calculateFn = async (params) => {
    try {
      if (!_.isEmpty(params)) {
        setCalculateLoading(true);

        const inputParmas = {
          price: calDtMemo?.price,
          down: calDtMemo?.down_amount,
          discountPeriod: Number(calDtMemo?.discountPeriod?.discount) || 0,
          period: calDtMemo?.periods,
          couponDiscount: _.sumBy(calDtMemo?.coupon, 'coupon_discount') || 0,
        };

        const response = await calculateCreateContract(inputParmas);

        const {success, result} = response;
        if (success) {
          const sumDiscount = result?.discount?.couponDiscount + result?.discount?.discountPeriod;

          const setFieldsValue = {
            discount: sumDiscount || 0,
            installments: result?.installments || 0,
            total: result?.total || 0,
          };
          form.setFieldsValue(setFieldsValue);
        }
      }
    } catch (err) {
      Modal.error({
        title: 'ไม่สามารถคํานวณราคาได้',
        content: err?.message,
      });
    } finally {
      setCalculateLoading(false);
    }
  };

  /* --------- Fn ---------*/
  const handleChangeSerialNumber = async (value) => {
    const {productList} = value;

    if (!_.isEmpty(productList)) {
      const serialNumber = _.map(productList, 'serialnumber');
      const productCode = _.map(productList, 'code');
      form.setFields([{name: 'serial_number', value: serialNumber, errors: []}]);

      setSerialnumber((prev) => [...prev, ...serialNumber]);
      setProductList((prev) => [...prev, ...productList]);
      setCalculateData((prev) => ({...prev, productCode: productCode}));
    }
  };

  const handleDeleteSerialNumber = async (value) => {
    form.setFieldValue('serial_number', value);
    setProductList(value);
    setCalculateData((prev) => ({...prev, discountPeriod: {}}));
  };

  const handleDownAmountChange = (value) => {
    const rawValue = value?.replace(/,/g, ''); // ลบ "," ออกจากตัวเลข
    const newValue = Number(rawValue) || 0;
    form.setFields([{name: 'down_amount', value: newValue, errors: []}]);
    setCalculateData((prev) => ({...prev, down_amount: newValue}));
  };

  const handlePeriodsChange = (value) => {
    form.setFields([{name: 'periods', value: value, errors: []}]);
    setCalculateData((prev) => ({...prev, periods: value}));
  };

  const handleCouponChange = (value) => {
    form.setFields([{name: 'coupon_code', VALUE: value, errors: []}]);
  };

  const handleSetCouponList = () => {
    const {discountPeriod, coupon} = calDtMemo;
    const discountPeriodCode = discountPeriod?.code ? [discountPeriod?.code] : [];
    const couponCode = _.map(coupon, 'coupon_code');
    const couponCodeAll = [...discountPeriodCode, ...couponCode];
    setCouponList(couponCodeAll);
  };

  const handleChangeTypeLock = (value) => {
    setTypeLock(value);
    form.setFields([
      {name: 'type_lock', value: value, errors: []},
      {name: 'mdm_provider_code', value: defualtMDMByKey[value]?.mdm_provider_code || '', errors: []},
      {name: 'mdm_site_code', value: defualtMDMByKey[value]?.mdm_site_code || '', errors: []},
    ]);
  };

  const handleClearForm = () => {
    form.resetFields();
    setProductList([]);
    setSerialnumber([]);
    setProductSaleByPeriod({});
    setCouponList([]);
    setCouponDataList([]);
    setTypeLock('');
    setCalculateData({...ININT_CAL});
    setFileImageList([]);
  };

  const onFinish = async (values) => {
    try {
      const {branch, periods, type_lock, checkMail, image, isOldContract, down_amount, mdm_provider_code, mdm_site_code, remark, friend_get_friend} =
        values;

      const serialNumberList = _.map(productList, (el) => {
        return {
          product_code: el?.code,
          product_name: el?.name,
          serial_number: el?.serialnumber,
          price: productSaleByPeriod[periods]?.price || 0,
        };
      });

      const productCodeList = _.map(productList, (el) => {
        return {
          code: el?.code,
          name: el?.name,
          serialNumber: el?.serialnumber,
        };
      });

      const couponCode = _.map(couponDataList, (el) => {
        return {code: el?.coupon_code};
      });

      const payload = {
        user_id: record?._id,
        type: contractType,
        branch: branch,
        contract: {
          type: contractType,
          down_amount: down_amount,
          periods: periods,
          type_lock: type_lock === 'NONE' ? '' : type_lock,
          mdm_provider_code: mdm_provider_code,
          mdm_site_code: mdm_site_code,
          checkMail: checkMail,
          image: {url: image},
          package: '',
          price_package: 0,
          promotion_code: 'none',
          couponCode: couponCode,
        },
        coupon_id: couponDataList[0]?.coupon_id ?? '',
        remark: remark,
        isOldContract: isOldContract,
        serial_number: serialNumberList,
        productCode: productCodeList,
        ...(friend_get_friend ? {friend_get_friend: fgfData} : {}),
      };

      await checkCustomer(payload);
    } catch (error) {}
  };

  return (
    <>
      {(getAdminMDMDefaultLoading || calculateLoading) && <Spinners />}
      <Form
        form={form}
        name={formName}
        layout={'horizontal'}
        labelAlign="right"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
        style={{padding: '0px 20px 20px 20px'}}
        onFinish={onFinish}
        autoComplete="off"
        scrollToFirstError>
        <Form.Item name={'serial_number'} label="บาร์โค้ดสินค้า" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <SerialNumberInput
            setErrorMessage={setErrorMessage}
            onChange={(value) => handleChangeSerialNumber(value)}
            onDelete={(value) => handleDeleteSerialNumber(value)}
            type={contractType}
            form={form}
          />
        </Form.Item>

        <Divider />

        <Form.Item name={'branch'} label="สาขา" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]} initialValue={admin_branch}>
          <Select options={options?.choicesOptionBranch || []} />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'downpayment_amount'} label="เงินออมดาวน์" labelCol={{span: 8}} initialValue={0}>
              <Input disabled />
            </Form.Item>
            <div
              style={{
                color: 'red',
                marginTop: -25,
                marginLeft: 145,
                marginBottom: 30,
              }}>{`กรุณาให้ลูกค้าโอนเงินดาวน์เพิ่มให้ครบยอดเงินดาวน์`}</div>
          </Col>
          <Col span={12}>
            <Form.Item name={'friend_get_friend'} label="รหัสเพื่อนชวนเพื่อน" labelCol={{span: 8}} extra={fgfTextExtra}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'down_amount'} label="เงินดาวน์" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <InputNumber
                style={{width: '100%'}}
                formatter={(value, info) => {
                  if (!info.userTyping) {
                    return convertNumberToComma(value);
                  } else {
                    return value;
                  }
                }}
                onBlur={(e) => handleDownAmountChange(e?.target?.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'coupon_code'} label={'Code คูปอง'} labelCol={{span: 8}} extra={'เมื่อกรอกโค้ดเสร็จ กรุณากด "Enter'}>
              <InputCoupon
                form={form}
                fieldName={'coupon_code'}
                setErrorMessage={setErrorMessage}
                disabledCoupon={[calDtMemo?.discountPeriod?.code]}
                couponList={couponList}
                setCouponList={setCouponList}
                couponDataList={couponDataList}
                setCouponDataList={setCouponDataList}
                productCodeList={_.map(productList, (el) => el?.code)}
                onChange={(e) => handleCouponChange(e)}
                disabled={_.isEmpty(productList)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'discount'} label="ส่วนลด" labelCol={{span: 8}} initialValue={0}>
              <InputNumber style={{width: '100%'}} disabled formatter={convertNumberToComma} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'periods'} label="จำนวนงวด" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectPeriods
                placeholder={'เลือกจำนวนงวด'}
                handleChange={(e) => handlePeriodsChange(e?.value)}
                filter={{type: contractType, serialNumber: serialnumber}}
                productSaleByPeriod={(e) => setProductSaleByPeriod(e)} //getProductData ด้วย
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'installments'} label="ยอดผ่อนต่อเดือน" labelCol={{span: 8}} initialValue={0}>
              <InputNumber style={{width: '100%'}} disabled formatter={convertNumberToComma} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'total'} label="ยอดชำระรวม" labelCol={{span: 8}} initialValue={0}>
              <InputNumber style={{width: '100%'}} disabled formatter={convertNumberToComma} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'type_lock'} label="การล็อคเครื่อง" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectTypeLock placeholder={'เลือกรูปแบบการล็อกเครื่อง'} handleChange={(e) => handleChangeTypeLock(e?.value)} filter={FILTER_MASTER} />
            </Form.Item>
          </Col>
        </Row>

        {typeLock && ['MDMICLOUD', 'MDM'].includes(typeLock) && (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={'mdm_provider_code'}
                label="ผู้ให้บริการ MDM"
                labelCol={{span: 8}}
                rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
                <SelectMDMProvider
                  placeholder={'เลือกผู้ให้บริการ MDM'}
                  handleChange={(e) => {
                    form.setFields([{name: 'mdm_provider_code', value: e?.value, errors: []}]);
                  }}
                  filter={FILTER_MASTER}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'mdm_site_code'} label="MDM Site" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
                <SelectMDMSite
                  placeholder={'เลือก MDM Site'}
                  handleChange={(e) => {
                    form.setFields([{name: 'mdm_site_code', value: e?.value, errors: []}]);
                  }}
                  filter={FILTER_MASTER}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Form.Item name={'image'} label="รูปผู้ทำสัญญา" labelCol={{span: 4}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileImageList}
            handleChange={(files) => {
              form.setFields([{name: 'image', value: files[0]?.url, errors: []}]);
              setFileImageList([{fileName: files[0]?.fileName, url: files[0]?.url}]);
            }}
            setLoading={setLoadingUpload}
            handleRemove={() => {
              form.setFields([{name: 'image', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'remark'} label="หมายเหตุ" labelCol={{span: 4}}>
          <Input />
        </Form.Item>

        <Form.Item name={'isOldContract'} label="สร้างสัญญาแบบเก่า" labelCol={{span: 4}} initialValue={false} valuePropName="checked">
          <Checkbox style={{marginLeft: 5}} />
        </Form.Item>

        <Row justify={'center'}>
          <ButtonTheme useFor="CLEAR" style={{width: 150}} onClick={() => handleClearForm()} />
          <ButtonTheme useFor="CUSTOM" htmlType="submit" title={'ยืนยัน'} style={{width: 150}} loading={loadingUpload} />
        </Row>
      </Form>
    </>
  );
};
